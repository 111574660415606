export const DUPLICATE_STUDY = 'DUPLICATE_STUDY';
export const DUPLICATE_STUDY_SUCCESS = 'DUPLICATE_STUDY_SUCCESS';
export const DUPLICATE_STUDY_ERROR = 'DUPLICATE_STUDY_ERROR';
export const RESET_DUPLICATE_STUDY = 'RESET_DUPLICATE_STUDY';

export interface DuplicateStudy {
  type: typeof DUPLICATE_STUDY;
  payload: any;
}
export interface DuplicateStudySuccess {
  type: typeof DUPLICATE_STUDY_SUCCESS;
  payload: null;
}
export interface DuplicateStudyError {
  type: typeof DUPLICATE_STUDY_ERROR;
  payload: boolean | string;
}
export interface ResetDuplicateStudy {
  type: typeof RESET_DUPLICATE_STUDY;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = DuplicateStudy | DuplicateStudySuccess | DuplicateStudyError | ResetDuplicateStudy;
