import { useState } from 'react';
import slugify from 'slugify';
import Step from '../Step';
import './styles.scss';

const states = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
  PREV: 'PREV',
};

const Component = ({ steps, activeStep, onClick }) => {
  const [currentStep, setCurrentStep] = useState(activeStep);

  const getState = (index) => {
    if (index === currentStep) return states.CURRENT;
    if (index < currentStep) return states.PREV;
    if (index > currentStep) return states.NEXT;
  };

  const handleClick = (index) => {
    onClick && onClick(index);
    setCurrentStep(index);
  };

  return (
    <div className="stepper">
      {steps.map((step, index) => {
        const { label, disabled } = step;

        return (
          <Step
            key={slugify(`${label}-${index}`)}
            label={label}
            state={getState(index)}
            step={index + 1}
            disabled={disabled}
            onClick={() => !disabled && handleClick(index)}
          />
        );
      })}
    </div>
  );
};

export default Component;
