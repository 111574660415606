import { ErrorMessage } from 'formik';
import { Row, Col } from 'reactstrap';

interface ComponentProps {
  values?: any;
  type?: string;
  disabled?: boolean;
  errors?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
}

const Filter = ({ errors, touched, values, onFocus, onBlur, onChange, ...rest }: ComponentProps) => {
  const hasError = !!errors && !!errors['confirm'] && !!touched;

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className="form-group">
      <Row>
        <Col>
          <label className="col-form-label pt-0">Confirmar contraseña</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            type="password"
            name="confirm"
            className={`form-control ${hasError ? 'error-field' : ''}`}
            onChange={(event) => handleOnChange(event, onChange)}
            onBlur={(event) => handleOnBlur(event, onBlur)}
            onFocus={(event) => handleOnFocus(event, onFocus)}
            {...(!!values && { value: values['confirm'] })}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="input-feedback">
            <ErrorMessage name="confirm" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Filter;
