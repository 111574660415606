import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { User } from 'react-feather';
import './styles.scss';

const Component = ({ panelistStatistics }) => {
  return (
    <div className="card" id="total-panelists">
      <div className="card-body">
        <div>
          <div className="circle">
            <User size={30} color="#FFFFFF" />
          </div>
          <div className="totals">
            <span>
              {panelistStatistics && <CountUp className="counter" end={panelistStatistics?.totals?.actives} />}
              {''} Panelistas activos
            </span>
            <span>Total de panelistas {panelistStatistics?.totals?.all}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistStatistics } = panelistStore.statistics;
  return { panelistStatistics };
};

export default connect(state)(Component);
