import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'DATOS PERSONALES',
  id: 'datos-personales',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Ocupación',
      name: 'ocupacion',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: 'Estado civil',
      name: 'estado-civil',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Soltero/a',
          value: 'soltero/a',
        },
        {
          label: 'Casado/a',
          value: 'casado/a',
        },
        {
          label: 'Divorciado/a',
          value: 'divorciado/a',
        },
        {
          label: 'Viudo/a',
          value: 'viudo/a',
        },
        {
          label: 'En pareja',
          value: 'en pareja',
        },
      ],
    },

    {
      label: '¿Tiene Hijos?',
      name: 'tiene-hijos',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '1° Hijo voluntario para futuros estudios (Nombre y Apellido)',
      name: '1-hijo-voluntario-para-futuros-estudios-nombre-y-apellido',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: '1° Hijo voluntario para futuros estudios (Fecha de nacimiento)',
      name: '1-hijo-voluntario-para-futuros-estudios-fecha-de-nacimiento',
      description: '',
      type: 'date',
      validationType: 'string',
    },

    {
      label: '2° Hijo voluntario para futuros estudios (Nombre y Apellido)',
      name: '2-hijo-voluntario-para-futuros-estudios-nombre-y-apellido',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: '2° Hijo voluntario para futuros estudios (Fecha de nacimiento)',
      name: '2-hijo-voluntario-para-futuros-estudios-fecha-de-nacimiento',
      description: '',
      type: 'date',
      validationType: 'string',
    },

    {
      label: '3° Hijo voluntario para futuros estudios (Nombre y Apellido)',
      name: '3-hijo-voluntario-para-futuros-estudios-nombre-y-apellido',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: '3° Hijo voluntario para futuros estudios (Fecha de nacimiento)',
      name: '3-hijo-voluntario-para-futuros-estudios-fecha-de-nacimiento',
      description: '',
      type: 'date',
      validationType: 'string',
    },

    {
      label: '4° Hijo voluntario para futuros estudios (Nombre y Apellido)',
      name: '4-hijo-voluntario-para-futuros-estudios-nombre-y-apellido',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: '4° Hijo voluntario para futuros estudios (Fecha de nacimiento)',
      name: '4-hijo-voluntario-para-futuros-estudios-fecha-de-nacimiento',
      description: '',
      type: 'date',
      validationType: 'string',
    },

    {
      label: '5° Hijo voluntario para futuros estudios (Nombre y Apellido)',
      name: '5-hijo-voluntario-para-futuros-estudios-nombre-y-apellido',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: '5° Hijo voluntario para futuros estudios (Fecha de nacimiento)',
      name: '5-hijo-voluntario-para-futuros-estudios-fecha-de-nacimiento',
      description: '',
      type: 'date',
      validationType: 'string',
    },
    {
      label: '¿Autoriza a participar ambos padres?',
      name: 'autoriza-a-participar-ambos-padres',
      description: 'autorizacion de los padres',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
        {
          label: 'NS/NC',
          value: 'ns/nc',
        },
      ],
    },
  ],
};

export default form;
