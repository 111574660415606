import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'FICHA TÉCNICA - FACIAL',
  id: 'ficha-tecnica-facial',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Fotodaño facial',
      name: 'fotodano-facial',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Fotodaño facial - apto foto',
      name: 'fotodano-facial-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'fotodano-facial',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Biotipo cutáneo - área facial',
      name: 'biotipo-cutaneo-area-facial',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Seca/Alípica',
          value: 'seca-alipica',
        },
        {
          label: 'Seborreica',
          value: 'seborreica',
        },
        {
          label: 'Seborreica fluente',
          value: 'seborreica-fluente',
        },
        {
          label: 'Mixta',
          value: 'mixta',
        },
        {
          label: 'Deshumectada / deshidratada',
          value: 'deshumectada-deshidratada',
        },
      ],
    },
    {
      type: 'title',
      label: 'CALIFICACIÓN PIEL SENSIBLE:',
      name: '',
      validationType: 'string',
    },
    {
      label: 'Stinging test',
      name: 'stinging-test',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Positivo',
          value: 'positivo',
        },
        {
          label: 'Negativo',
          value: 'negativo',
        },
      ],
    },
    {
      label: '10 segundos',
      name: '10-segundos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '1 No hay picor',
          value: '1-no-hay-picor',
        },
        {
          label: '2 Leve picor',
          value: '2-leve-picor',
        },
        {
          label: '3 Picor moderado',
          value: '3-picor-moderado',
        },
        {
          label: '4 Picor severo',
          value: '4-picor-severo',
        },
      ],
    },
    {
      label: '2.5 minutos',
      name: '2-5-minutos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '1 No hay picor',
          value: '1-no-hay-picor',
        },
        {
          label: '2 Leve picor',
          value: '2-leve-picor',
        },
        {
          label: '3 Picor moderado',
          value: '3-picor-moderado',
        },
        {
          label: '4 Picor severo',
          value: '4-picor-severo',
        },
      ],
    },
    {
      label: '5 minutos',
      name: '5-minutos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '1 No hay picor',
          value: '1-no-hay-picor',
        },
        {
          label: '2 Leve picor',
          value: '2-leve-picor',
        },
        {
          label: '3 Picor moderado',
          value: '3-picor-moderado',
        },
        {
          label: '4 Picor severo',
          value: '4-picor-severo',
        },
      ],
    },
    {
      label: '8 minutos',
      name: '8-minutos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '1 No hay picor',
          value: '1-no-hay-picor',
        },
        {
          label: '2 Leve picor',
          value: '2-leve-picor',
        },
        {
          label: '3 Picor moderado',
          value: '3-picor-moderado',
        },
        {
          label: '4 Picor severo',
          value: '4-picor-severo',
        },
      ],
    },
    {
      label: 'Cantidad de Comedones',
      name: 'cantidad-de-comedones',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Pocos',
          value: 'pocos',
        },
        {
          label: 'Moderados',
          value: 'moderados',
        },
        {
          label: 'Muchos',
          value: 'muchos',
        },
      ],
    },
    {
      label: 'Comedones - apto foto',
      name: 'comedones-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Cantidad de Pápulas',
      name: 'cantidad-de-papulas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Pocos',
          value: 'pocos',
        },
        {
          label: 'Moderados',
          value: 'moderados',
        },
        {
          label: 'Muchos',
          value: 'muchos',
        },
      ],
    },
    {
      label: 'Papulas - apto foto',
      name: 'papulas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Cantidad de Pústulas',
      name: 'cantidad-de-pustulas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Pocos',
          value: 'pocos',
        },
        {
          label: 'Moderados',
          value: 'moderados',
        },
        {
          label: 'Muchos',
          value: 'muchos',
        },
      ],
    },
    {
      label: 'Pústulas - apto foto',
      name: 'pustulas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Descamación',
      name: 'descamacion',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Área glabelar',
          value: 'area-glabelar',
        },
        {
          label: 'Mentón',
          value: 'menton',
        },
        {
          label: 'Mejilla',
          value: 'mejilla',
        },
        {
          label: 'Peribucal',
          value: 'peribucal',
        },
        {
          label: 'Otra',
          value: 'otra',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'descamacion-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'descamacion',
            {
              is: 'otra',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Propenso a tener acné?',
      name: 'propenso-a-tener-acne',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Acné',
      name: 'acne',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Grado I',
          value: 'grado-i',
        },
        {
          label: 'Grado II',
          value: 'grado-ii',
        },
        {
          label: 'Grado III',
          value: 'grado-iii',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'propenso-a-tener-acne',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Motivos y zonas de acné',
      name: 'motivos-y-zonas-de-acne',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'propenso-a-tener-acne',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Acné - pigmentación',
      name: 'acne-pigmentacion',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'propenso-a-tener-acne',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Acné - cicatrices',
      name: 'acne-cicatrices',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'propenso-a-tener-acne',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Acné - apto foto',
      name: 'acne-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'propenso-a-tener-acne',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Arrugas - Frente',
      name: 'arrugas-frente',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - frente - apto foto',
      name: 'arrugas-frente-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Arrugas- Entrecejo',
      name: 'arrugas-entrecejo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - entrecejo - apto foto',
      name: 'arrugas-entrecejo-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Arrugas- Surco nasogeniano',
      name: 'arrugas-sucro-nasogeniano',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - Surco nasogeniano - apto foto',
      name: 'arrugas-sucro-nasogeniano-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Arrugas- Marioneta',
      name: 'arrugas-marioneta',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - Marioneta - apto foto',
      name: 'arrugas-marioneta-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Arrugas- Peribucales',
      name: 'arrugas-peribucales',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - peribucales - Apto foto',
      name: 'arrugas-peribucales-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Arrugas - contorno de ojos',
      name: 'arrugas-contorno-de-ojos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderada',
          value: 'moderada',
        },
        {
          label: 'Profunda',
          value: 'profunda',
        },
      ],
    },
    {
      label: 'Arrugas - contorno de ojos - apto foto',
      name: 'arrugas-contorno-de-ojos-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Ojeras',
      name: 'ojeras',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Leves',
          value: 'leves',
        },
        {
          label: 'Moderadas',
          value: 'moderadas',
        },
        {
          label: 'Severas',
          value: 'severas',
        },
      ],
    },
    {
      label: 'Ojeras - apto foto',
      name: 'ojeras-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Bolsas',
      name: 'bolsas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Leves',
          value: 'leves',
        },
        {
          label: 'Moderadas',
          value: 'moderado',
        },
        {
          label: 'Severas',
          value: 'severas',
        },
      ],
    },
    {
      label: 'Bolsas - apto foto',
      name: 'bolsas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Eritema cutáneo',
      name: 'eritema-cutaneo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Mínimo (apenas perceptible)',
          value: 'minimo-apenas-perceptibles',
        },
        {
          label: 'Eritema suave',
          value: 'eritema-suave',
        },
        {
          label: 'Eritema moderado',
          value: 'eritema-moderado',
        },
        {
          label: 'Marcado eritema',
          value: 'marcado-eritema',
        },
      ],
    },
    {
      label: 'Zonas',
      name: 'zonas',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Eritema cutáneo - apto foto',
      name: 'eritema-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Telangiectasias facial',
      name: 'telangiectasias-facial',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Ubicación',
      name: 'telangiectasias-facial-ubicacion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'telangiectasias-facial',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Papada',
      name: 'papada',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Papada - apto foto',
      name: 'papada-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'papada',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Maculas hiperpigmentadas',
      name: 'maculas-hiperpigmentadas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ninguna',
          value: 'ninguna',
        },
        {
          label: 'Leves',
          value: 'leves',
        },
        {
          label: 'Moderadas',
          value: 'moderadas',
        },
        {
          label: 'Severas',
          value: 'severas',
        },
      ],
    },
    {
      label: 'Maculas hiperpigmentadas - apto foto',
      name: 'maculas-hiperpigmentadas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Melasma',
      name: 'melasma',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Pecas',
      name: 'pecas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Área capilar - ¿Se le cae el pelo?',
      name: 'area-capilar-se-le-cae-el-pelo',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Tiene microimplantes capilares?',
      name: 'tiene-microimplantes-capilares',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Área capilar - tipo de cabello',
      name: 'area-capilar-tipo-de-cabello',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Seco',
          value: 'seco',
        },
        {
          label: 'Normal',
          value: 'normal',
        },
        {
          label: 'Graso',
          value: 'graso',
        },
        {
          label: 'Mixto (cuero cabelludo graso - Pelo seco)',
          value: 'mixto-cuero-cabelludo-graso-pelo-seco',
        },
        {
          label: 'Crespo',
          value: 'crespo',
        },
        {
          label: 'Ondulado',
          value: 'ondulado',
        },
        {
          label: 'Lacio',
          value: 'lacio',
        },
        {
          label: 'Grueso',
          value: 'grueso',
        },
        {
          label: 'Fino',
          value: 'fino',
        },
        {
          label: 'Muy fino',
          value: 'muy-fino',
        },
      ],
    },

    {
      label: 'Área capilar - estado del cabello',
      name: 'area-capilar-estado-del-cabello',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Cabello dañado - frágil',
          value: 'cabello-danado-fragil',
        },
        {
          label: 'Puntas florecidas',
          value: 'puntas-florecidas',
        },
        {
          label: 'Cabello teñido',
          value: 'cabello-tenido',
        },
        {
          label: 'Cabello deshidratado',
          value: 'cabello-deshidratado',
        },
      ],
    },
    {
      label: 'Área capilar - estado del cuero cabelludo',
      name: 'area-capilar-estado-del-cuero-cabelludo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Sensibilizado',
          value: 'sensibilizado',
        },
        {
          label: 'Seborreico',
          value: 'seborreico',
        },
        {
          label: 'Descamación',
          value: 'descamacion',
        },
        {
          label: 'Caspa',
          value: 'caspa',
        },
        {
          label: 'Olor',
          value: 'olor',
        },
      ],
    },
    {
      label: '¿Causas y motivo del estado del cuero cabelludo?',
      name: 'causas-y-motivo-del-estado-del-cuero-cabelludo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Crónico',
          value: 'cronico',
        },
        {
          label: 'Temporal',
          value: 'temporal',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: 'Otro',
      name: 'otro',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'causas-y-motivo-del-estado-del-cuero-cabelludo',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Tiene caspa',
      name: 'tiene-caspa',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Área capilar - valoración de la caspa',
      name: 'area-capilar-valoracion-de-la-caspa',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'I bajo nivel de caspa',
          value: 'i-bajo-nivel-de-caspa',
        },
        {
          label: 'II moderado nivel de caspa',
          value: 'ii-moderado-nivel-de-caspa',
        },
        {
          label: 'III caspa severa',
          value: 'iii-caspa-severa',
        },
        {
          label: 'IV muy alto nivel de caspa',
          value: 'iv-muy-alto-nivel-de-caspa',
        },
      ],
    },
    {
      label: 'Área capilar - Tratamientos químicos',
      name: 'area-capilar-tratamientos-quimicos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Tintura con amoníaco',
          value: 'tintura-con-amoniaco',
        },
        {
          label: 'Tintura tono sobre tono',
          value: 'tintura-tono-sobre-tono',
        },
        {
          label: 'Coloración directa - henna',
          value: 'coloracion-directa-henna',
        },
        {
          label: 'Reflejos',
          value: 'reflejos',
        },
        {
          label: 'Decoloración',
          value: 'decoloracion',
        },
        {
          label: 'Permanente',
          value: 'permanente',
        },
        {
          label: 'Alisado',
          value: 'alisado',
        },
        {
          label: 'Botox capilar',
          value: 'botox-capilar',
        },
        {
          label: 'Shock de queratina',
          value: 'shock-de-queratina',
        },
        {
          label: 'Producto para puntas florecidas',
          value: 'producto-para-puntas-florecidas',
        },
        {
          label: 'Producto para la caspa',
          value: 'producto-para-la-caspa',
        },
        {
          label: 'Productos para alisado',
          value: 'producto-para-alisado',
        },
        {
          label: 'Producto para permanente',
          value: 'producto-para-permanente',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'area-capilar-tratamientos-quimicos-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'area-capilar-tratamientos-quimicos',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Sensibilidad a la tintura, picor, ardor, eritema',
      name: 'sensibilidad-a-la-tintura-picor-ardor-eritema',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Color del cabello virgen',
      name: 'color-cabello-virgen',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: 'Color de tintura',
      name: 'color-de-tintura',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: 'Marca de tintura y número de color',
      name: 'marca-de-tintura-y-numero-de-color',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: 'Indicación de tratamiento capilar ¿Cuál?',
      name: 'indicacion-de-tratamiento-capilar',
      description: '',
      type: 'text',
      validationType: 'string',
    },
  ],
};

export default form;
