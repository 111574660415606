import moment from 'moment';
import * as Yup from 'yup';

interface FormValues {
  minAge: number;
  maxAge: number;
  controls: number;
  amountParticipants: number;
  amountProducts: number;
  costEstimationNumber: string;
  payment: number;
  revision: string;
  startDate: string;
  endDate: string;
  laboratorieCode: string;
  studyNumber: string;
  studyType: string;
  studyZones: string[];
  sex: string;
  comments: string;
}

export const initialValues: FormValues = {
  minAge: 0,
  maxAge: 0,
  controls: 0,
  amountParticipants: 0,
  amountProducts: 0,
  costEstimationNumber: '',
  payment: 0,
  revision: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: '',
  laboratorieCode: '',
  studyNumber: '',
  studyType: '',
  studyZones: [],
  sex: '',
  comments: '',
};

export const fieldsLabels = {
  minAge: 'Edad Mínima',
  maxAge: 'Edad Máxima',
  controls: 'Cantidad de visitas/controles',
  amountParticipants: 'Nº de participantes solicitados',
  amountProducts: 'Cantidad de productos',
  costEstimationNumber: 'Nº de presupuesto',
  payment: 'Pago por participante',
  revision: 'Revisión',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de fin',
  laboratorieCode: 'Código de laboratorio',
  studyNumber: 'Nº de estudio',
  studyType: 'Tipo de estudio',
  studyZones: 'Zonas de uso',
  sex: 'Sexo',
  comments: 'Comentarios',
};

export const rules = Yup.object().shape({
  minAge: Yup.number().integer().required('Este campo es requerido.').min(0, '.'), // se cambia el min a 0 para habilitar panelistas de 0 a 1 año. No se modifica la constante.
  maxAge: Yup.number()
    .integer()
    .required('Este campo es requerido.')
    .test(function (value) {
      const { path, parent, createError } = this;
      if (value == null) return true;
      if (value < parseFloat(this.parent.minAge)) {
        return createError({
          path,
          message: `El valor del campo debe ser superior a ${parent.minAge}`,
        });
      }
      return true;
    }),
  controls: Yup.number().integer().required('Este campo es requerido.').min(1, 'El valor debe ser mayor o igual a 1.'),
  amountParticipants: Yup.number()
    .integer()
    .required('Este campo es requerido.')
    .min(1, 'El valor debe ser mayor o igual a 1.'),
  amountProducts: Yup.number()
    .integer()
    .required('Este campo es requerido.')
    .min(1, 'El valor debe ser mayor o igual a 1.'),
  payment: Yup.number().integer().required('Este campo es requerido.').min(1, 'El valor debe ser mayor o igual a 1.'),
  costEstimationNumber: Yup.string()
    .required('Este campo es requerido.')
    .min(1, 'El valor debe ser mayor o igual a 1.'),
  startDate: Yup.date()
    .default(() => new Date())
    .required('Este campo es requerido.'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'La fecha de fin no puede ser anterior a la de inicio')
    .required('Este campo es requerido.'),
  laboratorieCode: Yup.string(),
  studyNumber: Yup.string(),
  studyType: Yup.string().required('Este campo es requerido.'),
  //studyZones: Yup.array().min(1, 'Este campo es requerido.').of(Yup.string()),
  studyZones: Yup.array(),
  sex: Yup.string().required('Este campo es requerido.'),
  comments: Yup.string(),
});
