import { Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import SweetAlert from 'sweetalert2';
import * as Yup from 'yup';

import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import Submit from '../../../../components/Form/Submit';
import ErrorMessage from '../../../../components/Form/ErrorMessage';

import { signUp } from '../../../../store/actions/auth';
import { getUserTypes, getUserRoles } from '../../../../store/actions/statics';

interface FormValues {
  name: string;
  lastName: string;
  email: string;
  'email-confirm': string;
  password: string;
  'password-confirm': string;
  userRole: string;
  userType: string;
}

const values: FormValues = {
  name: '',
  lastName: '',
  email: '',
  'email-confirm': '',
  password: '',
  'password-confirm': '',
  userRole: 'user',
  userType: 'analyst',
};

const rules = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido.'),
  lastName: Yup.string().required('Este campo es requerido.'),
  email: Yup.string().required('Este campo es requerido.').email('El email es invalido.'),
  'email-confirm': Yup.string()
    .required('Este campo es requerido.')
    .email('El email es invalido.')
    .oneOf([Yup.ref('email'), null], 'El email no coincide'),
  userRole: Yup.string().required('Este campo es requerido.'),
  userType: Yup.string().required('Este campo es requerido.'),
  password: Yup.string()
    .required('Este campo es requerido')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Debe contener 8 caracateres, uno en mayuscula, uno en minuscula, un numero y un caracter especial.',
    ),
  'password-confirm': Yup.string()
    .required('Este campo es requerido')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
});

const Page = ({ signUpStates, userInformation, userTypes, userTypesStates, userRoles, userRolesStates }) => {
  const { error: signUpError, loading: signUpLoading, success: signUpSuccess } = signUpStates;

  const dispatch = useDispatch();

  const handleOnSubmit = ({ values, actions }) => {
    dispatch(signUp(values));
  };

  useEffect(() => {
    dispatch(getUserTypes());
    dispatch(getUserRoles());
  }, []);

  useEffect(() => {
    if (signUpSuccess) {
      SweetAlert.fire({
        title: 'Usuario creado exitosamente',
        showCancelButton: false,
        confirmButtonText: 'Confirmar',
        allowEscapeKey: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    }
  }, [signUpSuccess]);

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">Crear nuevo usuario</h4>
                      <h6 className="text-center">Ingresá los datos del usuario para darlo de alta.</h6>
                      <Form id="signup-form" rules={rules} values={values} onSubmit={handleOnSubmit}>
                        <Input type="text" name="name" placeholder="Ingrese un nombre" options={{ label: 'Nombre' }} />

                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Ingrese un apellido"
                          options={{ label: 'Apellido' }}
                        />

                        <Input type="email" name="email" placeholder="Ingrese un email" options={{ label: 'Email' }} />

                        <Input
                          type="email"
                          name="email-confirm"
                          placeholder="Ingrese un email"
                          options={{ label: 'Confirmar email' }}
                        />

                        <Select name="userRole" items={userRoles || []} options={{ label: 'Roles' }} />

                        <Select name="userType" items={userTypes || []} options={{ label: 'Tipo de usuario' }} />

                        <Input
                          type="password"
                          name="password"
                          placeholder="Ingrese una contraseña"
                          options={{ label: 'Contraseña' }}
                        />

                        <Input
                          type="password"
                          name="password-confirm"
                          placeholder="Volve a ingresar la contraseña"
                          options={{ label: 'Confirmar Contraseña' }}
                        />

                        <ErrorMessage visible={!!signUpError}>Hubo un error al crear el nuevo usuario.</ErrorMessage>

                        <div className="form-group">
                          <Submit form="signup-form" isLoading={signUpLoading}>
                            Crear
                          </Submit>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const state = ({ authStore, staticsStore }) => {
  const { states: signUpStates } = authStore.signup;
  const { data: userTypes, states: userTypesStates } = staticsStore.userTypes;
  const { data: userRoles, states: userRolesStates } = staticsStore.userRoles;
  return {
    signUpStates,
    userTypes,
    userTypesStates,
    userRoles,
    userRolesStates,
  };
};

export default connect(state)(Page);
