import form from '../../../../../../constants/study/filters';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Submit from '../../../../../../components/Form/Submit';
import FormFactory from '../../../../../../helpers/FormFactory';
import './styles.scss';

interface ComponentProps {
  active: boolean;
  studyInformation;
  updateStudyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ active, studyInformation, updateStudyStates }: ComponentProps) => {
  if (!studyInformation) {
    return null;
  }

  return (
    <div id="study-filters" className={`study study-step ${active ? 'step-active' : ''}`}>
      <Row>
        <Col>
          <FormFactory form={form} data={{ idStudy: studyInformation?._id }} initialValues={studyInformation} />
        </Col>
      </Row>
      <div style={{ height: '20px' }} />
      <Row>
        <Col>
          <div className="form-group text-right">
            <Submit form={form.id} isLoading={updateStudyStates.loading}>
              Aplicar Filtros
            </Submit>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const state = ({ studyStore }) => {
  const { data: studyInformation } = studyStore.study;
  const { states: updateStudyStates } = studyStore.updateStudy;

  return { studyInformation, updateStudyStates };
};

export default connect(state)(Component);
