import { ListPanelists, LIST_PANELISTS } from '../types/panelist/list';
import { NewPanelist, NEW_PANELIST, ResetNewPanelist, RESET_NEW_PANELIST } from '../types/panelist/new';
import { GetPanelist, GET_PANELIST } from '../types/panelist/get';
import {
  UpdatePanelist,
  UpdatePanelistExtraInformation,
  ResetUpdatePanelist,
  UPDATE_PANELIST,
  UPDATE_PANELIST_EXTRAINFORMATION,
  RESET_UPDATE_PANELIST,
} from '../types/panelist/update';

import { GetPanelistsStatistics, GET_PANELISTS_STATISTICS } from '../types/panelist/statistics';

export const listPanelists = (filters): ListPanelists => {
  return { type: LIST_PANELISTS, payload: filters };
};

export const newPanelist = (payload: any): NewPanelist => {
  return { type: NEW_PANELIST, payload };
};

export const resetNewPanelist = (): ResetNewPanelist => {
  return { type: RESET_NEW_PANELIST, payload: null };
};

export const getPanelist = (idPanelist: number): GetPanelist => {
  return { type: GET_PANELIST, payload: idPanelist };
};

export const updatePanelist = (payload: any): UpdatePanelist => {
  return { type: UPDATE_PANELIST, payload };
};

export const resetUpdatePanelist = (): ResetUpdatePanelist => {
  return { type: RESET_UPDATE_PANELIST, payload: null };
};

export const updatePanelistExtraInformation = (payload: any): UpdatePanelistExtraInformation => {
  const { idPanelist, formId, ...rest } = payload;
  return {
    type: UPDATE_PANELIST_EXTRAINFORMATION,
    payload: { idPanelist, extraInformation: { [formId]: { ...rest } } },
  };
};

export const getPanelistsStatistics = (): GetPanelistsStatistics => {
  return { type: GET_PANELISTS_STATISTICS, payload: null };
};
