import {
  initialState,
  Actions,
  State,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from '../../types/user/change-password';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
