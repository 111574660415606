export const GET_STUDIES_STATISTICS = 'GET_STUDIES_STATISTICS';
export const GET_STUDIES_STATISTICS_SUCCESS = 'GET_STUDIES_STATISTICS_SUCCESS';
export const GET_STUDIES_STATISTICS_ERROR = 'GET_STUDIES_STATISTICS_ERROR';

export interface GetStudiesStatistics {
  type: typeof GET_STUDIES_STATISTICS;
  payload: any;
}

export interface GetStudiesStatisticsSuccess {
  type: typeof GET_STUDIES_STATISTICS_SUCCESS;
  payload: any;
}

export interface GetStudiesStatisticsError {
  type: typeof GET_STUDIES_STATISTICS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetStudiesStatistics | GetStudiesStatisticsSuccess | GetStudiesStatisticsError;
