import ReactSelect from '../../../../../components/Form/ReactSelect';
import { ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

interface ComponentProps {
  sexOptions;
  values?: any;
  type?: string;
  disabled?: boolean;
  errors?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  isMulti?: boolean;
}

const Filter = ({ sexOptions, errors, values, touched, ...rest }: ComponentProps) => {
  const hasError = !!errors && !!errors['sex'] && !!touched;

  return (
    <div className="form-group">
      <Row>
        <Col>
          <label className="col-form-label pt-0">Sexo</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactSelect
            name="sex"
            items={sexOptions}
            error={!!errors && errors['sex']}
            touched={touched}
            {...(!!values && { defaultVaue: values['sex'] })}
            {...rest}
          />
        </Col>
      </Row>
    </div>
  );
};

const states = ({ staticsStore }) => {
  const { data: sexOptions } = staticsStore.sexOptions;
  return {
    sexOptions,
  };
};

export default connect(states)(Filter);
