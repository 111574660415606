import './Spinner.scss';

interface ComponentProps {
  size?: 'large' | 'medium' | 'small';
  style?;
}

const Component = ({ size = 'small', ...rest }: ComponentProps) => {
  return (
    <div className={`spinner-box`} {...rest}>
      <div className={`spinner spinner-${size}`} />
    </div>
  );
};

export default Component;
