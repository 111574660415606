import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Component = () => {
  return (
    <Fragment>
      <div id="studies-list-empty-state">
        <h2>No se encontraron nuevos estudios</h2>

        <Link to="/dashboard/study/new">Crear nuevo estudio</Link>
      </div>
    </Fragment>
  );
};

export default Component;
