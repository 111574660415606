import { UserDto } from './../../../types/user';
export const GET_USER_MAIN_INFORMATION = 'GET_USER_MAIN_INFORMATION';
export const GET_USER_MAIN_INFORMATION_SUCCESS = 'GET_USER_MAIN_INFORMATION_SUCCESS';
export const GET_USER_MAIN_INFORMATION_ERROR = 'GET_USER_MAIN_INFORMATION_ERROR';
export const RESET_USER_MAIN_INFORMATION = 'RESET_USER_MAIN_INFORMATION';

export interface ResetUserCommonInformation {
  type: typeof RESET_USER_MAIN_INFORMATION;
  payload: null;
}

export interface GetUserCommonInformation {
  type: typeof GET_USER_MAIN_INFORMATION;
  payload: null;
}

export interface GetUserCommonInformationSuccess {
  type: typeof GET_USER_MAIN_INFORMATION_SUCCESS;
  payload: UserDto;
}

export interface GetUserCommonInformationError {
  type: typeof GET_USER_MAIN_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface State {
  data: UserDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetUserCommonInformation
  | GetUserCommonInformationSuccess
  | GetUserCommonInformationError
  | ResetUserCommonInformation;
