import slugify from 'slugify';
import { Field } from 'formik';
import { useState } from 'react';

interface ComponentProps {
  name: string;
  value?: string;
  disabled?: boolean;
  error?: {};
  touched?: {};
  items: {
    label: string;
    value: string | number;
  }[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  setFieldValue?;
  options: {
    label?: string;
  };
}

const Component = ({
  name,
  options,
  error,
  touched,
  items,
  onFocus,
  onBlur,
  onChange,
  setFieldValue,
  value,
  ...rest
}: ComponentProps) => {
  const { label } = options;

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className="form-group">
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      {items.map((item, index) => {
        const { label: itemLabel, value: itemValue } = item;
        const id = `${slugify(label || '', { lower: true })}-${index}`;
        return (
          <div className={`radio-option ${!!error && !!touched ? 'error-field' : ''}`} key={id}>
            <Field className="radio_animated" id={id} value={itemValue} name={name} type="radio" />
            <label htmlFor={id}>{itemLabel}</label>
          </div>
        );
      })}

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
