import { GET_USER_MAIN_INFORMATION, GetUserCommonInformation } from '../types/user/get-information';
import { UPDATE_USER_MAIN_INFORMATION, UpdateUserCommonInformation } from '../types/user/update-information';
import { RESET_USER_MAIN_INFORMATION, ResetUserCommonInformation } from '../types/user/reset-information';
import { ChangePassword, CHANGE_PASSWORD } from '../types/user/change-password';

export const getUserInformation = (): GetUserCommonInformation => {
  return { type: GET_USER_MAIN_INFORMATION, payload: null };
};

export const updateUserInformation = (payload): UpdateUserCommonInformation => {
  return { type: UPDATE_USER_MAIN_INFORMATION, payload };
};

export const resetUserStore = (): ResetUserCommonInformation => {
  return { type: RESET_USER_MAIN_INFORMATION, payload: null };
};

export const changePassword = (payload): ChangePassword => {
  return { type: CHANGE_PASSWORD, payload };
};
