import moment from 'moment';

const months = {
  1: {
    large: 'enero',
    short: 'ene',
  },
  2: {
    large: 'febrero',
    short: 'feb',
  },
  3: {
    large: 'marzo',
    short: 'mar',
  },
  4: {
    large: 'abril',
    short: 'abr',
  },
  5: {
    large: 'mayo',
    short: 'may',
  },
  6: {
    large: 'junio',
    short: 'jun',
  },
  7: {
    large: 'julio',
    short: 'jul',
  },
  8: {
    large: 'agosto',
    short: 'ago',
  },
  9: {
    large: 'septiembre',
    short: 'sep',
  },
  10: {
    large: 'octubre',
    short: 'oct',
  },
  11: {
    large: 'noviembre',
    short: 'nov',
  },
  12: {
    large: 'diciembre',
    short: 'dic',
  },
};

const days = {
  Mon: {
    large: 'lunes',
    short: 'lun',
  },
  Tues: {
    large: 'martes',
    short: 'mar',
  },
  Wed: {
    large: 'miercoles',
    short: 'mie',
  },
  Thu: {
    large: 'jueves',
    short: 'jue',
  },
  Fri: {
    large: 'viernes',
    short: 'vie',
  },
  Sat: {
    large: 'sabado',
    short: 'sab',
  },
  Sun: {
    large: 'domingo',
    short: 'dom',
  },
};

export const dateSpliter = (date) => {
  const utcDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).local();
  const dateObj = moment(utcDate).add(1, 'd');

  return {
    input: dateObj.format('YYYY-MM-dd'),
    month: {
      number: dateObj.format('M'),
      name: months[dateObj.format('M')],
    },
    day: {
      number: dateObj.format('D'),
      name: days[dateObj.format('ddd')],
    },
    year: dateObj.format('YYYY'),
    hours: dateObj.format('hh'),
    minutes: dateObj.format('mm'),
    seconds: dateObj.format('ss'),
  };
};

export const getNiceDate = (date) => {
  const dateSplited = dateSpliter(date);
  return `${dateSplited.day.number} de ${dateSplited.month.name.large} del ${dateSplited.year}`;
};

export const isExpired = (date) => {
  const utcDate = moment.utc(date).format('MMM D, YYYY h:mm:ss');
  const timeDate = new Date(utcDate).getTime();
  const today = new Date().getTime();
  const distance = timeDate - today;
  return distance <= 0;
};

export const getUtcDate = (date) => {
  const utcDate = moment.utc(date).format('D, YYYY h:mm:ss');
  const timeDate = new Date(utcDate).getTime();
  const today = new Date().getTime();
  const distance = timeDate - today;
  return distance <= 0;
};

export const inputDate = (date) => {
  const utcDate = date.split('T')[0];
  const dateObj = moment(utcDate);
  return dateObj.format('YYYY-MM-DD');
};

const date = {
  dateSpliter,
  isExpired,
  getNiceDate,
};

export default date;
