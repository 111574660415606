import { UserDto } from './../../../types/user';

export const UPDATE_USER_MAIN_INFORMATION = 'UPDATE_USER_MAIN_INFORMATION';
export const UPDATE_USER_MAIN_INFORMATION_SUCCESS = 'UPDATE_USER_MAIN_INFORMATION_SUCCESS';
export const UPDATE_USER_MAIN_INFORMATION_ERROR = 'UPDATE_USER_MAIN_INFORMATION_ERROR';

export interface UpdateUserCommonInformation {
  type: typeof UPDATE_USER_MAIN_INFORMATION;
  payload: null;
}

export interface UpdateUserCommonInformationSuccess {
  type: typeof UPDATE_USER_MAIN_INFORMATION_SUCCESS;
  payload: UserDto;
}

export interface UpdateUserCommonInformationError {
  type: typeof UPDATE_USER_MAIN_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | UpdateUserCommonInformation
  | UpdateUserCommonInformationSuccess
  | UpdateUserCommonInformationError;
