import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PanelistStudyDto } from '../../../../../../types/panelist-study';
import { PanelistDto } from '../../../../../../types/panelist';
import { fieldsLabels } from '../../../../../../constants/study/new-study';
import panelistStudies from '../../../../../../constants/dummy/panelist-studies';
import slugify from 'slugify';
import { StudyDto } from '../../../../../../types/study';
import { getNiceDate } from '../../../../../../helpers/dates-hanlder';
import moment from 'moment';
import './styles.scss';

interface ComponentProps {
  panelistInformation: PanelistDto;
}

const ENABLED_STUDY_FIELDS = {
  state: { enabled: false },
  filters: { enabled: false },
  _id: { enabled: false },
  minAge: { enabled: false },
  maxAge: { enabled: false },
  controls: { enabled: false },
  amountParticipants: { enabled: false },
  amountProducts: { enabled: false },
  costEstimationNumber: { enabled: true },
  payment: { enabled: false },
  revision: { enabled: false },
  startDate: { enabled: false },
  endDate: { enabled: true },
  laboratorieCode: { enabled: false },
  studyZones: { enabled: true },
  studyNumber: { enabled: false },
  studyType: { enabled: true },
  sex: { enabled: false },
};

const Component = ({ panelistInformation }: ComponentProps) => {
  const [panelistStudies, setPanelistStudies] = useState<StudyDto[]>();

  useEffect(() => {
    if (!!panelistInformation?.studies) {
      setPanelistStudies(panelistInformation.studies);
    }
  }, [panelistInformation]);

  if (!panelistStudies || panelistStudies.length <= 0) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Estudios realizados</h5>
        <div className="card-options">
          <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
            <i className="fe fe-chevron-up"></i>
          </a>
          <a className="card-options-remove" href="javascript" data-toggle="card-remove">
            <i className="fe fe-x"></i>
          </a>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap studies-list">
          <thead>
            <tr>
              {Object.keys(panelistStudies[0])
                .sort()
                .map((item, index) => {
                  return (
                    ENABLED_STUDY_FIELDS[item] &&
                    ENABLED_STUDY_FIELDS[item].enabled && <th key={slugify('th' + '-' + item)}>{fieldsLabels[item]}</th>
                  );
                })}
              <th key={slugify('th-study-link')}></th>
            </tr>
          </thead>
          <tbody>
            {panelistStudies
              .filter((study) => study.relation?.isSelected)
              .sort((a, b) => {
                const date1 = new Date(moment.utc(a.endDate).format('MMM D, YYYY')).getTime();
                const date2 = new Date(moment.utc(b.endDate).format('MMM D, YYYY')).getTime();
                return date1 - date2;
              })
              .map((study, index) => (
                <tr key={study._id + index}>
                  {Object.keys(study)
                    .sort()
                    .map((item) => {
                      const datesValues = ['endDate', 'startDate'];
                      const value = datesValues.includes(item) ? getNiceDate(study[item]) : study[item];

                      if (Array.isArray(study[item])) {
                        return (
                          <td className="capitalize" key={slugify('td' + '-' + study[item].join(', '))}>
                            {study[item].join(', ')}
                          </td>
                        );
                      }

                      return (
                        ENABLED_STUDY_FIELDS[item] &&
                        ENABLED_STUDY_FIELDS[item].enabled && (
                          <td className={item === 'studyType' ? 'uppercase' : ''} key={slugify('td' + '-' + item)}>
                            {value}
                          </td>
                        )
                      );
                    })}
                  <th key={slugify('td-study-link')}>
                    <Link to={`/dashboard/study/${study._id}`}>Ir al estudio</Link>
                  </th>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistInformation } = panelistStore.panelist;
  return { panelistInformation };
};
export default connect(state)(Component);
