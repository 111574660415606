import {
  initialState,
  Actions,
  State,
  GET_STUDY_ZONES,
  GET_STUDY_ZONES_SUCCESS,
  GET_STUDY_ZONES_ERROR,
} from '../../types/statics/study-zones';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_STUDY_ZONES:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_STUDY_ZONES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_STUDY_ZONES_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
