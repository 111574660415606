import { Filter } from '../../../types/panelist-filter';

export const UPDATE_STUDY = 'UPDATE_STUDY';
export const UPDATE_STUDY_SUCCESS = 'UPDATE_STUDY_SUCCESS';
export const UPDATE_STUDY_ERROR = 'UPDATE_STUDY_ERROR';
export const RESET_UPDATE_STUDY = 'RESET_UPDATE_STUDY';

export interface UpdateStudy {
  type: typeof UPDATE_STUDY;
  payload: { idStudy: string; filters: Filter[] } | null;
}
export interface UpdateStudySuccess {
  type: typeof UPDATE_STUDY_SUCCESS;
  payload: null;
}
export interface UpdateStudyError {
  type: typeof UPDATE_STUDY_ERROR;
  payload: boolean | string;
}
export interface ResetUpdateStudy {
  type: typeof RESET_UPDATE_STUDY;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = UpdateStudy | UpdateStudySuccess | UpdateStudyError | ResetUpdateStudy;
