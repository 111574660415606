import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import Submit from '../../../../../../components/Form/Submit';

import FieldComments from '../../../components/FieldComments';
import FieldAge from '../../../components/FieldAge';
import FieldAmountParticipants from '../../../components/FieldAmountParticipants';
import FieldAmountProducts from '../../../components/FieldAmountProducts';
import FieldControls from '../../../components/FieldControls';
import FieldCostStimationNumber from '../../../components/FieldCostStimationNumber';
import FieldEndDate from '../../../components/FieldEndDate';
import FieldLaboratoryCode from '../../../components/FieldLaboratoryCode';
import FieldPayment from '../../../components/FieldPayment';
import FieldRevision from '../../../components/FieldRevision';
import FieldSex from '../../../components/FieldSex';
import FieldStartDate from '../../../components/FieldStartDate';
import FieldStudyNumber from '../../../components/FieldStudyNumber';
import FieldType from '../../../components/FieldType';
import FieldZones from '../../../components/FieldZones';

import { newStudy, resetStudyNew, duplicateStudy, resetDuplicateStudy } from '../../../../../../store/actions/study';
import { initialValues as newStudyInitialValues, rules } from '../../../../../../constants/study/new-study';
import { useHistory } from 'react-router';
import { urlParamExist } from '../../../../../../helpers/get-url-param';

interface ComponentProps {
  newStudyStates;
  newStudyData;
  duplicateStudyData;
  duplicateStudyStates;
  active: boolean;
}

const Component = ({
  active,
  newStudyStates,
  newStudyData,
  duplicateStudyStates,
  duplicateStudyData,
}: ComponentProps) => {
  const duplicateStudyFlow = urlParamExist('duplicate-study');
  const [studyToBeDuplicated, setStudyToBeDuplicated] = useState({});
  const [initialValues, setInitialValues] = useState(newStudyInitialValues);
  const { error: newStudyError, loading: newStudyLoading, success: newStudySuccess } = newStudyStates;
  const {
    error: duplicateStudyError,
    loading: duplicateStudyLoading,
    success: duplicateStudySuccess,
  } = duplicateStudyStates;
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    if (duplicateStudyFlow) {
      SweetAlert.fire({
        title: 'Se duplicara el estudio',
        text: 'Los panelistas y filtros del estudio a duplicar, tambien seran migrados',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(duplicateStudy({ ...duplicateStudy, ...values }));
        },
      });
    } else {
      dispatch(newStudy(values));
    }
  };

  useEffect(() => {
    if (newStudySuccess) {
      SweetAlert.fire({
        title: 'Estudio creado satisfactoriamente',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetStudyNew());
          setTimeout(() => {
            history.push(`/dashboard/study/${newStudyData._id}`);
          }, 300);
        },
      });
    }
  }, [newStudySuccess]);

  useEffect(() => {
    if (newStudyError) {
      SweetAlert.fire({
        title: 'Error al crear estudio',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetStudyNew());
        },
      });
    }
  }, [newStudyError]);

  useEffect(() => {
    if (duplicateStudySuccess) {
      const thereArePanelistas = duplicateStudyData?.panelists?.length > 0;

      SweetAlert.fire({
        title: 'Estudio duplicado satisfactoriamente',
        text: thereArePanelistas
          ? `Se migraron ${duplicateStudyData.panelists.length} panelistas al estudio.`
          : 'No se encontraron panelistas a migrar.',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetDuplicateStudy());
          setTimeout(() => {
            history.push(`/dashboard/study/${duplicateStudyData._id}`);
          }, 300);
        },
      });
    }
  }, [duplicateStudySuccess]);

  useEffect(() => {
    if (duplicateStudyError) {
      SweetAlert.fire({
        title: 'Error al duplicar estudio',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetDuplicateStudy());
        },
      });
    }
  }, [duplicateStudyError]);

  useEffect(() => {
    const studyToDuplicate = localStorage.getItem('duplicate-study');

    if (!!studyToDuplicate && duplicateStudyFlow) {
      const studyToDuplicateJson = JSON.parse(studyToDuplicate);
      const { costEstimationNumber, studyNumber, _id, ...rest } = studyToDuplicateJson;
      setStudyToBeDuplicated(rest);
      localStorage.removeItem('duplicate-study');
    }
  }, []);

  return (
    <div id="study-common-information" className={`study study-step ${active ? 'step-active' : ''}`}>
      <Formik
        initialValues={{ ...initialValues, ...studyToBeDuplicated }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={rules}
        enableReinitialize
      >
        {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleReset, handleSubmit }) => {
          return (
            <form className="theme-form" onSubmit={handleSubmit} id="new-study-form">
              <Row>
                <Col>
                  <FieldStudyNumber
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <FieldCostStimationNumber
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldRevision
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldLaboratoryCode
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldPayment
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldType errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
                <Col md={6}>
                  <FieldAmountProducts
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldStartDate
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldEndDate
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldAmountParticipants
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldSex errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldAge
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldControls
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldZones errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldComments
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="form-group text-right">
                    <Submit form="new-study-form" isLoading={newStudyLoading || duplicateStudyLoading}>
                      Crear
                    </Submit>
                  </div>
                </Col>
              </Row>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const states = ({ studyStore }) => {
  const { data: newStudyData, states: newStudyStates } = studyStore.newStudy;
  const { data: duplicateStudyData, states: duplicateStudyStates } = studyStore.duplicateStudy;
  return {
    newStudyStates,
    newStudyData,
    duplicateStudyData,
    duplicateStudyStates,
  };
};
export default connect(states)(Component);
