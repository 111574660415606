import { all } from 'redux-saga/effects';
import auth from './auth';
import user from './user';
import profile from './profile';
import panelist from './panelist';
import statics from './statics';
import study from './study';
import panelistStudy from './panelist-study';

export default function* rootSaga() {
  yield all([auth(), user(), profile(), panelist(), statics(), study(), panelistStudy()]);
}
