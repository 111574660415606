import { combineReducers } from 'redux';

import panelist from './get';
import panelistList from './list';
import updatePanelist from './update';
import newPanelist from './new';

import newTechnicalInformation from './newTechnicalInformation';
import technicalInformation from './getTechnicalInformation';
import updateTechnicalInformation from './updateTechnicalInformation';

import newCommonInformation from './newCommonInformation';
import commonInformation from './getCommonInformation';
import updateCommonInformation from './updateCommonInformation';

import statistics from './statistics';

const reducer = combineReducers({
  panelist,
  newPanelist,
  updatePanelist,
  panelistList,
  technicalInformation,
  newTechnicalInformation,
  updateTechnicalInformation,
  commonInformation,
  newCommonInformation,
  updateCommonInformation,
  statistics,
});

export default reducer;
