import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'FICHA TÉCNICA - GENERAL',
  id: 'ficha-tecnica-general',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Responsable de Claim',
      name: 'responsable-claim',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: 'Fecha de ficha',
      name: 'fecha-de-ficha',
      description: '',
      type: 'date',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: 'Fecha técnica',
      name: 'fecha-tecnica',
      description: '',
      type: 'date',
      validationType: 'string',
      validations: [{ type: 'max', params: [30, 'Maximo 30 carácteres'] }],
    },
    {
      label: '¿Apto parche?',
      name: 'apto-parche',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Tuvo Covid-19?',
      name: 'tuvo-covid-19',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Se vacuno?',
      name: 'se-vacuno',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Que vacuna se aplico?',
      name: 'que-vacuna-se-aplico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'se-vacuno',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Cuantas dosis?',
      name: 'cuantas-dosis',
      description: '',
      type: 'number',
      validationType: 'number',
      validations: [
        {
          type: 'when',
          params: [
            'se-vacuno',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Cuál es el color natural de su piel cuando no esta bronceada?',
      name: 'cual-es-el-color-natural-de-su-piel-cuando-no-esta-bronceada',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 Rojisa, blanca',
          value: '0-rojisa-blanca',
        },
        {
          label: '2 Blanca - beige',
          value: '2-blanca-beige',
        },
        {
          label: '4 Beige',
          value: '4-beige',
        },
        {
          label: '8 Marrón clara',
          value: '8-marron-clara',
        },
        {
          label: '12 Marrón',
          value: '12-marron',
        },
        {
          label: '16 Negra',
          value: '16-negra',
        },
      ],
    },
    {
      label: '¿De qué color natural es su pelo?',
      name: 'de-que-color-natural-es-su-pelo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 pelirrojo, rubio claro',
          value: '0-pelirrojo-rubio-claro',
        },
        {
          label: '2 Rubio, castaño claro',
          value: '2-rubio-castano-claro',
        },
        {
          label: '4 Castaño',
          value: '4-castano',
        },
        {
          label: '8 Castaño oscuro',
          value: '8-castano-oscuro',
        },
        {
          label: '12 Castaño oscuro - negro',
          value: '12-catano-oscuro-negro',
        },
        {
          label: '16 Negro',
          value: '16-negro',
        },
      ],
    },
    {
      label: '¿De qué color tiene los ojos?',
      name: 'de-que-color-tiene-los-ojos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 Azul claro, verde claro, gris claro',
          value: '0-azul-claro-verde-claro-gris-claro',
        },
        {
          label: '2 Azules, verdes, grises',
          value: '2-Azules-verdes-grises',
        },
        {
          label: '4 Grises, marron claro',
          value: '4-grises-marron-claro',
        },
        {
          label: '8 Marrones',
          value: '8-marrones',
        },
        {
          label: '12 Marron oscuro',
          value: '12-marron-oscuro',
        },
        {
          label: '16 Negros',
          value: '16-negros',
        },
      ],
    },
    {
      label: '¿Cuantas pecas tiene de manera natural en el cuerpo cuando no esta bronceado?',
      name: 'cuantas-pecas-tiene-de-manera-natural-en-el-cuerpo-cuando-no-esta-bronceado',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 Muchas',
          value: '0-muchas',
        },
        {
          label: '4 Algunas',
          value: '4-algunas',
        },
        {
          label: '6 Unas cuantas',
          value: '6-unas-cuantas',
        },
        {
          label: '8 Ninguna',
          value: '8-ninguna',
        },
      ],
    },
    {
      label: '¿Qué categoría describe mejor su herencia genética?',
      name: 'que-categoria-describe-mejor-su-herencia-genetica',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 raza blanca de piel muy blanca',
          value: '0-raza-blanca-de-piel-muy-blanca',
        },
        {
          label: '2 raza blanca de piel clara',
          value: '2-raza-blanca-de-piel-clara',
        },
        {
          label: '4 Raza blanca piel morena (mediterráneo)',
          value: '4-raza-blanca-piel-morena-mediterraneo',
        },
        {
          label: '8 Oriente medio, hindú, asiático, hispano-americano',
          value: '8-oriente-medio-hindu-asiatico-hispano-americano',
        },
        {
          label: '12 Aborigen, africano, afroamericano',
          value: '12-aborigen-africano-afroamericano',
        },
      ],
    },
    {
      label: '¿Qué categoría describe mejor su potencial de quemadura después de exponerse al sol una hora en verano?',
      name: 'que-categoria-describe-mejor-su-potencial-de-quemadura-despues-de-exponerse-al-sol-una-hora-en-verano',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 siempre se quema y no se broncea nunca',
          value: '0-siempre-se-quema-y-no-se-broncea-nunca',
        },
        {
          label: '2 habitualmente se quema, pero puede broncearse ligeramente',
          value: '2-habitualmente-se-quema-pero-puede-broncearse-ligeramente',
        },
        {
          label: '4 se quema ocasionalmente, pero se broncea moderadamente',
          value: '4-se-quema-ocasionalmente-pero-se-broncea-moderadamente',
        },
        {
          label: '8 nunca se quema y se broncea con facilidad',
          value: '8-nunca-se-quema-y-se-broncea-con-facilidad',
        },
        {
          label: '10 raramente se quema y se broncea profundamente',
          value: '10-raramente-se-quema-y-se-broncea-profundamente',
        },
        {
          label: '12 nunca se quema',
          value: '12-nunca-se-quema',
        },
      ],
    },
    {
      label: '¿Qué categoría describe mejor su potencial de bronceado?',
      name: 'que-categoria-describe-mejor-su-potencial-de-bronceado',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0 nunca se broncea',
          value: '0-nunca-se-broncea',
        },
        {
          label: '2 se puede broncear ligeramente',
          value: '2-se-puede.broncear-ligeramente',
        },
        {
          label: '4 se puede broncear moderadamente',
          value: '4-se-puede-broncear-moderadamente',
        },
        {
          label: '8 se puede broncear profundamente',
          value: '8-se-puede-broncear-profundamente',
        },
      ],
    },
    {
      label: 'Puntuación total:       Fototipo: ',
      name: 'puntuacion-total-fototipo',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: '0-7 Fototipo I',
          value: '0-7-fototipo-i',
        },
        {
          label: '8-21 Fototipo II',
          value: '8-21-fototipo-ii',
        },
        {
          label: '22-42 Fototipo III',
          value: '22-42-fototipo-iii',
        },
        {
          label: '43-68 Fototipo IV',
          value: '43-68-fototipo-iv',
        },
        {
          label: '96-84 Fototipo V',
          value: '96-84-fototipo-v',
        },
        {
          label: '+85 Fototipo VI',
          value: '85-fototipo-vi',
        },
      ],
    },
    {
      label: 'Rosácea',
      name: 'rosacea',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Ubicación',
      name: 'ubicacion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'rosacea',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Rosácea - apto foto',
      name: 'rosacea-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'rosacea',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Eczemas',
      name: 'eczemas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Facial',
          value: 'facial',
        },
        {
          label: 'Corporal',
          value: 'corporal',
        },
        {
          label: 'Manos',
          value: 'manos',
        },
        {
          label: 'Cuero cabelludo',
          value: 'cuero-cabelludo',
        },
      ],
    },
    {
      label: 'Ubicación',
      name: 'ubicacion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Psoriasis',
      name: 'psoriasis',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'No',
        },
      ],
    },
    {
      label: 'Vitíligo',
      name: 'vitiligio',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Dermatitis ',
      name: 'demartitis',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Foliculitis',
      name: 'foliculitis',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Queratosis',
      name: 'queratosis',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
  ],
};

export default form;
