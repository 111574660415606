import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  RESET_USER_PROFILE,
} from '../types/profile';

function* getUserProfile() {
  try {
    const payload = yield call(services.user.getUserProfile);
    yield put({ type: GET_USER_PROFILE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: GET_USER_PROFILE_ERROR, payload: error });
  }
}

function* updateUserProfile({ payload }) {
  try {
    const result = yield call(services.user.updateUserProfile, payload);
    yield put({ type: UPDATE_USER_PROFILE_SUCCESS, result });
  } catch (error) {
    yield put({ type: UPDATE_USER_PROFILE_ERROR, payload: error });
  }
}

function* resetUserProfile() {
  yield put({ type: RESET_USER_PROFILE });
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_USER_PROFILE, getUserProfile);
  yield takeLatest<any>(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeLatest<any>(RESET_USER_PROFILE, resetUserProfile);
}
