export const RESET_USER_MAIN_INFORMATION = 'RESET_USER_MAIN_INFORMATION';

export interface ResetUserCommonInformation {
  type: typeof RESET_USER_MAIN_INFORMATION;
  payload: null;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = ResetUserCommonInformation;
