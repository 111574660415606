import { ProvinciaDto } from '../../../types/provincia';
import { PartidoDto } from '../../../types/partido';
import { LocalidadDto } from '../../../types/localidad';

export const GET_PROVINCIAS = 'GET_PROVINCIAS';
export const GET_PROVINCIAS_SUCCESS = 'GET_PROVINCIAS_SUCCESS';
export const GET_PROVINCIAS_ERROR = 'GET_PROVINCIAS_ERROR';

export interface GetProvincias {
  type: typeof GET_PROVINCIAS;
  payload: any;
}
export interface GetProvinciasSuccess {
  type: typeof GET_PROVINCIAS_SUCCESS;
  payload: null;
}
export interface GetProvinciasError {
  type: typeof GET_PROVINCIAS_ERROR;
  payload: boolean | string;
}

export const GET_PARTIDOS = 'GET_PARTIDOS';
export const GET_PARTIDOS_SUCCESS = 'GET_PARTIDOS_SUCCESS';
export const GET_PARTIDOS_ERROR = 'GET_PARTIDOS_ERROR';

export interface GetPartidos {
  type: typeof GET_PARTIDOS;
  payload: any;
}
export interface GetPartidosSuccess {
  type: typeof GET_PARTIDOS_SUCCESS;
  payload: null;
}
export interface GetPartidosError {
  type: typeof GET_PARTIDOS_ERROR;
  payload: boolean | string;
}

export const GET_LOCALIDADES = 'GET_LOCALIDADES';
export const GET_LOCALIDADES_SUCCESS = 'GET_LOCALIDADES_SUCCESS';
export const GET_LOCALIDADES_ERROR = 'GET_LOCALIDADES_ERROR';

export interface GetLocalidades {
  type: typeof GET_LOCALIDADES;
  payload: any;
}
export interface GetLocalidadesSuccess {
  type: typeof GET_LOCALIDADES_SUCCESS;
  payload: null;
}
export interface GetLocalidadesError {
  type: typeof GET_LOCALIDADES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: {
    provincias: ProvinciaDto | null;
    partidos: PartidoDto | null;
    localidades: LocalidadDto | null;
  };
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: {
    provincias: null,
    partidos: null,
    localidades: null,
  },
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetProvincias
  | GetProvinciasSuccess
  | GetProvinciasError
  | GetPartidos
  | GetPartidosSuccess
  | GetPartidosError
  | GetLocalidades
  | GetLocalidadesSuccess
  | GetLocalidadesError;
