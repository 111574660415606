import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from '../../../../../Study/List/components/SimplifiedCard';
import './styles.scss';
import slugify from 'slugify';

const Component = ({ studiesStatistics }) => {
  return (
    <div className="card" id="studies-list">
      <div className="card-header">
        <h2>
          Estudios Activos <Link to={'/dashboard/study/list'}>Ir al listado</Link>
        </h2>
      </div>
      <div className="card-body">
        <div id="statistics-studies-list">
          <ul>
            {studiesStatistics?.lastStudies.map((study, index) => (
              <li key={slugify(`${study._id}`)}>
                <Card study={study} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const state = ({ studyStore }) => {
  const { data: studiesStatistics } = studyStore.statistics;
  return { studiesStatistics };
};

export default connect(state)(Component);
