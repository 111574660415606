export const CONTACTED_PANELIST = 'CONTACTED_PANELIST';
export const CONTACTED_PANELIST_SUCCESS = 'CONTACTED_PANELIST_SUCCESS';
export const CONTACTED_PANELIST_ERROR = 'CONTACTED_PANELIST_ERROR';

export interface PanelistContacted {
  type: typeof CONTACTED_PANELIST;
  payload: any;
}
export interface PanelistContactedSuccess {
  type: typeof CONTACTED_PANELIST_SUCCESS;
  payload: null;
}
export interface PanelistContactedError {
  type: typeof CONTACTED_PANELIST_ERROR;
  payload: boolean | string;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = PanelistContacted | PanelistContactedSuccess | PanelistContactedError;
