import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'HISTORIA CLÍNICA',
  id: 'historia-clinica',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Enfermedades crónicas',
      name: 'enfermedades-cronicas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'En caso de tener una enfermedad crónica, ¿Cuál es el diagnóstico?',
      name: 'enfermedad-cronica-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'enfermedades-cronicas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'En caso de tener una enfermedad crónica, ¿Qué tratamiento tiene?',
      name: 'enfermedad-cronica-tratamiento',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'enfermedades-cronicas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Toma Medicación?',
      name: 'toma-medicacion',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'En caso de tomar medicación, ¿Cuál medicación?',
      name: 'toma-medicacion-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'toma-medicacion',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Alergias?',
      name: 'alergias',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'En caso de tener alergias, ¿Cuáles?',
      name: 'cual-alergia',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'alergias',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Presión arterial?',
      name: 'presion-arterial',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Alta',
          value: 'alta',
        },
        {
          label: 'Baja',
          value: 'baja',
        },
        {
          label: 'Normal',
          value: 'normal',
        },
      ],
    },
    {
      label: 'Antecedentes digestivos',
      name: 'antecedentes-digestivos',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'antecedentes-digestivos-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'antecedentes-digestivos',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Antecedentes circulatorios',
      name: 'antecedentes-circulatorios',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'antecedentes-circulatorios-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'antecedentes-circulatorios',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Antecedentes oculares: Enfermedades reumatológicas autoinmunes ',
      name: 'antecedentes-oculares',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Artritis reumatoidea',
          value: 'artritis-reumatoidea',
        },
        {
          label: 'Lupus',
          value: 'lupus',
        },
        {
          label: 'Tiroiditis',
          value: 'tiroiditis',
        },
        {
          label: 'Sjogren',
          value: 'sjogren',
        },
        {
          label: 'Rosácea',
          value: 'rosacea',
        },
      ],
    },
    {
      label: 'Antecedentes oculares: Uso de lentes de contactos',
      name: 'uso-de-lentes-de-contactos',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Antecedentes oculares: Tratamiento ocular crónico',
      name: 'tratamiento-ocular-cronico',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Glaucoma',
          value: 'glaucoma',
        },
        {
          label: 'Ojos secos severo',
          value: 'ojos-secos-severo',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'tratamiento-ocular-cronico-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'tratamiento-ocular-cronico',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Antecedentes odontológicos:',
      name: 'antecedentes-odontologicos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Implantes',
          value: 'implantes',
        },
        {
          label: 'Prótesis',
          value: 'protesis',
        },
        {
          label: 'Gingivitis',
          value: 'gingivitis',
        },
        {
          label: 'Periodontitis',
          value: 'periodontitis',
        },
        {
          label: 'Trastorno de las encías ',
          value: 'trastorno-de-las-encias',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'antecedentes-odontologicos-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'antecedentes-odontologicos',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Menopausia? ',
      name: 'menopausia',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Edad de comienzo',
      name: 'edad-de-comienzo',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'menopausia',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Medicación/Tratamiento Menopausia?',
      name: 'medicacion-tratamiento-menopausia',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'medicacion-tratamiento-menopausia-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'medicacion-tratamiento-menopausia',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Alimentación',
      name: 'alimentacion',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Come de todo',
          value: 'come-de-todo',
        },
        {
          label: 'Vegetarianismo',
          value: 'vegetarianismo',
        },
        {
          label: 'Veganismo',
          value: 'veganismo',
        },
        {
          label: 'Plan de alimentación especial',
          value: 'plan-de-alimentacion-especial',
        },
        {
          label: 'Suplemento dietario',
          value: 'suplemento-dietario',
        },
        {
          label: 'Sin TACC',
          value: 'sin-tacc',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'alimentacion-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'alimentacion',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Tatuajes?',
      name: 'tatuajes',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'tatuajes-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'tatuajes',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿En el antebrazo?',
      name: 'en-el-antebrazo',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Piercing?',
      name: 'piercing',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Si, se lo puede sacar',
          value: 'si-se-lo-puede-sacar',
        },
        {
          label: 'Si, no se lo puede sacar',
          value: 'si-no-se-lo-puede-sacar',
        },
        {
          label: 'No tiene',
          value: 'no-tiene',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'piercing-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'piercing',
            {
              is: 'si-se-lo-puede-sacar',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
        {
          type: 'when',
          params: [
            'piercing',
            {
              is: 'si-no-se-lo-puede-sacar',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Realiza actividad al aire libre?',
      name: 'realiza-actividad-al-aire-libre',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Cuál?',
      name: 'realiza-actividad-al-aire-libre-cual',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'realiza-actividad-al-aire-libre',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Toma sol?',
      name: 'toma-sol',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Usa protección solar?',
      name: 'usa-protecccion-solar',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Tipo de producto',
      name: 'tipo-de-producto',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Loción',
          value: 'locion',
        },
        {
          label: 'Crema',
          value: 'crema',
        },
        {
          label: 'Emulsión fluida (sprayable)',
          value: 'emulsion-fluida',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'usa-protecccion-solar',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Factor',
      name: 'factor',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Alta (+50 fps)',
          value: 'alta',
        },
        {
          label: 'Media (50 a 15 fps)',
          value: 'media',
        },
        {
          label: 'Baja (-15 fps)',
          value: 'baja',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'usa-protecccion-solar',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Usa productos para el bronceado?',
      name: 'usa-productos-para-el-bronceado',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿De qué forma?',
      name: 'de-que-forma',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Tópico',
          value: 'topico',
        },
        {
          label: 'Comprimido',
          value: 'comprimido',
        },
        {
          label: 'Productos para el bronceado',
          value: 'productos-para-el-bronceado',
        },
        {
          label: 'Acelerador',
          value: 'acelerador',
        },
        {
          label: 'Autobronceante',
          value: 'autobronceante',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'usa-productos-para-el-bronceado',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Utiliza cama solar?',
      name: 'utiliza-cama-solar',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Conoce los efectos que produce el sol en la piel?',
      name: 'conoce-los-efectos-que-produce-el-sol-en-la-piel',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Puede mencionar algunos?',
      name: 'puede-mecionar-algunos',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'conoce-los-efectos-que-produce-el-sol-en-la-piel',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Hábitos del sueño: ¿Cuántas horas duerme por día?',
      name: 'habitos-del-sueno-horas-duerme-por-dia',
      description: '',
      type: 'number',
      validationType: 'number',
      validations: [{ type: 'max', params: [24, 'Maximo 24 carácteres'] }],
    },
    {
      label: '¿Ronca?',
      name: 'ronca',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Tiene indicación de tratamiento?',
      name: 'indicacion-de-tratamiento',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Fuma?',
      name: 'fuma',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Si, actualmente',
          value: 'si-actualmente',
        },
        {
          label: 'No',
          value: 'no',
        },
        {
          label: 'Si, en el pasado',
          value: 'si-en-el-pasado',
        },
      ],
    },
    {
      label: '¿Qué cantidad?',
      name: 'que-cantidad',
      description: '',
      type: 'number',
      validationType: 'number',
      validations: [
        {
          type: 'when',
          params: [
            'fuma',
            {
              is: 'si-actualmente',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
        {
          type: 'when',
          params: [
            'fuma',
            {
              is: 'si-en-el-pasado',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
  ],
};
export default form;
