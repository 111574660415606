import { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import FilterByAge from './components/FieldAge';
import FilterByLocation from './components/FieldLocation';
import FilterState from './components/FieldState';
import FilterStudyType from './components/FieldStudyType';
import FieldName from './components/FieldName';
import FieldLastName from './components/FieldLastName';
import FieldPanelistNumber from './components/FieldPanelistNumber';
import FieldSex from './components/FieldSex';
import './styles.scss';
import { listPanelists } from '../../../../../../store/actions/panelist';

const yupRules = Yup.object().shape({});
const initialValues = {};

const MyAutoSavingComponent = () => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};

const Component = ({ panelistList }) => {
  const dispatch = useDispatch();

  const onFormSubmit = (data) => {
    dispatch(listPanelists(data));
  };

  return (
    <div id="filters">
      <div className="card">
        <div className="card-header">
          <h6 className="m-0">Filtros</h6>
          <div className="total-filtered">
            <Badge color="light">
              {panelistList?.limit * panelistList?.page > panelistList?.totalDocs
                ? panelistList?.totalDocs
                : panelistList?.limit * panelistList?.page}{' '}
              de {panelistList?.totalDocs}
            </Badge>
          </div>
        </div>

        <div className="card-body">
          <Formik initialValues={initialValues} onSubmit={(event) => onFormSubmit(event)} validationSchema={yupRules}>
            {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleSubmit }) => {
              return (
                <form className="theme-form" onSubmit={handleSubmit} id="study-filters-form">
                  <FieldName errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldLastName errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldPanelistNumber errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldSex errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                  <FilterByAge errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FilterByLocation errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FilterState errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                  <MyAutoSavingComponent />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistList, filters: appliedFilters } = panelistStore.panelistList;
  return { panelistList, appliedFilters };
};
export default connect(state)(Component);
