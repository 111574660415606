import { FilterFormField } from '../../../types/form-field';

export const historiaClinicaFilters: FilterFormField[] = [
  {
    type: 'title',
    label: 'HISTORIA CLÍNICA',
    name: '',
    validationType: 'string',
  },
  {
    label: 'Enfermedades crónicas',
    name: 'extraInformation_historia-clinica_enfermedades-cronicas',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: '¿Toma Medicación?',
    name: 'extraInformation_historia-clinica_toma-medicacion',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: '¿Alergias?',
    name: 'extraInformation_historia-clinica_alergias',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Antecedentes odontologicos',
    name: 'extraInformation_historia-clinica_antecedentes-odontologicos',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Implantes',
        value: 'implantes',
      },
      {
        label: 'Prótesis',
        value: 'protesis',
      },
      {
        label: 'Gingivitis',
        value: 'gingivitis',
      },
      {
        label: 'Periodontitis',
        value: 'periodontitis',
      },
      {
        label: 'Trastorno de las encías ',
        value: 'trastorno-de-las-encias',
      },
      {
        label: 'Otro',
        value: 'otro',
      },
    ],
  },
  {
    label: 'Antecedentes oculares: Enfermedades reumatológicas autoinmunes ',
    name: 'extraInformation_historia-clinica_antecedentes-oculares',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Artritis reumatoidea',
        value: 'artritis-reumatoidea',
      },
      {
        label: 'Lupus',
        value: 'lupus',
      },
      {
        label: 'Tiroiditis',
        value: 'tiroiditis',
      },
      {
        label: 'Sjogren',
        value: 'sjogren',
      },
      {
        label: 'Rosácea',
        value: 'rosacea',
      },
    ],
  },
  {
    label: 'Antecedentes oculares: Uso de lentes de contactos',
    name: 'extraInformation_historia-clinica_uso-de-lentes-de-contactos',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: '¿Tatuajes?',
    name: 'extraInformation_historia-clinica_tatuajes',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: '¿En el antebrazo?',
    name: 'extraInformation_historia-clinica_en-el-antebrazo',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: '¿Fuma?',
    name: 'extraInformation_historia-clinica_fuma',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Si, actualmente',
        value: 'si-actualmente',
      },
      {
        label: 'No actualmente',
        value: 'no-actualmente',
      },
      {
        label: 'Si, en el pasado',
        value: 'si-en-el-pasado',
      },
    ],
  },
];
