import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';
import { urls } from '../../constants/urls';

interface ComponentProps {
  locationData: {
    pathname: string;
  };
}

const Component = ({ locationData }: ComponentProps) => {
  const [breadcrumb, setBreadcrumb] = useState<{
    pageTitle: string;
    label: string;
  }>();

  useEffect(() => {
    if (locationData) {
      const pathInfo = urls.filter((url) => locationData.pathname.match(url.regex))[0];
      setBreadcrumb(pathInfo);
    }
  }, [locationData]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{breadcrumb?.pageTitle}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/home">
                      <Home />
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">{breadcrumb?.label}</li>

                  {/* {breadcrumbData?.sections.map((section, index) => {
                    const isLastItem = index + 1 === breadcrumbData?.sections.length;
                    return <li className={`breadcrumb-item ${isLastItem ? 'active' : ''}`}>{section.title}</li>;
                  })} */}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const state = ({ locationStore }) => {
  const { data: locationData } = locationStore;
  return {
    locationData,
  };
};

export default connect(state)(Component);
