import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

interface ComponentProps {
  name?: string;
  lastName?: string;
  picture?: string;
}

const Component = ({ name, lastName, picture }: ComponentProps) => {
  const [userNameInitials, setUserNameInitials] = useState('');

  useEffect(() => {
    const nameFirstLetter = () => {
      const names = name?.split(' ');
      const namesFirstLetter = names?.map((name) => name?.charAt(0));
      return namesFirstLetter?.toString().replace(/,/g, '');
    };
    const lastnameFirstLetter = lastName?.charAt(0);
    setUserNameInitials(`${nameFirstLetter()}${lastnameFirstLetter}`);
  }, [name, lastName]);

  return (
    <div className="avatar">
      {!!picture ? (
        <img className="img-40 rounded-circle lazyloaded blur-up" src={picture} alt="#" />
      ) : (
        userNameInitials
      )}
    </div>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
  };
};

export default connect(state)(Component);
