import { Container, Row, Col } from 'reactstrap';
import Studies from './components/Studies';
import Panelists from './components/Panelists';
import TotalPanelists from './components/TotalPanelists';
import TotalStudies from './components/TotalStudies';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getPanelistsStatistics } from '../../../store/actions/panelist';
import { getStudiesStatistics } from '../../../store/actions/study';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPanelistsStatistics());
    dispatch(getStudiesStatistics());
  }, []);

  return (
    <Container fluid={true}>
      <Row>
        <Col sm={6}>
          <TotalPanelists />
        </Col>
        <Col sm={6}>
          <TotalStudies />
        </Col>
      </Row>

      <Row>
        <Col sm={6} lg={6}>
          <Panelists />
        </Col>
        <Col sm={6} lg={6}>
          <Studies />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
