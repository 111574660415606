import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import { GET_USER_TYPES, GET_USER_TYPES_SUCCESS, GET_USER_TYPES_ERROR } from '../types/statics/user-types';
import { GET_USER_ROLES, GET_USER_ROLES_SUCCESS, GET_USER_ROLES_ERROR } from '../types/statics/user-roles';
import { GET_STUDY_TYPES, GET_STUDY_TYPES_SUCCESS, GET_STUDY_TYPES_ERROR } from '../types/statics/study-types';
import { GET_STUDY_STATES, GET_STUDY_STATES_SUCCESS, GET_STUDY_STATES_ERROR } from '../types/statics/study-states';
import { GET_STUDY_ZONES, GET_STUDY_ZONES_SUCCESS, GET_STUDY_ZONES_ERROR } from '../types/statics/study-zones';
import { GET_SEX_OPTIONS, GET_SEX_OPTIONS_SUCCESS, GET_SEX_OPTIONS_ERROR } from '../types/statics/sex-options';
import {
  GET_PANELIST_STATES_SUCCESS,
  GET_PANELIST_STATES_ERROR,
  GET_PANELIST_STATES,
} from '../types/statics/panelist-states';
import {
  GET_PROVINCIAS,
  GET_PROVINCIAS_SUCCESS,
  GET_PROVINCIAS_ERROR,
  GET_PARTIDOS,
  GET_PARTIDOS_SUCCESS,
  GET_PARTIDOS_ERROR,
  GET_LOCALIDADES,
  GET_LOCALIDADES_SUCCESS,
  GET_LOCALIDADES_ERROR,
} from '../types/statics/locations';
/**
 * Pending Task
 * Check if store is filled before call to BFF.
 */

function* getStudyStates() {
  try {
    const result = yield call(services.statics.getStudyStates);
    yield put({ type: GET_STUDY_STATES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_STUDY_STATES_ERROR, payload: error });
  }
}

function* getStudyTypes() {
  try {
    const result = yield call(services.statics.getStudyTypes);
    yield put({ type: GET_STUDY_TYPES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_STUDY_TYPES_ERROR, payload: error });
  }
}

function* getStudyZones() {
  try {
    const result = yield call(services.statics.getStudyZones);
    yield put({ type: GET_STUDY_ZONES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_STUDY_ZONES_ERROR, payload: error });
  }
}

function* getUserTypes() {
  try {
    const result = yield call(services.statics.getUserTypes);
    yield put({ type: GET_USER_TYPES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_USER_TYPES_ERROR, payload: error });
  }
}

function* getUserRoles() {
  try {
    const result = yield call(services.statics.getUserRoles);
    yield put({ type: GET_USER_ROLES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_USER_ROLES_ERROR, payload: error });
  }
}

function* getSexOptions() {
  try {
    const result = yield call(services.statics.getSexOptions);
    yield put({ type: GET_SEX_OPTIONS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_SEX_OPTIONS_ERROR, payload: error });
  }
}

function* getPanelistStates() {
  try {
    const result = yield call(services.statics.getPanelistStates);
    yield put({ type: GET_PANELIST_STATES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_PANELIST_STATES_ERROR, payload: error });
  }
}

function* getProvincias() {
  try {
    const result = yield call(services.statics.getProvincias);
    yield put({ type: GET_PROVINCIAS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_PROVINCIAS_ERROR, payload: error });
  }
}

function* getPartidos({ payload }) {
  try {
    const result = yield call(services.statics.getPartidos, payload);
    yield put({ type: GET_PARTIDOS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_PARTIDOS_ERROR, payload: error });
  }
}

function* getLocalidades({ payload }) {
  try {
    const result = yield call(services.statics.getLocalidades, payload);
    yield put({ type: GET_LOCALIDADES_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_LOCALIDADES_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_STUDY_STATES, getStudyStates);
  yield takeLatest<any>(GET_STUDY_TYPES, getStudyTypes);
  yield takeLatest<any>(GET_STUDY_ZONES, getStudyZones);
  yield takeLatest<any>(GET_USER_TYPES, getUserTypes);
  yield takeLatest<any>(GET_USER_ROLES, getUserRoles);
  yield takeLatest<any>(GET_SEX_OPTIONS, getSexOptions);
  yield takeLatest<any>(GET_PANELIST_STATES, getPanelistStates);
  yield takeLatest<any>(GET_PROVINCIAS, getProvincias);
  yield takeLatest<any>(GET_PARTIDOS, getPartidos);
  yield takeLatest<any>(GET_LOCALIDADES, getLocalidades);
}
