import { useEffect, useState } from 'react';
import StepCommonInformation from './components/StepCommonInformation';
import Stepper from '../../../../components/Stepper';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import './styles.scss';

const Study = () => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stepper
                onClick={setActiveStep}
                activeStep={activeStep}
                steps={[
                  { label: 'Datos del estudio', active: true },
                  { label: 'Filtros', disabled: true },
                  { label: 'Listado de panelistas', disabled: true },
                ]}
              />
            </CardHeader>
            <CardBody>
              <StepCommonInformation active={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Study;
