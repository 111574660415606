import ReactSelect from '../../../../../../../../components/Form/ReactSelect';
import { Row, Col } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import slugify from 'slugify';
import { getLocalidades, getPartidos } from '../../../../../../../../store/actions/statics';
import { useEffect } from 'react';

interface ComponentProps {
  locations;
  value?: string;
  type?: string;
  disabled?: boolean;
  errors?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
}

const Filter = ({ errors, touched, locations, ...rest }: ComponentProps) => {
  const dispatch = useDispatch();

  const getLocalidadesByPartido = (partido) => {
    dispatch(getLocalidades(partido.value));
  };

  // useEffect(() => {
  //   dispatch(getPartidos(1));
  // }, []);

  return (
    <div className="form-group">
      <Row>
        <Col>
          <label className="col-form-label pt-0">Partido</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactSelect
            name="city"
            items={
              locations.partidos &&
              locations.partidos.map((partido) => {
                return {
                  label: partido.descripcion,
                  value: partido.id,
                };
              })
            }
            onOptionSelected={(partido) => {
              getLocalidadesByPartido(partido);
            }}
            error={!!errors && errors['city']}
            touched={touched}
            //isMulti
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="col-form-label pt-0">Localidad</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactSelect
            name="locality"
            items={
              locations.localidades &&
              locations.localidades.map((localidad) => {
                return {
                  label: localidad.descripcion,
                  value: localidad.id,
                };
              })
            }
            error={!!errors && errors['locality']}
            style={{ margin: '0px' }}
            touched={touched}
            //isMulti
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {!!errors && !!errors['location'] && !!touched && <div className="input-feedback">{errors['location']}</div>}
        </Col>
      </Row>
    </div>
  );
};

const states = ({ staticsStore }) => {
  const { data: locations, states: locationsStates } = staticsStore.locations;
  return {
    locations,
    locationsStates,
  };
};

export default connect(states)(Filter);
