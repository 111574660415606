import { combineReducers } from 'redux';
import userTypes from './user-types';
import studyTypes from './study-types';
import studyStates from './study-states';
import studyZones from './study-zones';
import userRoles from './user-roles';
import sexOptions from './sex-options';
import panelistStates from './panelist-states';
import locations from './locations';

const reducer = combineReducers({
  userTypes,
  userRoles,
  studyZones,
  studyTypes,
  studyStates,
  sexOptions,
  panelistStates,
  locations,
});

export default reducer;
