import { FilterFormField } from '../../../types/form-field';
export const fichaTecnicaAreaCorporalFilters: FilterFormField[] = [
  {
    type: 'title',
    label: 'FICHA TÉCNICA - ÁREA CORPORAL',
    name: '',
    validationType: 'string',
  },
  {
    label: 'Axilas pigmentadas',
    name: 'extraInformation_ficha-tecnica-area-corporal_axilas-pigmentadas',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Axilas pigmentadas - apto foto',
    name: 'extraInformation_ficha-tecnica-area-corporal_axila-pigmentadas-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Cicatrices',
    name: 'extraInformation_ficha-tecnica-area-corporal_cicatrices',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Localización (dev: campo de escritura alfanumérico)',
        value: 'localizacion',
      },
      {
        label: 'Origen (dev: campo de escritura alfanumérico)',
        value: 'origen',
      },
      {
        label: 'Tiempo de evolución (dev: campo de escritura alfanumérico)',
        value: 'tiempo-de-evolucion',
      },
    ],
  },
  {
    label: 'Cicatrices - apto foto',
    name: 'extraInformation_ficha-tecnica-area-corporal_cicatrices-apto-fotos',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Estrías - causa',
    name: 'extraInformation_ficha-tecnica-area-corporal_estrias-causas',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Hormonales',
        value: 'hormonales',
      },
      {
        label: 'Crecimiento',
        value: 'crecimiento',
      },
      {
        label: 'Embarazo',
        value: 'embarazo',
      },
      {
        label: 'Descenso brusco de peso',
        value: 'descenso-brusco-de-peso',
      },
      {
        label: 'Otro',
        value: 'otro',
      },
    ],
  },
  {
    label: 'Estrías -apto foto',
    name: 'extraInformation_ficha-tecnica-area-corporal_estrias-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Celulitis - apto foto',
    name: 'extraInformation_ficha-tecnica-area-corporal_celulitis-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
];
