import { createYupSchema } from '../yup-schema-validation';
import Input from '../../components/Form/Input';
import Checkbox from '../../components/Form/Checkbox';
import Radio from '../../components/Form/Radio';
import Select from '../../components/Form/Select';
import ReactSelect from '../../components/Form/ReactSelect';
import slugify from 'slugify';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from '../../components/Form';
import { useState } from 'react';
import { getLocalidades } from '../../store/actions/statics';

interface ComponentProps {
  form;
  onSubmit?;
  data?;
  initialValues?;
  locations;
  locationsStates;
}

interface Option {
  label: string;
  value: string | number;
}

const Component = ({
  form,
  onSubmit,
  data: extraData = {},
  initialValues = {},
  locations,
  locationsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  // const [fieldItems, setFieldsItems] = useState<Option[]>([]);

  const handleSubmit = ({ values, actions }) => {
    console.log('submit');
    dispatch(form.submit({ ...values, ...extraData }));

    if (!!onSubmit) {
      onSubmit(values);
    }
  };

  // const removeOtherValues = (optionsSelected: Option | Option[], options: Option[], isMulti: boolean = false) => {
  //   if (isMulti) {
  //     const isTodasSelected =
  //       (optionsSelected as Option[]).filter((optionSelected) => {
  //         return optionSelected.value === 'todas';
  //       }).length > 0;

  //     if (isTodasSelected) {
  //       setFieldsItems(fieldItems.filter((fieldItem) => fieldItem.value === 'todas'));
  //     } else {
  //       setFieldsItems(options);
  //     }
  //   }
  // };

  let formInitValues = {};

  form.fields.forEach((item) => {
    formInitValues[item.name] = item.value || '';
  });

  const yupSchema = form.fields.reduce(createYupSchema, {});
  const validateSchema = Yup.object().shape(yupSchema);

  return (
    <Form
      id={form.id}
      onSubmit={({ values, actions }) => {
        console.log('submit 1', values, actions);
        handleSubmit({ values, actions });
      }}
      values={{ ...formInitValues, ...(!!initialValues[form.id] ? initialValues[form.id] : {}) }}
      rules={validateSchema}
      key={slugify(`${form.id}`, { lower: true })}
    >
      {form.fields.map((field, index) => {
        const { type, name, label, isMulti = false, options } = field;

        switch (type) {
          case 'title':
            return <h5 key={slugify(`title-${label}-${index}`)}>{label}</h5>;
          case 'hidden':
            return (
              <Input name={name} options={{ label }} type="hidden" key={slugify(`hidden-${index}`, { lower: true })} />
            );
          case 'text':
            return (
              <Input name={name} options={{ label }} type="text" key={slugify(`${label}-${index}`, { lower: true })} />
            );
          case 'date':
            return (
              <Input name={name} options={{ label }} type="date" key={slugify(`${label}-${index}`, { lower: true })} />
            );
          case 'number':
            return (
              <Input
                name={name}
                options={{ label }}
                type="number"
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'checklist':
            return (
              <Checkbox
                name={name}
                options={{ label }}
                items={options}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'radio':
            return (
              <Radio
                name={name}
                options={{ label }}
                items={options}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'select':
            return (
              <Select
                name={name}
                options={{ label }}
                items={options}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'react-select':
            return (
              <ReactSelect
                name={name}
                options={{ label }}
                items={options}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'react-select-filter':
            return (
              <ReactSelect
                isMulti={isMulti}
                onOptionSelected={(value, actions) => {
                  const valuesIds = isMulti
                    ? (value as Option[]).map((item: Option) => item.value)
                    : (value as Option).value;

                  if ((valuesIds as string[]).includes('todas') && actions.action !== 'remove-value') {
                    actions.setForcedValue(
                      isMulti
                        ? options.filter((option) => option.value === 'todas')
                        : options.filter((option) => option.value === 'todas')[0],
                    );
                  } else {
                    actions.setForcedValue(null);
                  }
                }}
                isOptionDisabled={(option, optionSelected) => {
                  return !!optionSelected && optionSelected[0]?.value === 'todas' && option.value !== 'todas';
                }}
                name={name}
                options={{ label }}
                items={[...options]}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          case 'react-select-location':
            const asyncoptions = {
              city: {
                values:
                  locations.partidos &&
                  locations.partidos.map((partido) => {
                    return {
                      label: partido.descripcion,
                      value: partido.id,
                    };
                  }),
                action: (partido) => {
                  dispatch(getLocalidades(partido.value));
                },
              },
              locality: {
                values:
                  locations.localidades &&
                  locations.localidades.map((localidad) => {
                    return {
                      label: localidad.descripcion,
                      value: localidad.id,
                    };
                  }),
              },
            };

            return (
              <ReactSelect
                {...(!!asyncoptions[name].action && {
                  onOptionSelected: (value) => {
                    asyncoptions[name].action(value);
                  },
                })}
                name={name}
                options={{ label }}
                items={asyncoptions[name].values}
                key={slugify(`${label}-${index}`, { lower: true })}
              />
            );
          default:
            return null;
        }
      })}
    </Form>
  );
};

const states = ({ staticsStore }) => {
  const { data: locations, states: locationsStates } = staticsStore.locations;
  return {
    locations,
    locationsStates,
  };
};

export default connect(states)(Component);
