import { ListStudies, LIST_STUDIES } from '../types/study/list';
import { NewStudy, ResetNewStudy, NEW_STUDY, RESET_NEW_STUDY } from '../types/study/new';
import { DuplicateStudy, ResetDuplicateStudy, DUPLICATE_STUDY, RESET_DUPLICATE_STUDY } from '../types/study/duplicate';
import { GetStudy, GET_STUDY } from '../types/study/get';
import { UpdateStudy, ResetUpdateStudy, UPDATE_STUDY, RESET_UPDATE_STUDY } from '../types/study/update';
import {
  ListStudyPanelists,
  LIST_STUDY_PANELISTS,
  ListFilteredPanelists,
  LIST_FILTERED_PANELISTS,
  AddExtraFilters,
  ADD_EXTRA_FILTERS,
} from '../types/study/panelist';
import { GetStudiesStatistics, GET_STUDIES_STATISTICS } from '../types/study/statistics';

export const listStudies = (filters): ListStudies => {
  return { type: LIST_STUDIES, payload: filters };
};

export const listStudyPanelists = (Filters): ListStudyPanelists => {
  return { type: LIST_STUDY_PANELISTS, payload: Filters };
};
export const listFilteredPanelists = (Filters): ListFilteredPanelists => {
  return { type: LIST_FILTERED_PANELISTS, payload: Filters };
};

export const newStudy = (payload: any): NewStudy => {
  return { type: NEW_STUDY, payload };
};

export const duplicateStudy = (payload: any): DuplicateStudy => {
  return { type: DUPLICATE_STUDY, payload };
};

export const resetDuplicateStudy = (): ResetDuplicateStudy => {
  return { type: RESET_DUPLICATE_STUDY, payload: null };
};

export const resetStudyNew = (): ResetNewStudy => {
  return { type: RESET_NEW_STUDY, payload: null };
};

export const getStudy = (idStudy: number): GetStudy => {
  return { type: GET_STUDY, payload: idStudy };
};

export const updateStudy = (payload: any): UpdateStudy => {
  return { type: UPDATE_STUDY, payload };
};

export const updateStudyFilters = (payload: any): UpdateStudy => {
  const { idStudy, ...rest } = payload;
  return { type: UPDATE_STUDY, payload: { idStudy, filters: { ...rest } } };
};

export const applyStudyExtraFilters = (payload: any): AddExtraFilters => {
  return { type: ADD_EXTRA_FILTERS, payload };
};

export const resetUpdateStudy = (): ResetUpdateStudy => {
  return { type: RESET_UPDATE_STUDY, payload: null };
};

export const getStudiesStatistics = (): GetStudiesStatistics => {
  return { type: GET_STUDIES_STATISTICS, payload: null };
};
