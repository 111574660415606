import auth from './auth';
import user from './user';
import panelist from './panelist';
import panelistTechnicalInformation from './panelist-technical-information';
import panelistCommonInformation from './panelist-common-information';
import study from './study';
import dashboard from './dashboard';
import statics from './statics';
import panelistStudy from './panelist-study';

const services = {
  auth,
  user,
  panelist,
  panelistTechnicalInformation,
  panelistCommonInformation,
  study,
  dashboard,
  statics,
  panelistStudy,
};

export default services;
