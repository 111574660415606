import { Fragment, useState } from 'react';
import './styles.scss';
import logo from '../../assets/images/logo.svg';
import { ArrowRight, X } from 'react-feather';
import { MENUITEMS } from '../../constants/menu';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import slugify from 'slugify';

const Sidebar = ({ userInformation }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toogleNav = () => {
    setShowSidebar(!showSidebar);
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) {
          a.active = false;
        }

        if (!a.children) {
          return false;
        }

        if (typeof a.children === 'object') {
          a.children.forEach((b) => {
            if (a.children?.includes(item)) {
              b.active = false;
            }

            if (!b.children) {
              return false;
            }

            b.children.forEach((c) => {
              if (b.children && b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        }
      });
    }
    item.active = !item.active;
  };

  return (
    <Fragment>
      <div className={`page-sidebar ${showSidebar ? 'open' : ''}`}>
        <div className="sidebar-header">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
              <img src={logo} alt="Logo" height="60px" />
            </Link>
          </div>
        </div>

        <div className="sidebar">
          <ul className="sidebar-menu" id="sidebar-menu">
            <li>
              <button onClick={toogleNav} className="sidebar-menu-toggle">
                {showSidebar ? <X /> : <ArrowRight />}
              </button>
            </li>

            {MENUITEMS.map((menuItem, i) => {
              if (menuItem.onlyAdmins && userInformation.userRole !== 'admin') {
                return '';
              }

              return (
                <li className={menuItem.active ? 'active' : ''} key={slugify(menuItem.title)}>
                  {menuItem.sidebartitle ? <div className="sidebar-title">{menuItem.title}</div> : ''}

                  {menuItem.type === 'sub' && (
                    <button type="button" className="sidebar-submenu-toggle" onClick={() => toggletNavActive(menuItem)}>
                      <menuItem.icon size={20} />
                      <span>{menuItem.title}</span>
                    </button>
                  )}

                  {menuItem.type === 'link' && (
                    <Link
                      to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                      className={`sidebar-link ${menuItem.active ? 'active' : ''}`}
                      onClick={() => toggletNavActive(menuItem)}
                    >
                      <menuItem.icon size={20} />
                      <span>{menuItem.title}</span>
                    </Link>
                  )}

                  {!!menuItem.children && (
                    <ul
                      className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                      style={{ ...(menuItem.active && { opacity: 1, transition: 'opacity 500ms ease-in' }) }}
                    >
                      {menuItem.children.map((childrenItem, index) => (
                        <li key={index} className={childrenItem.children ? (childrenItem.active ? 'active' : '') : ''}>
                          {childrenItem.type === 'sub' && (
                            <button type="button" onClick={() => toggletNavActive(childrenItem)}>
                              <i className="fa fa-circle"></i>
                            </button>
                          )}

                          {childrenItem.type === 'link' && (
                            <Link
                              to={`${childrenItem.path}`}
                              className={childrenItem.active ? 'active' : ''}
                              onClick={() => toggletNavActive(childrenItem)}
                            >
                              <i className="fa fa-circle"></i>
                              {childrenItem.title}{' '}
                            </Link>
                          )}

                          {childrenItem.children && (
                            <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                              {childrenItem.children.map((childrenSubItem, key) => (
                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                  {childrenSubItem.type === 'link' && (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                      className={childrenSubItem.active ? 'active' : ''}
                                      onClick={() => toggletNavActive(childrenSubItem)}
                                    >
                                      <i className="fa fa-circle"></i>
                                      {childrenSubItem.title}
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

const state = ({ locationStore, userStore }) => {
  const { data: locationData } = locationStore;
  const { data: userInformation } = userStore.information;
  return {
    locationData,
    userInformation,
  };
};

export default connect(state)(Sidebar);
