import * as yup from 'yup';

export const createYupSchema = (schema, config) => {
  const { name, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }

  let validator = yup[validationType]().nullable();

  validations.forEach((validation) => {
    const { params, type } = validation;

    if (!validator[type]) {
      return;
    }

    if (type === 'when') {
      validator = validator[type](params[0], {
        is: params[1].is,
        then: yup[validationType]()
          .nullable()
          [params[1].then.type](...params[1].then.params),
      });
    } else {
      validator = validator[type](...params);
    }
  });

  schema[name] = validator;
  return schema;
};
