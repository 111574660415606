import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Search } from 'react-feather';
import { ErrorMessage, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { applyStudyExtraFilters } from '../../../../../../../../store/actions/study';

interface ComponentProps {
  values?: any;
  type?: string;
  disabled?: boolean;
  errors?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
}

const MyAutoSavingComponent = () => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};

const yupRules = Yup.object().shape({});
const initialValues = {};

const Component = ({ errors, touched, onFocus, values, onBlur, onChange, ...rest }: ComponentProps) => {
  const dispatch = useDispatch();

  const onFormSubmit = (data) => {
    dispatch(applyStudyExtraFilters({ fullName: data.search }));
  };

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div id="search-bar">
      <Formik initialValues={initialValues} onSubmit={(event) => onFormSubmit(event)} validationSchema={yupRules}>
        {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleSubmit }) => {
          const hasError = !!errors && !!errors['search'] && !!touched;

          return (
            <form className="theme-form" onSubmit={handleSubmit} id="study-search-bar">
              <div className="field">
                <i className="icon">
                  <Search />
                </i>

                <input
                  placeholder="Refina tu búsqueda. Ingresa nombre o apellido del panelista."
                  type="text"
                  name="search"
                  className={`form-control ${hasError ? 'error-field' : ''}`}
                  onChange={(event) => handleOnChange(event, handleChange)}
                  onBlur={(event) => handleOnBlur(event, handleBlur)}
                  {...(!!values && { value: values['search'] })}
                  {...rest}
                  //disabled={isSubmitting}
                />
              </div>

              <div className="input-feedback">
                <ErrorMessage name="lastName" />
              </div>

              <MyAutoSavingComponent />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Component;
