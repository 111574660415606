import { Filter } from '../../../types/panelist-filter';
import { PanelistDto } from '../../../types/panelist';

export const LIST_STUDY_PANELISTS = 'LIST_STUDY_PANELISTS';
export const LIST_STUDY_PANELISTS_SUCCESS = 'LIST_STUDY_PANELISTS_SUCCESS';
export const LIST_STUDY_PANELISTS_ERROR = 'LIST_STUDY_PANELISTS_ERROR';

export const LIST_FILTERED_PANELISTS = 'LIST_FILTERED_PANELISTS';
export const LIST_FILTERED_PANELISTS_SUCCESS = 'LIST_FILTERED_PANELISTS_SUCCESS';
export const LIST_FILTERED_PANELISTS_ERROR = 'LIST_FILTERED_PANELISTS_ERROR';

export const ADD_EXTRA_FILTERS = 'ADD_EXTRA_FILTERS';
export const ADD_EXTRA_FILTERS_SUCCESS = 'ADD_EXTRA_FILTERS_SUCCESS';
export const ADD_EXTRA_FILTERS_ERROR = 'ADD_EXTRA_FILTERS_ERROR';

export interface ListStudyPanelists {
  type: typeof LIST_STUDY_PANELISTS;
  payload: Filter[] | null;
}
export interface ListStudyPanelistsSuccess {
  type: typeof LIST_STUDY_PANELISTS_SUCCESS;
  payload: PanelistDto[] | [];
}
export interface ListStudyPanelistsError {
  type: typeof LIST_STUDY_PANELISTS_ERROR;
  payload: boolean | string;
}

export interface ListFilteredPanelists {
  type: typeof LIST_FILTERED_PANELISTS;
  payload: Filter[] | null;
}
export interface ListFilteredPanelistsSuccess {
  type: typeof LIST_FILTERED_PANELISTS_SUCCESS;
  payload: PanelistDto[] | [];
}
export interface ListFilteredPanelistsError {
  type: typeof LIST_FILTERED_PANELISTS_ERROR;
  payload: boolean | string;
}

export interface AddExtraFilters {
  type: typeof ADD_EXTRA_FILTERS;
  payload: { idStudy: string; filters: Filter[] } | null;
}
export interface AddExtraFiltersSuccess {
  type: typeof ADD_EXTRA_FILTERS_SUCCESS;
  payload: PanelistDto[] | [];
}
export interface AddExtraFiltersError {
  type: typeof ADD_EXTRA_FILTERS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: {
    filtered: PanelistDto[] | [];
    assigned: PanelistDto[] | [];
  };
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: {
    filtered: [],
    assigned: [],
  },
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | ListStudyPanelists
  | ListStudyPanelistsSuccess
  | ListStudyPanelistsError
  | ListFilteredPanelists
  | ListFilteredPanelistsSuccess
  | ListFilteredPanelistsError
  | AddExtraFilters
  | AddExtraFiltersSuccess
  | AddExtraFiltersError;
