import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Avatar from '../../../../components/Avatar';
import ChangePassword from './components/ChangePassword';

import './styles.scss';

const Component = ({ userInformation }) => {
  return (
    <Container fluid={true}>
      <div className="user-profile">
        <Row>
          <Col>
            <div className="card hovercard text-center">
              <div className="user-image">
                <Avatar
                  name={userInformation.name}
                  lastName={userInformation.lastName}
                  picture={userInformation.picture}
                />

                {/* <div className="icon-wrapper">
          <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here">
            <input className="pencil" type="file" onChange={(e) => readUrl(e)} />
          </i>
        </div> */}
              </div>

              <div className="info">
                <div className="row detail" data-intro="This is the your details">
                  <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ttl-info text-left">
                          <h6>
                            <i className="fa fa-envelope mr-2"></i>
                            E-mail
                          </h6>
                          <span>{userInformation.email}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="ttl-info text-left ttl-sm-mb-0">
                          <h6>
                            <i className="fa fa-phone"></i>
                            Teléfono
                          </h6>
                          <span>{userInformation.phone}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                    <div className="user-designation">
                      <div className="title">
                        {userInformation.name} {userInformation.lastName}
                      </div>
                      <div className="desc mt-2">{userInformation.role}</div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ttl-info text-left">
                          <h6>
                            <i className="fa fa-envelope mr-2"></i>
                            Posicion
                          </h6>
                          <span>{userInformation.userType}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="ttl-info text-left ttl-sm-mb-0">
                          <h6>
                            <i className="fa fa-phone"></i>
                            Tipo usuario
                          </h6>
                          <span>{userInformation.userRole}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <hr />
                <div className="social-media" data-intro="This is your Social details">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="#javascript">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#javascript">
                <i className="fa fa-google-plus"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#javascript">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#javascript">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#javascript">
                <i className="fa fa-rss"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="follow">
          <div className="row">
            <div className="col-6 border-right">
              <div className="follow-num counter">{'25869'}</div>
              <span>{Follower}</span>
            </div>

            <div className="col-6">
              <div className="follow-num counter">{'659887'}</div>
              <span>{Following}</span>
            </div>
          </div>
        </div> */}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <ChangePassword />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
  };
};

export default connect(state)(Component);
