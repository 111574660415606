import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { Activity } from 'react-feather';
import './styles.scss';

const Component = ({ studiesStatistics }) => {
  return (
    <div className="card" id="total-studies">
      <div className="card-body">
        <div>
          <div className="circle">
            <Activity size={30} color="#FFFFFF" />
          </div>
          <div className="totals">
            <span>
              {studiesStatistics && <CountUp className="counter" end={studiesStatistics?.totals?.actives} />} Estudios
              activos
            </span>
            <span>Total de estudios {studiesStatistics?.totals?.all}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const state = ({ studyStore }) => {
  const { data: studiesStatistics } = studyStore.statistics;
  return { studiesStatistics };
};

export default connect(state)(Component);
