export const NEW_PANELIST_TECHNICAL_INFORMATION = 'NEW_PANELIST_TECHNICAL_INFORMATION';
export const NEW_PANELIST_TECHNICAL_INFORMATION_SUCCESS = 'NEW_PANELIST_TECHNICAL_INFORMATION_SUCCESS';
export const NEW_PANELIST_TECHNICAL_INFORMATION_ERROR = 'NEW_PANELIST_TECHNICAL_INFORMATION_ERROR';

export interface NewPanelistTechnicalInformation {
  type: typeof NEW_PANELIST_TECHNICAL_INFORMATION;
  payload: any;
}
export interface NewPanelistTechnicalInformationSuccess {
  type: typeof NEW_PANELIST_TECHNICAL_INFORMATION_SUCCESS;
  payload: null;
}
export interface NewPanelistTechnicalInformationError {
  type: typeof NEW_PANELIST_TECHNICAL_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | NewPanelistTechnicalInformation
  | NewPanelistTechnicalInformationSuccess
  | NewPanelistTechnicalInformationError;
