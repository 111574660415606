import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { studyState } from '../../../../../helpers/resolve-study-state';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import Spinner from '../../../../../components/Spinner';
import ReactSelect from '../../../../../components/Form/ReactSelect';
import { updateStudy } from '../../../../../store/actions/study';

interface ComponentProps {
  studyStates;
  updateStudyStates;
  study;
}

const Component = ({ studyStates, updateStudyStates, study }: ComponentProps) => {
  const dispatch = useDispatch();
  const [studyCurrentState, setStudyCurrentState] = useState<{ value: string; label: string }>();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateStudy({ ...values, idStudy: study._id }));
  };

  useEffect(() => {
    if (study && studyStates) {
      setStudyCurrentState(studyState(study, studyStates));
    }
  }, [study, studyStates]);

  return (
    <Formik
      initialValues={{ ...study, state: studyCurrentState }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize
    >
      {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleReset, handleSubmit }) => {
        const hasError = !!errors && !!errors['state'] && !!touched;

        return (
          <form className="theme-form" onSubmit={handleSubmit} id="update-study-form">
            <div className="form-group">
              <Row>
                <Col>
                  <label className="col-form-label pt-0">Estado</label>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}
                >
                  <ReactSelect
                    name="state"
                    onOptionSelected={() => handleSubmit()}
                    defaultValue={studyCurrentState}
                    items={studyStates}
                    error={!!errors && errors['state']}
                    touched={touched}
                  />
                  {updateStudyStates.loading && <Spinner size="small" style={{ marginLeft: '10px' }} />}
                </Col>
              </Row>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ staticsStore, studyStore }) => {
  const { data: studyStates } = staticsStore.studyStates;
  const { data: study } = studyStore.study;
  const { states: updateStudyStates } = studyStore.updateStudy;

  return {
    studyStates,
    updateStudyStates,
    study,
  };
};

export default connect(states)(Component);
