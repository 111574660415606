export const GET_STUDY_ZONES = 'GET_STUDY_ZONES';
export const GET_STUDY_ZONES_SUCCESS = 'GET_STUDY_ZONES_SUCCESS';
export const GET_STUDY_ZONES_ERROR = 'GET_STUDY_ZONES_ERROR';

export interface GetStudyZones {
  type: typeof GET_STUDY_ZONES;
  payload: any;
}
export interface GetStudyZonesSuccess {
  type: typeof GET_STUDY_ZONES_SUCCESS;
  payload: null;
}
export interface GetStudyZonesError {
  type: typeof GET_STUDY_ZONES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetStudyZones | GetStudyZonesSuccess | GetStudyZonesError;
