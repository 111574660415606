export const GET_STUDY = 'GET_STUDY';
export const GET_STUDY_SUCCESS = 'GET_STUDY_SUCCESS';
export const GET_STUDY_ERROR = 'GET_STUDY_ERROR';
export const UPDATE_CURRENT_STUDY = 'UPDATE_CURRENT_STUDY';

export interface GetStudy {
  type: typeof GET_STUDY;
  payload: any;
}
export interface GetStudySuccess {
  type: typeof GET_STUDY_SUCCESS;
  payload: null;
}
export interface GetStudyError {
  type: typeof GET_STUDY_ERROR;
  payload: boolean | string;
}
export interface UpdateCurrentStudy {
  type: typeof UPDATE_CURRENT_STUDY;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetStudy | GetStudySuccess | GetStudyError | UpdateCurrentStudy;
