import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import New from './New';
import List from './List';
import Edit from './Edit';
import { useDispatch } from 'react-redux';
import { getLocalidades, getPanelistStates, getPartidos } from '../../../store/actions/statics';

const Component = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPanelistStates());
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}/new`} component={(props) => <New {...props} />} />
      <Route exact path={`${path}/list`} component={(props) => <List {...props} />} />
      <Route exact path={`${path}/:id`} component={(props) => <Edit {...props} />} />
      <Route path={`*`} render={() => <Redirect to={`${path}/list`} />} />
    </Switch>
  );
};

export default Component;
