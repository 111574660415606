import { FilterFormField } from '../../../types/form-field';
export const fichaTecnicaGeneralFilters: FilterFormField[] = [
  {
    type: 'title',
    label: 'FICHA TÉCNICA - GENERAL',
    name: '',
    validationType: 'string',
  },
  {
    label: '¿Apto parche?',
    name: 'extraInformation_ficha-tecnica-general_apto-parche',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Puntuación total:       Fototipo: ',
    name: 'extraInformation_ficha-tecnica-general_puntuacion-total-fototipo',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: '0-7 Fototipo I',
        value: '0-7-fototipo-i',
      },
      {
        label: '8-21 Fototipo II',
        value: '8-21-fototipo-ii',
      },
      {
        label: '22-42 Fototipo III',
        value: '22-42-fototipo-iii',
      },
      {
        label: '43-68 Fototipo IV',
        value: '43-68-fototipo-iv',
      },
      {
        label: '96-84 Fototipo V',
        value: '96-84-fototipo-v',
      },
      {
        label: '+85 Fototipo VI',
        value: '85-fototipo-vi',
      },
    ],
  },
  {
    label: 'Rosácea',
    name: 'extraInformation_ficha-tecnica-general_rosacea',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Rosácea - apto foto',
    name: 'extraInformation_ficha-tecnica-general_rosacea-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Eczemas',
    name: 'extraInformation_ficha-tecnica-general_eczemas',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Facial',
        value: 'facial',
      },
      {
        label: 'Corporal',
        value: 'corporal',
      },
      {
        label: 'Manos',
        value: 'manos',
      },
      {
        label: 'Cuero cabelludo',
        value: 'cuero-cabelludo',
      },
    ],
  },
  {
    label: 'Psoriasis',
    name: 'extraInformation_ficha-tecnica-general_psoriasis',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Vitíligo',
    name: 'extraInformation_ficha-tecnica-general_vitiligio',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Dermatitis ',
    name: 'extraInformation_ficha-tecnica-general_demartitis',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Foliculitis',
    name: 'extraInformation_ficha-tecnica-general_foliculitis',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Queratosis',
    name: 'extraInformation_ficha-tecnica-general_queratosis',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
];
