import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch, useLocation, Redirect } from 'react-router-dom';

import { getPanelistsStatistics } from '../../store/actions/panelist';
import { getStudiesStatistics } from '../../store/actions/study';
import { getSexOptions, getStudyStates, getStudyTypes, getStudyZones, getPartidos } from '../../store/actions/statics';

import { setLocation } from '../../store/actions/location';

import Layout from '../../components/Layout';
import Home from './Home';
import Panelist from './Panelist';
import Study from './Study';
import User from './User';
import Admin from './Admin';

const Component = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getStudyTypes());
    dispatch(getStudyZones());
    dispatch(getSexOptions());
    dispatch(getStudyStates());
    dispatch(getPartidos(1));
  }, []);

  useEffect(() => {
    dispatch(setLocation(location));
  }, [location]);

  return (
    <Switch>
      <Route
        path={`${path}/home`}
        component={() => (
          <Layout showBreadcrumb={false}>
            <Home />
          </Layout>
        )}
      />
      <Route
        path={`${path}/panelist`}
        component={() => (
          <Layout>
            <Panelist />
          </Layout>
        )}
      />
      <Route
        path={`${path}/study`}
        component={() => (
          <Layout>
            <Study />
          </Layout>
        )}
      />
      <Route
        path={`${path}/user`}
        component={() => (
          <Layout>
            <User />
          </Layout>
        )}
      />
      <Route
        path={`${path}/admin`}
        component={() => (
          <Layout>
            <Admin />
          </Layout>
        )}
      />

      <Route path={`*`} render={() => <Redirect to={`${path}/home`} />} />
    </Switch>
  );
};

export default Component;
