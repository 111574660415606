import { Fragment, useEffect, useState } from 'react';
import slugify from 'slugify';
import './styles.scss';
import { urls } from '../../constants/urls';
import Header from '../Header';
import Breadcrumb from '../Breadcrumb';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import Footer from '../Footer';
import Loader from '../Loader/loader';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

const AppLayout = ({ locationData, children, showBreadcrumb = true }) => {
  const [breadcrumb, setBreadcrumb] = useState<{
    pageTitle: string;
    label: string;
  }>();

  useEffect(() => {
    if (locationData) {
      const pathInfo = urls.filter((url) => {
        return locationData.pathname.match(url.regex);
      })[0];
      setBreadcrumb(pathInfo);
    }
  }, [locationData]);

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper" id={breadcrumb && slugify(`page-${breadcrumb.label}`, { lower: true })}>
        <div className="page-body-wrapper">
          <LeftSidebar />
          <Header />

          <div className="page-body">
            <div className="page-body-content">
              {showBreadcrumb && <Breadcrumb />}
              {children}
            </div>

            <Footer />
          </div>

          <RightSidebar />
        </div>
      </div>
      {/* <Customizer /> */}
      <ToastContainer />
    </Fragment>
  );
};

const state = ({ locationStore }) => {
  const { data: locationData } = locationStore;
  return {
    locationData,
  };
};

export default connect(state)(AppLayout);
