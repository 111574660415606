import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

import ScrollToTop from '../components/ScrollToTop';

import Dashboard from './Dashboard';
import SignIn from './SignIn';

const Component = () => {
  return (
    <Router>
      <ScrollToTop />

      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <Route path="/signin" component={SignIn} />
        <Route path={`*`} render={() => <Redirect to={`/signin`} />} />
      </Switch>
    </Router>
  );
};

export default Component;
