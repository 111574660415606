import EmptyState from './components/EmptyState';
import { connect } from 'react-redux';
import List from './components/List';
import studies from '../../../../../constants/dummy/studies';

const Component = ({ studiesStatistics }) => {
  return studiesStatistics?.lastStudies.length <= 0 ? <EmptyState /> : <List />;
};

const state = ({ studyStore }) => {
  const { data: studiesStatistics } = studyStore.statistics;
  return { studiesStatistics };
};

export default connect(state)(Component);
