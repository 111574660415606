import { GetStudyStates, GET_STUDY_STATES } from '../types/statics/study-states';
import { GetStudyTypes, GET_STUDY_TYPES } from '../types/statics/study-types';
import { GetUserTypes, GET_USER_TYPES } from '../types/statics/user-types';
import { GetUserRoles, GET_USER_ROLES } from '../types/statics/user-roles';
import { GetStudyZones, GET_STUDY_ZONES } from '../types/statics/study-zones';
import { GetSexOptions, GET_SEX_OPTIONS } from '../types/statics/sex-options';
import { GetPanelistStates, GET_PANELIST_STATES } from '../types/statics/panelist-states';
import {
  GetProvincias,
  GET_PROVINCIAS,
  GetPartidos,
  GET_PARTIDOS,
  GetLocalidades,
  GET_LOCALIDADES,
} from '../types/statics/locations';

export const getUserTypes = (): GetUserTypes => {
  return { type: GET_USER_TYPES, payload: null };
};

export const getUserRoles = (): GetUserRoles => {
  return { type: GET_USER_ROLES, payload: null };
};

export const getStudyTypes = (): GetStudyTypes => {
  return { type: GET_STUDY_TYPES, payload: null };
};

export const getStudyZones = (): GetStudyZones => {
  return { type: GET_STUDY_ZONES, payload: null };
};

export const getStudyStates = (): GetStudyStates => {
  return { type: GET_STUDY_STATES, payload: null };
};

export const getSexOptions = (): GetSexOptions => {
  return { type: GET_SEX_OPTIONS, payload: null };
};

export const getPanelistStates = (): GetPanelistStates => {
  return { type: GET_PANELIST_STATES, payload: null };
};

export const getProvincias = (): GetProvincias => {
  return { type: GET_PROVINCIAS, payload: null };
};

export const getPartidos = (provinciaId): GetPartidos => {
  return { type: GET_PARTIDOS, payload: provinciaId };
};

export const getLocalidades = (partidoId): GetLocalidades => {
  return { type: GET_LOCALIDADES, payload: partidoId };
};
