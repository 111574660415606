export const ASSIGNED_PANELIST = 'ASSIGNED_PANELIST';
export const ASSIGNED_PANELIST_SUCCESS = 'ASSIGNED_PANELIST_SUCCESS';
export const ASSIGNED_PANELIST_ERROR = 'ASSIGNED_PANELIST_ERROR';

export interface PanelistAssigned {
  type: typeof ASSIGNED_PANELIST;
  payload: any;
}
export interface PanelistAssignedSuccess {
  type: typeof ASSIGNED_PANELIST_SUCCESS;
  payload: null;
}
export interface PanelistAssignedError {
  type: typeof ASSIGNED_PANELIST_ERROR;
  payload: boolean | string;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = PanelistAssigned | PanelistAssignedSuccess | PanelistAssignedError;
