import { useState, useEffect } from 'react';
import { Button, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { forms } from '../../../../../../constants/panelist/forms';
import Submit from '../../../../../../components/Form/Submit';
import FormFactory from '../../../../../../helpers/FormFactory';
import { PanelistDto } from '../../../../../../types/panelist';
import './styles.scss';
import slugify from 'slugify';

const Component = ({ panelistInformation, updatePanelistStates }) => {
  const [panelist, setPanelist] = useState<PanelistDto>();
  const [visible, setVisible] = useState('');
  const [panelistCommonInformation, setPanelistCommonInformation] = useState();

  useEffect(() => {
    if (!!panelistInformation) {
      setPanelistCommonInformation({ ...panelistInformation.extraInformation });
      setPanelist({ ...panelistInformation });
    }
  }, [panelistInformation]);

  if (!panelist) {
    return null;
  }

  return (
    <>
      {forms.map((form) => {
        return (
          <div
            className="card panelist-information"
            key={`common-information_${slugify(form.id, { lower: true })}_form`}
          >
            <div className="card-header">
              <h5 className="card-title mb-0">
                {form.label}
                <Button
                  onClick={() => {
                    setVisible(visible === form.id ? '' : form.id);
                  }}
                >
                  {visible === form.id ? 'Ocultar' : 'Mostrar'}
                </Button>
              </h5>
              <div className="card-options">
                <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                  <i className="fe fe-chevron-up"></i>
                </a>
                <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
            <Collapse isOpen={visible === form.id}>
              <div className="card-body">
                <FormFactory
                  form={form}
                  data={{ formId: form.id, idPanelist: panelist._id }}
                  initialValues={panelistCommonInformation}
                />
              </div>
              <div className="card-footer text-right">
                <Submit form={form.id} isLoading={updatePanelistStates.loading}>
                  Actualizar
                </Submit>
              </div>
            </Collapse>
          </div>
        );
      })}
    </>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistInformation } = panelistStore.panelist;
  const { states: updatePanelistStates } = panelistStore.updatePanelist;
  return { panelistInformation, updatePanelistStates };
};
export default connect(state)(Component);
