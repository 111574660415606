import moment from 'moment';
import { PanelistDto } from '../types/panelist';

export const getPanelistAge = (panelist: PanelistDto): { number: number; unit: string } | null => {
  if (!panelist) return null;
  const years = moment().diff(panelist?.birthdate, 'years');
  const panelistAge = years === 0 ? moment().diff(panelist?.birthdate, 'months') : years;
  const unit = years === 0 ? 'meses' : 'años';
  console.log({ number: panelistAge, unit });
  return { number: panelistAge, unit };
};
