export const GET_STUDY_STATES = 'GET_STUDY_STATES';
export const GET_STUDY_STATES_SUCCESS = 'GET_STUDY_STATES_SUCCESS';
export const GET_STUDY_STATES_ERROR = 'GET_STUDY_STATES_ERROR';

export interface GetStudyStates {
  type: typeof GET_STUDY_STATES;
  payload: any;
}
export interface GetStudyStatesSuccess {
  type: typeof GET_STUDY_STATES_SUCCESS;
  payload: null;
}
export interface GetStudyStatesError {
  type: typeof GET_STUDY_STATES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetStudyStates | GetStudyStatesSuccess | GetStudyStatesError;
