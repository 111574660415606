import { combineReducers } from 'redux';
import study from './get';
import studyList from './list';
import newStudy from './new';
import duplicateStudy from './duplicate';
import updateStudy from './update';
import panelistList from './filtered-panelist';
import statistics from './statistics';

const reducer = combineReducers({
  study,
  studyList,
  newStudy,
  duplicateStudy,
  updateStudy,
  panelistList,
  statistics,
});

export default reducer;
