import { connect, useDispatch } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Badge } from 'reactstrap';

import FieldStudyNumber from '../../../components/FieldStudyNumber';
import FieldCostStimationNumber from '../../../components/FieldCostStimationNumber';
import FilterZones from '../../../components/FieldZones';
import FieldAge from '../../../components/FieldAge';
import FieldControls from '../../../components/FieldControls';
import FieldLaboratoryCode from '../../../components/FieldLaboratoryCode';
import FieldAmountParticipants from '../../../components/FieldAmountParticipants';
import FieldPayment from '../../../components/FieldPayment';
import FieldSex from '../../../components/FieldSex';
import FilterStudyType from '../../../components/FieldType';
import FildStartDate from '../../../components/FieldStartDate';
import FildEndDate from '../../../components/FieldEndDate';

import FilterByState from './components/FilterByState';
import './styles.scss';
import { useEffect } from 'react';
import { listStudies } from '../../../../../../store/actions/study';

const yupRules = Yup.object().shape({});
const initialValues = {};

const MyAutoSavingComponent = () => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};

interface ComponentProps {
  studyList;
}

const Component = ({ studyList }: ComponentProps) => {
  const dispatch = useDispatch();

  const onFormSubmit = (data) => {
    dispatch(listStudies(data));
  };

  return (
    <div id="filters">
      <div className="card">
        <div className="card-header">
          <h6 className="m-0">Filtros</h6>
          <div className="total-filtered">
            <Badge color="light">
              Mostrando{' '}
              {studyList?.limit * studyList?.page > studyList?.totalDocs
                ? studyList?.totalDocs
                : studyList?.limit * studyList?.page}{' '}
              de {studyList?.totalDocs}
            </Badge>
          </div>
        </div>

        <div className="card-body">
          <Formik initialValues={initialValues} onSubmit={(event) => onFormSubmit(event)} validationSchema={yupRules}>
            {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleSubmit }) => {
              return (
                <form className="theme-form" onSubmit={handleSubmit} id="study-filters-form">
                  <FieldStudyNumber errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldCostStimationNumber
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FilterZones style={{ margin: '0px' }} />
                  <FilterStudyType
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    values={values}
                    style={{ margin: '0px' }}
                  />
                  {/* <FieldAge errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldControls errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FildStartDate errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FildEndDate errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldLaboratoryCode errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldAmountParticipants
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FieldPayment errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FieldSex errors={errors} touched={touched} onBlur={handleBlur} />
                  <FieldType errors={errors} touched={touched} onBlur={handleBlur} isMulti />
                  <FilterByState errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} /> */}
                  <MyAutoSavingComponent />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const states = ({ studyStore }) => {
  const { data: studyList, states: studyListStates } = studyStore.studyList;
  const { states: studyStates } = studyStore.study;
  return {
    studyList,
    studyListStates,
    studyStates,
  };
};

export default connect(states)(Component);
