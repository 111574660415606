import action from './../helpers/rest-client';

export class ErrorStatics extends Error {}

class Statics {
  public async getStudyStates() {
    return action
      .Get({
        url: `/statics/study-states`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getStudyZones() {
    return action
      .Get({
        url: `/statics/study-zones`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getStudyTypes() {
    return action
      .Get({
        url: '/statics/study-types',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getUserTypes() {
    return action
      .Get({
        url: '/statics/user-types',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getUserRoles() {
    return action
      .Get({
        url: '/statics/user-roles',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getSexOptions() {
    return action
      .Get({
        url: '/statics/sex-options',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getPanelistStates() {
    return action
      .Get({
        url: '/statics/panelist-states',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getProvincias() {
    return action
      .Get({
        url: `/statics/provincias`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getPartidos(provinciaId) {
    return action
      .Get({
        url: `/statics/partidos?provinciaId=${provinciaId}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }

  public async getLocalidades(partidoId) {
    return action
      .Get({
        url: `/statics/localidades?partidoId=${partidoId}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStatics(error.message);
      });
  }
}

const statics = new Statics();
export default statics;
