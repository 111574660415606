export const GET_USER_TYPES = 'GET_USER_TYPES';
export const GET_USER_TYPES_SUCCESS = 'GET_USER_TYPES_SUCCESS';
export const GET_USER_TYPES_ERROR = 'GET_USER_TYPES_ERROR';

export interface GetUserTypes {
  type: typeof GET_USER_TYPES;
  payload: any;
}
export interface GetUserTypesSuccess {
  type: typeof GET_USER_TYPES_SUCCESS;
  payload: null;
}
export interface GetUserTypesError {
  type: typeof GET_USER_TYPES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetUserTypes | GetUserTypesSuccess | GetUserTypesError;
