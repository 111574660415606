import { useState, Fragment } from 'react';
import './styles.scss';
//import logo from '../../assets/images/logo.svg';
import UserMenu from './userMenu';
import Notification from './notification';
//import { Link } from 'react-router-dom';
import { toggleFullScren } from '../../hooks/useFullScreen';
import { AlignLeft, Maximize, Bell, MessageCircle, MoreHorizontal } from 'react-feather';

const Header = () => {
  const [headerbar, setHeaderbar] = useState(true);

  // const openCloseSidebar = () => {
  //   if (showLeftSidebar) {
  //     setSidebar(!showLeftSidebar);
  //     document.querySelector(".page-main-header").classList.remove("open");
  //     document.querySelector(".page-sidebar").classList.remove("open");
  //   } else {
  //     setSidebar(!showLeftSidebar);
  //     document.querySelector(".page-main-header").classList.add("open");
  //     document.querySelector(".page-sidebar").classList.add("open");
  //   }
  // };

  // function showRightSidebar() {
  //   if (showRightSidebar) {
  //     setRightSidebar(!rightSidebar);
  //     document.querySelector(".right-sidebar").classList.add("show");
  //   } else {
  //     setRightSidebar(!rightSidebar);
  //     document.querySelector(".right-sidebar").classList.remove("show");
  //   }
  // }

  //full screen function

  return (
    <Fragment>
      <div className={`page-main-header`}>
        <div className="main-header-right">
          <div className="nav-right">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                <button type="button" onClick={() => toggleFullScren()} className="text-dark btn btn-link p-0">
                  <Maximize />
                </button>
              </li>

              {/* <li className="onhover-dropdown">
                <Notification />
                <Bell />
                <span className="dot"></span>
                <Notification />
              </li> */}

              {/* <li>
                <a href="#javascript" onClick={() => console.log('showRightSidebar')}>
                  <MessageCircle />
                  <span className="dot"></span>
                </a>
              </li> */}

              <UserMenu />
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
