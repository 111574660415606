import { useEffect, useState } from 'react';
import SweetAlert from 'sweetalert2';
import { connect, useDispatch } from 'react-redux';
import ContactData from './components/ContactData';
import CommonData from './components/CommonData';
import StudiesList from './components/StudiesList';
import { Container, Row, Col } from 'reactstrap';
import { getPanelist, resetUpdatePanelist } from '../../../../store/actions/panelist';
import { forms } from '../../../../constants/panelist/contact-information';
import './styles.scss';

const Panelist = ({ match, updatePanelistStates }) => {
  const { error: updatePanelistError, success: updatePanelistSuccess } = updatePanelistStates;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!match && !!match.params) {
      dispatch(getPanelist(match.params.id));
    }
  }, [match]);

  useEffect(() => {
    if (updatePanelistSuccess) {
      SweetAlert.fire({
        title: 'Panelista actualizado satisfactoriamente',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetUpdatePanelist());
        },
      });
    }
  }, [updatePanelistSuccess]);

  useEffect(() => {
    if (updatePanelistError) {
      SweetAlert.fire({
        title: 'Error al actualizar panelista',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetUpdatePanelist());
        },
      });
    }
  }, [updatePanelistError]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <ContactData form={forms[0]} />
        </Col>
        <Col>
          <CommonData />
        </Col>
      </Row>
      <Row>
        <Col>
          <StudiesList />
        </Col>
      </Row>
    </Container>
  );
};

const states = ({ panelistStore }) => {
  const { states: updatePanelistStates } = panelistStore.updatePanelist;
  const { data: panelist } = panelistStore.panelist;

  return {
    updatePanelistStates,
    panelist,
  };
};
export default connect(states)(Panelist);
