import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { User, MapPin, Calendar, Phone, Smartphone } from 'react-feather';
import moment from 'moment';
import Avatar from '../../../../../../components/Avatar';
import externalLink from '../../../../../../assets/images/icons/external-link.svg';
import './styles.scss';
import { getNiceDate } from '../../../../../../helpers/dates-hanlder';
import { getStudiesInProgress } from '../../../../../../helpers/getStudiesInProgress';
import { StudyDto } from '../../../../../../types/study';
import { panelistState } from '../../../../../../helpers/resolve-panelist-state';
import { PanelistDto } from '../../../../../../types/panelist';
import { getPanelistAge } from '../../../../../../helpers/get-panelist-age';

interface ComponentProps {
  panelist: PanelistDto;
  panelistStates;
}

const statesClasses = {
  active: { label: 'Activo', class: 'badge-success' },
  inactive: { label: 'Pausado', class: 'badge-danger' },
  'study-in-progress': { label: 'Finalizado', class: 'badge-warning' },
};

const Component = ({ panelist, panelistStates }: ComponentProps) => {
  const [studyInProgress, setStudyInProgress] = useState<StudyDto[] | null>(null);
  const [panelistCurrentState, setPanelistCurrentState] = useState<{ value: string; label: string }>();

  useEffect(() => {
    if (!!panelist?.studies) {
      setStudyInProgress(getStudiesInProgress(panelist?.studies, null));
    }
  }, [panelist]);

  useEffect(() => {
    if (!!panelist && !!panelistStates) {
      setPanelistCurrentState(panelistState(panelist, panelistStates));
    }
  }, [panelist, panelistStates]);

  return (
    <Link
      to={`/dashboard/panelist/${panelist?._id}`}
      className={`panelist-card ${studyInProgress ? 'study-in-progress' : ''}`}
    >
      <div className="picture">
        <span>
          <Avatar name={panelist?.name} lastName={panelist?.lastName} picture={panelist?.picture} />
        </span>
        <div className={`status-${panelist?.state} ${statesClasses[panelist?.state].class}`}></div>
      </div>

      <div className="information">
        <div className="name">
          <div className="icon">
            <User size={14} color="#757575" />
          </div>
          {`${panelist?.lastName}, ${panelist?.name}`}
        </div>

        <div className="location">
          <div className="icon">
            <MapPin size={13} color="#757575" />
          </div>
          {`${panelist?.street}, ${panelist?.location.partido.descripcion}, ${panelist?.location.localidad.descripcion}`}
        </div>

        <div className="age">
          <div className="icon">
            <Calendar size={13} color="#757575" />
          </div>
          {getNiceDate(panelist?.birthdate)}{' '}
          {getPanelistAge(panelist) && `(${getPanelistAge(panelist)?.number} ${getPanelistAge(panelist)?.unit})`}
        </div>

        {!!panelist?.mobilePhone && (
          <div className="mobile-phone">
            <div className="icon">
              <Smartphone size={13} color="#757575" />
            </div>
            {panelist?.mobilePhone}
          </div>
        )}

        {!panelist?.mobilePhone && !!panelist?.mobilePhone && (
          <div className="mobile-phone">
            <div className="icon">
              <Phone size={13} color="#757575" />
            </div>
            {panelist?.mobilePhone}
          </div>
        )}

        {studyInProgress && (
          <div className="study-in-progress">
            {`Estudio en curso ${studyInProgress[0].costEstimationNumber} finaliza el 
            ${getNiceDate(studyInProgress[0].endDate)}`}
          </div>
        )}
      </div>

      <span className="go-to-panelist">
        <img src={externalLink} alt="" />
      </span>
    </Link>
  );
};

const state = ({ staticsStore }) => {
  const { data: panelistStates } = staticsStore.panelistStates;
  return { panelistStates };
};
export default connect(state)(Component);
