import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'PRODUCTOS DEPILATORIOS / AFEITADO',
  id: 'productos-depilatorios-afeitado',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: '¿Se depila o se afeita?',
      name: 'se-depila-o-se-afeita',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Se depila',
          value: 'se-depila',
        },
        {
          label: 'Se afeita',
          value: 'se-afeita',
        },
        {
          label: 'Se depila y se afeita',
          value: 'se-depila-y-se-afeita',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },
    {
      label: '¿Qué método depilatorio/afeitado utiliza?',
      name: 'que-metodo-depilatorio-afeitado-utiliza',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Ceras depilatorias',
          value: 'ceras-depilatorias',
        },
        {
          label: 'Cremas depilatorias',
          value: 'cremas-depilatorias',
        },
        {
          label: 'Espumas',
          value: 'espumas',
        },
        {
          label: 'Bandas depilatorias con cera',
          value: 'bandas-depilatorias-con-cera',
        },
        {
          label: 'Máquina eléctrica',
          value: 'maquina-electrica',
        },
        {
          label: 'Con hoja (gilette)',
          value: 'con-hoja',
        },
        {
          label: 'Navaja',
          value: 'navaja',
        },
        {
          label: 'Depilación con láser',
          value: 'depilacion-con-laser',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'que-metodo-depilatorio-afeitado-utiliza-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'que-metodo-depilatorio-afeitado-utiliza',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Qué zona del cuerpo se depila?',
      name: 'que-zona-del-cuerpo-se-depila',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Bozo',
          value: 'bozo',
        },
        {
          label: 'Cejas',
          value: 'cejas',
        },
        {
          label: 'Axilas',
          value: 'axilas',
        },
        {
          label: 'Ingles',
          value: 'ingles',
        },
        {
          label: 'Piernas',
          value: 'piernas',
        },
        {
          label: 'Brazos',
          value: 'brazos',
        },
        {
          label: 'Mamas',
          value: 'mamas',
        },
        {
          label: 'Abdomen',
          value: 'abdomen',
        },
        {
          label: 'Espalda',
          value: 'espalda',
        },
        {
          label: 'Glúteos',
          value: 'gluteos',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'que-zona-del-cuerpo-se-depila-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'que-zona-del-cuerpo-se-depila',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Qué tipo de cera utiliza?',
      name: 'que-tipo-de-cera-utiliza',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Vegetal',
          value: 'vegetal',
        },
        {
          label: 'Tibia',
          value: 'tibia',
        },
        {
          label: 'Cera negra caliente',
          value: 'cera-negra-caliente',
        },
        {
          label: 'Fría de abejas',
          value: 'fria-de-abejas',
        },
        {
          label: 'Descartable con espátula',
          value: 'descartable-con-espatula',
        },
        {
          label: 'A roll-on',
          value: 'a-roll-on',
        },
        {
          label: 'Con descongestivos (aloe)',
          value: 'con-descongestivos',
        },
        {
          label: 'Miel',
          value: 'miel',
        },
        {
          label: 'Natural',
          value: 'natural',
        },
        {
          label: 'En la depiladora',
          value: 'en-la-depiladora',
        },
        {
          label: 'Cualquiera',
          value: 'cualquiera',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'que-tipo-de-cera-utiliza-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'que-tipo-de-cera-utiliza',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Le ocasiona la depilación efectos adversos?',
      name: 'le-ocasiona-la-depilacion-efectos-adversos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'No',
          value: 'no',
        },
        {
          label: 'Picazón',
          value: 'picazon',
        },
        {
          label: 'Enrojecimiento / Irritación',
          value: 'enrojecimiento-irritacion',
        },
        {
          label: 'Pelo encarnado',
          value: 'pelo-encarnado',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'le-ocasiona-la-depilacion-efectos-adversos-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'le-ocasiona-la-depilacion-efectos-adversos',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Qué zona del cuerpo se afeita?',
      name: 'que-zona-del-cuerpo-se-afeita',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Bozo',
          value: 'bozo',
        },
        {
          label: 'Cejas',
          value: 'cejas',
        },
        {
          label: 'Axilas',
          value: 'axilas',
        },
        {
          label: 'Ingles',
          value: 'ingles',
        },
        {
          label: 'Piernas',
          value: 'piernas',
        },
        {
          label: 'Brazos',
          value: 'brazos',
        },
        {
          label: 'Mamas',
          value: 'mamas',
        },
        {
          label: 'Abdomen',
          value: 'abdomen',
        },
        {
          label: 'Espalda',
          value: 'espalda',
        },
        {
          label: 'Glúteos',
          value: 'gluteos',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'que-zona-del-cuerpo-se-afeita-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'que-zona-del-cuerpo-se-afeita',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Qué producto utiliza para afeitarse?',
      name: 'que-producto-utiliza-para-afeitarse',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Cremas para afeitar',
          value: 'cremas-para-afeitar',
        },
        {
          label: 'Jabones para afeitarse',
          value: 'jabones-para-afeitarse',
        },
        {
          label: 'Espumas para afeitar',
          value: 'espumas-para-afeitar',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'que-producto-utiliza-para-afeitarse-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'que-producto-utiliza-para-afeitarse',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Le ocasiona el afeitado efectos adversos?',
      name: 'le-ocaciona-el-afeitado-efectos-adversos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Picazón',
          value: 'picazon',
        },
        {
          label: 'Enrojecimiento',
          value: 'enrojecimiento',
        },
        {
          label: 'Pelo encarnado',
          value: 'pelo-encarnado',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'le-ocaciona-el-afeitado-efectos-adversos-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'le-ocaciona-el-afeitado-efectos-adversos',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Por qué elige el método seleccionado?',
      name: 'por-que-elige-el-metodo-seleccionado',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Duración',
          value: 'duracion',
        },
        {
          label: 'Suavidad',
          value: 'suavidad',
        },
        {
          label: 'Higiene',
          value: 'higiene',
        },
        {
          label: 'Comodidad',
          value: 'comodidad',
        },
        {
          label: 'Falta de tiempo',
          value: 'falta-de-tiempo',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'por-que-elige-el-metodo-seleccionado-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'por-que-elige-el-metodo-seleccionado',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Utiliza algún producto post-depilatorio/afeitado?',
      name: 'utiliza-algun-producto-post-depilatorio-afeitado',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: 'Prod. post-depilatorio ',
      name: 'prod-post-depilatorio',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
    },
    {
      label: '¿Tuvo alguna reacción con algún producto? ¿Cuál?',
      name: 'tuvo-alguna-reaccion-con-algun-producto',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
  ],
};

export default form;
