import { useEffect, useState } from 'react';
import SweetAlert from 'sweetalert2';
import { connect, useDispatch } from 'react-redux';
import { getStudy, resetUpdateStudy } from '../../../../store/actions/study';
import StepCommonInformation from './components/StepCommonInformation';
import StepFilters from './components/StepFilters';
import StepFilteredPanelistsList from './components/StepFilteredPanelistsList';
import StepSelectedPanelistsList from './components/StepSelectedPanelistsList';
import FieldChangeStudyState from '../components/FieldChangeStudyState';
import Stepper from '../../../../components/Stepper';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import './styles.scss';

interface ComponentProps {
  updateStudyStates;
  study;
  match;
}

const Study = ({ match, updateStudyStates, study }: ComponentProps) => {
  const { error: updateStudyError, success: updateStudySuccess } = updateStudyStates;
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(getStudy(match.params.id));
  }, [match]);

  useEffect(() => {
    if (updateStudySuccess) {
      SweetAlert.fire({
        title: 'Estudio actualizado satisfactoriamente',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetUpdateStudy());
        },
      });
    }
  }, [updateStudySuccess]);

  useEffect(() => {
    if (updateStudyError) {
      SweetAlert.fire({
        title: 'Error al actualizar estudio',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetUpdateStudy());
        },
      });
    }
  }, [updateStudyError]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stepper
                onClick={setActiveStep}
                activeStep={activeStep}
                steps={[
                  { label: 'Datos del estudio', active: true },
                  { label: 'Filtros' },
                  { label: 'Panelistas Filtrados' },
                  { label: 'Panelistas Seleccionados' },
                ]}
              />
            </CardHeader>

            <CardBody>
              <StepCommonInformation active={activeStep === 0} />
              <StepFilters active={activeStep === 1} />
              <StepFilteredPanelistsList active={activeStep === 2} />
              <StepSelectedPanelistsList active={activeStep === 3} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const states = ({ studyStore }) => {
  const { states: updateStudyStates } = studyStore.updateStudy;
  const { data: study } = studyStore.study;

  return {
    updateStudyStates,
    study,
  };
};

export default connect(states)(Study);
