import { Route, Switch, useRouteMatch } from "react-router-dom";

import Profile from "./Profile";

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/profile`} component={Profile} />
    </Switch>
  );
};

export default Component;
