import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'PRODUCTOS COSMETICOS UTILIZADOS - CAPILAR',
  id: 'productos-cosmeticos-utilizados-capilar',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Productos cosméticos capilares',
      name: 'productos-cosmeticos-capilares',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Decolorantes',
          value: 'decolorantes',
        },
        {
          label: 'Tonalizadores',
          value: 'tonalizadores',
        },
        {
          label: 'Lacas / Spray',
          value: 'lacas-spray',
        },
        {
          label: 'Geles',
          value: 'geles',
        },
        {
          label: 'Mousse',
          value: 'mousse',
        },
        {
          label: 'Ceras',
          value: 'ceras',
        },
        {
          label: 'Crema para peinar',
          value: 'crema-para-peinar',
        },
        {
          label: 'Shampoo y Acondicionador sólidos',
          value: 'shampoo-y-acondicionador-solidos',
        },
      ],
    },
    {
      type: 'title',
      label: 'Producto para puntas florecidas',
      name: '',
      validationType: 'string',
    },
    {
      label: '¿Cuales?',
      name: 'productos-para-puntas-florecidas-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: 'Rutina de higiene capilar - Especifique',
      name: 'rutina-de-higiene-capilar-especifique',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: 'Comentarios',
      name: 'rutina-de-higiene-capilar-comentarios',
      description: '',
      type: 'text',
      validationType: 'string',
    },
    {
      label: 'Capilares - otras preguntas',
      name: 'capilares-otras-preguntas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Utiliza/tiene planchita',
          value: 'utiliza-tiene-planchita',
        },
        {
          label: 'Utiliza/tiene secador de pelo',
          value: 'utiliza-tiene-secador-de-pelo',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
    },
    {
      label: '¿Cuáles?',
      name: 'capilares-otras-preguntas-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'capilares-otras-preguntas',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Tuvo alguna reacción con algún producto? ¿Cuál?',
      name: 'tuvo-alguna-reaccion-con-algun-producto',
      description: '',
      type: 'text',
      validationType: 'string',
    },
  ],
};

export default form;
