interface ComponentProps {
  name: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  min?: string | number;
  max?: string | number;
  autocomplete?: 'off' | 'on' | 'new-password';
  options: {
    label?: string;
  };
}

const Component = ({ max, options, error, touched, type, onFocus, onBlur, onChange, ...rest }: ComponentProps) => {
  const { label } = options;
  const { name } = rest;

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className={`form-group ${type === 'hidden' ? 'hidden' : ''}`}>
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      <input
        className={`form-control ${!!error && !!touched ? 'error-field' : ''}`}
        onChange={(event) => handleOnChange(event, onChange)}
        onBlur={(event) => handleOnBlur(event, onBlur)}
        onFocus={(event) => handleOnFocus(event, onFocus)}
        type={type}
        onWheel={(event) => event.currentTarget.blur()}
        {...(type === 'date' && name != 'birthdate' && { max: max || '9999-12-31' })}
        {...(type === 'date' && name == 'birthdate' && { max: new Date().toISOString().slice(0, 10) })}
        {...rest}
      />

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
