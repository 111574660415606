declare global {
  interface Document {
    mozFullScreen: any;
    webkitIsFullScreen: any;
    cancelFullScreen;
    mozCancelFullScreen;
    webkitCancelFullScreen;
  }

  interface HTMLElement {
    mozRequestFullScreen: any;
    webkitRequestFullScreen: any;
  }
}

export const toggleFullScren = () => {
  if (!document.fullscreenElement) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen();
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};
