import {
  initialState,
  Actions,
  State,
  LIST_STUDIES,
  LIST_STUDIES_SUCCESS,
  LIST_STUDIES_ERROR,
  LIST_STUDIES_FILTERS,
  LIST_STUDIES_FILTERS_ERROR,
  LIST_STUDIES_FILTERS_SUCCESS,
} from '../../types/study/list';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LIST_STUDIES:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case LIST_STUDIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case LIST_STUDIES_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case LIST_STUDIES_FILTERS:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case LIST_STUDIES_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case LIST_STUDIES_FILTERS_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
