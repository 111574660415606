import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NewUser from './NewUser';
import FormBuilder from './FormBuilder';

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/form-builder`} component={FormBuilder} />
      <Route path={`${path}/new-user`} component={NewUser} />
    </Switch>
  );
};

export default Component;
