import { LocationDto } from '../../types/location';

export const SET_LOCATION = 'SET_LOCATION';

export interface SetLocation {
  type: typeof SET_LOCATION;
  payload: LocationDto;
}

export interface State {
  data: LocationDto | null;
}

export const initialState: State = {
  data: null,
};

export type Actions = SetLocation;
