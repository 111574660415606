import action from './../helpers/rest-client';
import { UserDto } from '../types/user';

export class ErrorUser extends Error {}

class UserService {
  public getUserInformation() {
    const user: UserDto = JSON.parse(localStorage.getItem('user') || '{}');
    return { ...user };
  }

  public async updateUserInformation(payload) {
    const { _id: userId } = payload;

    const response = await action
      .Patch({
        url: `/users/${userId}`,
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });

    return response;
  }

  public async getUserProfile() {
    const response = await action
      .Get({
        url: '/profile',
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });

    return response;
  }

  public async updateUserProfile(payload) {
    const response = await action
      .Patch({
        url: '/profile',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });

    return response;
  }

  public async changePassword(payload) {
    const response = await action
      .Post({
        url: '/profile/change-password',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorUser(error.message);
      });

    return response;
  }
}

const user = new UserService();
export default user;
