export const urls = [
  {
    id: 'HOME',
    path: '/dashboard/home',
    regex: /(\/home)/g,
    label: 'Inicio',
    pageTitle: 'Inicio',
  },
  {
    id: 'USER_PROFILE',
    path: '/dashboard/user/profile',
    regex: /(\/dashboard\/user\/profile)/g,
    label: 'perfil de usuario',
    pageTitle: 'perfil de usuario',
  },
  {
    id: 'NEW_USER',
    path: '/dashboard/admin/new-user',
    regex: /(\/dashboard\/admin\/new-user)/g,
    label: 'nuevo usuario',
    pageTitle: 'nuevo usuario',
  },
  {
    id: 'FORM_BUILDER',
    path: '/dashboard/admin/form-builder',
    regex: /(\/dashboard\/admin\/form-builder)/g,
    label: 'personalización',
    pageTitle: 'Personalización',
  },
  {
    id: 'NEW_PANELIST',
    path: '/dashboard/panelist/new',
    regex: /(\/dashboard\/panelist\/new)/g,
    label: 'nuevo panelista',
    pageTitle: 'Panelistas',
  },
  {
    id: 'EDIT_PANELIST',
    path: '/dashboard/panelist/:id',
    regex: /(\/dashboard\/panelist\/(?![new, list]))/g,
    label: 'editar panelistas',
    pageTitle: 'Panelistas',
  },
  {
    id: 'LIST_PANELIST',
    path: '/dashboard/panelist/list',
    regex: /(\/dashboard\/panelist\/list)/g,
    label: 'listado de panelistas',
    pageTitle: 'Panelistas',
  },
  {
    id: 'NEW_STUDY',
    path: '/dashboard/study/new',
    regex: /(\/dashboard\/study\/new)/g,
    label: 'nuevo estudio',
    pageTitle: 'Estudios',
  },
  {
    id: 'EDIT_STUDY',
    path: '/dashboard/study/:id',
    regex: /(\/dashboard\/study\/(?![new, list]))/g,
    label: 'editar estudio',
    pageTitle: 'Estudios',
  },
  {
    id: 'LIST_STUDY',
    path: '/dashboard/study/list',
    regex: /(\/dashboard\/study\/list)/g,
    label: 'listado de estudios',
    pageTitle: 'Estudios',
  },
];
