import { call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';

import {
  ASSIGNED_PANELIST,
  ASSIGNED_PANELIST_SUCCESS,
  ASSIGNED_PANELIST_ERROR,
} from '../types/panelist-study/assigned-panelist';

import {
  CONTACTED_PANELIST,
  CONTACTED_PANELIST_SUCCESS,
  CONTACTED_PANELIST_ERROR,
} from '../types/panelist-study/contacted-panelist';

function* setPanelistContacted({ payload }) {
  const { idPanelistStudy } = payload;

  try {
    let result;
    if (idPanelistStudy) {
      result = yield call(services.panelistStudy.updatePanelistStudyRelation, payload);
    } else {
      result = yield call(services.panelistStudy.createPanelistStudyRelation, payload);
    }
    yield put({ type: ASSIGNED_PANELIST_SUCCESS, result });
  } catch (error) {
    yield put({ type: ASSIGNED_PANELIST_ERROR, payload: error });
  }
}

function* setPanelistAssigned({ payload }) {
  const { idPanelistStudy } = payload;

  try {
    let result;
    if (idPanelistStudy) {
      result = yield call(services.panelistStudy.updatePanelistStudyRelation, payload);
    } else {
      result = yield call(services.panelistStudy.createPanelistStudyRelation, payload);
    }
    yield put({ type: CONTACTED_PANELIST_SUCCESS, result });
  } catch (error) {
    yield put({ type: CONTACTED_PANELIST_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(ASSIGNED_PANELIST, setPanelistAssigned);
  yield takeLatest<any>(CONTACTED_PANELIST, setPanelistContacted);
  //yield takeLatest<any>(ASSIGNED_PANELIST_BY_STUDY, getPanelistAssignedByStudy);
}
