import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Formik, useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { isExpired } from '../../../../../helpers/dates-hanlder';
import { studyState } from '../../../../../helpers/resolve-study-state';
import { inputDate } from '../../../../../helpers/dates-hanlder';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import Spinner from '../../../../../components/Spinner';
import ReactSelect from '../../../../../components/Form/ReactSelect';
import Input from '../../../../../components/Form/Input';
import { updatePanelist } from '../../../../../store/actions/panelist';
import { PanelistDto } from '../../../../../types/panelist';

import './styles.scss';

interface Option {
  label: string;
  value: string | number;
}

interface ComponentProps {
  panelistStates;
  panelist: PanelistDto;
  updatePanelistStates;
}

const MyAutoSavingComponent = () => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};

const Component = ({ panelistStates, panelist, updatePanelistStates }: ComponentProps) => {
  const dispatch = useDispatch();
  const [panelistCurrentState, setPanelistCurrentState] = useState<{ value: string; label: string }>();
  const [panelistInactiveUntil, setPanelistInactiveUntil] = useState<string>('');

  const onSubmit = ({ values, actions }) => {
    const isValidDate = !isExpired(values['inactive-until']);
    let panelistState = values.state;
    let inactiveUntil = values['inactive-until'];

    if (isValidDate && values.state === 'active') {
      panelistState = 'inactive';
    }

    if (values.state === 'active' && panelist.state === 'inactive') {
      panelistState = 'active';
      inactiveUntil = '';
      setPanelistInactiveUntil(inactiveUntil);
    }

    dispatch(
      updatePanelist({
        ...panelist,
        state: panelistState,
        'inactive-until': inactiveUntil,
        idPanelist: panelist._id,
      }),
    );
  };

  useEffect(() => {
    if (panelist && panelistStates) {
      if (!!panelist.state) {
        setPanelistCurrentState(panelistStates.filter((state) => panelist.state === state.value));
      }

      if (!!panelist['inactive-until']) {
        setPanelistInactiveUntil(inputDate(panelist['inactive-until']));
      }
    }
  }, [panelist, panelistStates]);

  if (!panelist) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        state: panelist.state,
        'inactive-until': panelistInactiveUntil,
      }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validationSchema={Yup.object().shape({
        state: Yup.string(),
        'inactive-until': Yup.string(),
      })}
    >
      {({ touched, errors, values, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit }) => {
        return (
          <form className="theme-form" onReset={handleReset} onSubmit={handleSubmit} id="change-panelist-state">
            <Row>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <label className="col-form-label pt-0">Estado</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <ReactSelect
                    name="state"
                    onOptionSelected={(values) => handleChange((values as Option).value)}
                    defaultValue={panelistCurrentState}
                    items={panelistStates}
                    error={!!errors && errors['state']}
                    touched={touched}
                    style={{ marginBottom: '0px', width: '100%', transition: 'all .1s ease-in-out' }}
                  />
                </div>
              </Col>

              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Input
                    name="inactive-until"
                    type="date"
                    placeholder=""
                    value={panelistInactiveUntil}
                    options={{ label: 'Inactivo hasta' }}
                    error={!!errors && errors['inactive-until']}
                    touched={touched}
                    min={moment(new Date()).add(1, 'days').format('YYYY-MM-DD')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Col>
            </Row>

            <MyAutoSavingComponent />

            {updatePanelistStates.loading && (
              <div className="panelist-state-spinner">
                <Spinner size="small" style={{ marginLeft: '10px' }} />
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ staticsStore, panelistStore }) => {
  const { data: panelistStates } = staticsStore.panelistStates;
  const { data: panelist } = panelistStore.panelist;
  const { states: updatePanelistStates } = panelistStore.updatePanelist;

  return {
    panelistStates,
    panelist,
    updatePanelistStates,
  };
};

export default connect(states)(Component);
