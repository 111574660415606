import { FilterFormField } from '../../../types/form-field';
export const fichaTecnicaFacialFilters: FilterFormField[] = [
  {
    type: 'title',
    label: 'FICHA TÉCNICA - FACIAL',
    name: '',
    validationType: 'string',
  },
  {
    label: 'Fotodaño facial',
    name: 'extraInformation_ficha-tecnica-facial_fotodano-facial',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Fotodaño facial - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_fotodano-facial-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Biotipo cutáneo - área facial',
    name: 'extraInformation_ficha-tecnica-facial_biotipo-cutaneo-area-facial',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Seca/Alípica',
        value: 'seca-alipica',
      },
      {
        label: 'Seborreica',
        value: 'seborreica',
      },
      {
        label: 'Seborreica fluente',
        value: 'seborreica-fluente',
      },
      {
        label: 'Mixta',
        value: 'mixta',
      },
      {
        label: 'Deshumectada / deshidratada',
        value: 'deshumectada-deshidratada',
      },
    ],
  },
  {
    label: 'Stinging test',
    name: 'extraInformation_ficha-tecnica-facial_stinging-test',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Positivo',
        value: 'positivo',
      },
      {
        label: 'Negativo',
        value: 'negativo',
      },
    ],
  },
  {
    label: 'Cantidad de Comedones',
    name: 'extraInformation_ficha-tecnica-facial_cantidad-de-comedones',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Pocos',
        value: 'pocos',
      },
      {
        label: 'Moderados',
        value: 'moderados',
      },
      {
        label: 'Muchos',
        value: 'muchos',
      },
    ],
  },
  {
    label: 'Comedones - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_comedones-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Cantidad de Pápulas',
    name: 'extraInformation_ficha-tecnica-facial_cantidad-de-papulas',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Pocos',
        value: 'pocos',
      },
      {
        label: 'Moderados',
        value: 'moderados',
      },
      {
        label: 'Muchos',
        value: 'muchos',
      },
    ],
  },
  {
    label: 'Papulas - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_papulas-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Cantidad de Pústulas',
    name: 'extraInformation_ficha-tecnica-facial_cantidad-de-pustulas',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Pocos',
        value: 'pocos',
      },
      {
        label: 'Moderados',
        value: 'moderados',
      },
      {
        label: 'Muchos',
        value: 'muchos',
      },
    ],
  },
  {
    label: 'Pústulas - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_pustulas-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Descamación',
    name: 'extraInformation_ficha-tecnica-facial_descamacion',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Área glabelar',
        value: 'area-glabelar',
      },
      {
        label: 'Mentón',
        value: 'menton',
      },
      {
        label: 'Mejilla',
        value: 'mejilla',
      },
      {
        label: 'Peribucal',
        value: 'peribucal',
      },
      {
        label: 'Otra',
        value: 'otra',
      },
    ],
  },
  {
    label: '¿Propenso a tener acné?',
    name: 'extraInformation_ficha-tecnica-facial_propenso-a-tener-acne',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Acné - pigmentación',
    name: 'extraInformation_ficha-tecnica-facial_acne-pigmentacion',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Acné - cicatrices',
    name: 'extraInformation_ficha-tecnica-facial_acne-cicatrices',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Acné - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_acne-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas - Frente',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-frente',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - frente - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-frente-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas- Entrecejo',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-entrecejo',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - entrecejo - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-entrecejo-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas- Surco nasogeniano',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-sucro-nasogeniano',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - Surco nasogeniano - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-sucro-nasogeniano-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas- Marioneta',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-marioneta',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - Marioneta - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-marioneta-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas- Peribucales',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-peribucales',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - peribucales - Apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-peribucales-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Arrugas - contorno de ojos',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-contorno-de-ojos',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leve',
        value: 'leve',
      },
      {
        label: 'Moderada',
        value: 'moderada',
      },
      {
        label: 'Profunda',
        value: 'profunda',
      },
    ],
  },
  {
    label: 'Arrugas - contorno de ojos - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_arrugas-contorno-de-ojos-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Ojeras',
    name: 'extraInformation_ficha-tecnica-facial_ojeras',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Leves',
        value: 'leves',
      },
      {
        label: 'Moderadas',
        value: 'moderadas',
      },
      {
        label: 'Severas',
        value: 'severas',
      },
    ],
  },
  {
    label: 'Ojeras - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_ojeras-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Bolsas',
    name: 'extraInformation_ficha-tecnica-facial_bolsas',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Leves',
        value: 'leves',
      },
      {
        label: 'Moderadas',
        value: 'moderado',
      },
      {
        label: 'Severas',
        value: 'severas',
      },
    ],
  },
  {
    label: 'Bolsas - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_bolsas-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Eritema cutáneo',
    name: 'extraInformation_ficha-tecnica-facial_eritema-cutaneo',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Mínimo (apenas perceptible)',
        value: 'minimo-apenas-perceptibles',
      },
      {
        label: 'Eritema suave',
        value: 'eritema-suave',
      },
      {
        label: 'Eritema moderado',
        value: 'eritema-moderado',
      },
      {
        label: 'Marcado eritema',
        value: 'marcado-eritema',
      },
    ],
  },
  {
    label: 'Eritema cutáneo - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_eritema-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Papada',
    name: 'extraInformation_ficha-tecnica-facial_papada',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Papada - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_papada-apto-foto',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Maculas hiperpigmentadas',
    name: 'extraInformation_ficha-tecnica-facial_maculas-hiperpigmentadas',
    description: '',
    type: 'checklist',
    validationType: 'object',
    options: [
      {
        label: 'Ninguna',
        value: 'ninguna',
      },
      {
        label: 'Leves',
        value: 'leves',
      },
      {
        label: 'Moderadas',
        value: 'moderadas',
      },
      {
        label: 'Severas',
        value: 'severas',
      },
    ],
  },
  {
    label: 'Maculas hiperpigmentadas - apto foto',
    name: 'extraInformation_ficha-tecnica-facial_maculas-hiperpigmentadas-apto-foto',
    description: '',
    type: 'radio',
    validationType: 'string',
    options: [
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
  {
    label: 'Área capilar - tipo de cabello',
    name: 'extraInformation_ficha-tecnica-facial_area-capilar-tipo-de-cabello',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Seco',
        value: 'seco',
      },
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Graso',
        value: 'graso',
      },
      {
        label: 'Mixto (cuero cabelludo graso - Pelo seco)',
        value: 'mixto-cuero-cabelludo-graso-pelo-seco',
      },
      {
        label: 'Crespo',
        value: 'crespo',
      },
      {
        label: 'Ondulado',
        value: 'ondulado',
      },
      {
        label: 'Lacio',
        value: 'lacio',
      },
      {
        label: 'Grueso',
        value: 'grueso',
      },
      {
        label: 'Fino',
        value: 'fino',
      },
      {
        label: 'Muy fino',
        value: 'muy-fino',
      },
    ],
  },
  {
    label: 'Área capilar - estado del cuero cabelludo',
    name: 'extraInformation_ficha-tecnica-facial_area-capilar-estado-del-cuero-cabelludo',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'Sensibilizado',
        value: 'sensibilizado',
      },
      {
        label: 'Seborreico',
        value: 'seborreico',
      },
      {
        label: 'Descamación',
        value: 'descamacion',
      },
      {
        label: 'Caspa',
        value: 'caspa',
      },
      {
        label: 'Olor',
        value: 'olor',
      },
    ],
  },
  {
    label: 'Área capilar - valoración de la caspa',
    name: 'extraInformation_ficha-tecnica-facial_area-capilar-valoracion-de-la-caspa',
    description: '',
    type: 'react-select-filter',
    validationType: 'array',
    isMulti: true,
    options: [
      {
        label: 'Todas',
        value: 'todas',
      },
      {
        label: 'I bajo nivel de caspa',
        value: 'i-bajo-nivel-de-caspa',
      },
      {
        label: 'II moderado nivel de caspa',
        value: 'ii-moderado-nivel-de-caspa',
      },
      {
        label: 'III caspa severa',
        value: 'iii-caspa-severa',
      },
      {
        label: 'IV muy alto nivel de caspa',
        value: 'iv-muy-alto-nivel-de-caspa',
      },
    ],
  },
  {
    label: 'Sensibilidad a la tintura, picor, ardor, eritema',
    name: 'extraInformation_ficha-tecnica-facial_sensibilidad-a-la-tintura-picor-ardor-eritema',
    description: '',
    type: 'react-select-filter',
    validationType: 'string',
    options: [
      {
        label: 'N/A',
        value: '',
      },
      {
        label: 'Si',
        value: 'si',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  },
];
