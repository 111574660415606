import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import services from '../../services';

import {
  LIST_STUDY_PANELISTS,
  LIST_STUDY_PANELISTS_SUCCESS,
  LIST_STUDY_PANELISTS_ERROR,
  LIST_FILTERED_PANELISTS,
  LIST_FILTERED_PANELISTS_SUCCESS,
  LIST_FILTERED_PANELISTS_ERROR,
  ADD_EXTRA_FILTERS,
  ADD_EXTRA_FILTERS_SUCCESS,
  ADD_EXTRA_FILTERS_ERROR,
} from '../types/study/panelist';
import { NEW_STUDY, NEW_STUDY_SUCCESS, NEW_STUDY_ERROR } from '../types/study/new';
import { DUPLICATE_STUDY, DUPLICATE_STUDY_SUCCESS, DUPLICATE_STUDY_ERROR } from '../types/study/duplicate';
import { UPDATE_STUDY, UPDATE_STUDY_SUCCESS, UPDATE_STUDY_ERROR } from '../types/study/update';
import { GET_STUDY, GET_STUDY_SUCCESS, GET_STUDY_ERROR, UPDATE_CURRENT_STUDY } from '../types/study/get';
import {
  LIST_STUDIES,
  LIST_STUDIES_SUCCESS,
  LIST_STUDIES_ERROR,
  LIST_STUDIES_FILTERS_SUCCESS,
} from '../types/study/list';
import {
  GET_STUDIES_STATISTICS,
  GET_STUDIES_STATISTICS_ERROR,
  GET_STUDIES_STATISTICS_SUCCESS,
} from '../types/study/statistics';
import { StudyDto } from '../../types/study';
import panelist from '../../services/panelist';

const getCurrentStudy = ({ studyStore }) => studyStore.study;

function* listStudies({ payload }) {
  try {
    const result = yield call(services.study.list, payload);
    yield all([
      put({ type: LIST_STUDIES_FILTERS_SUCCESS, payload }),
      put({ type: LIST_STUDIES_SUCCESS, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: LIST_STUDIES_ERROR, payload: error });
  }
}

function* getStudy({ payload }) {
  try {
    const result = yield call(services.study.getOne, payload);
    yield put({ type: GET_STUDY_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_STUDY_ERROR, payload: error });
  }
}

function* updateStudy({ payload }) {
  try {
    const result = yield call(services.study.updateOne, payload);
    yield all([
      put({ type: UPDATE_STUDY_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_STUDY, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: UPDATE_STUDY_ERROR, payload: error });
  }
}

function* newStudy({ payload }) {
  try {
    const result = yield call(services.study.newOne, payload);
    yield put({ type: NEW_STUDY_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: NEW_STUDY_ERROR, payload: error });
  }
}

function* duplicateStudy({ payload }) {
  const { panelists, ...rest } = payload;
  try {
    const duplicatedStudy = yield call(services.study.newOne, rest);
    let migratedPanelist = undefined;

    if (!!panelists && panelists.length > 0 && !!duplicatedStudy) {
      migratedPanelist = yield call(
        services.panelistStudy.migratePanelistStudyRelations,
        panelists.map((panelist) => {
          const { _id, idStudy, ...rest } = panelist;
          return {
            ...rest,
            idStudy: duplicatedStudy._id,
          };
        }),
      );
    }

    yield put({ type: DUPLICATE_STUDY_SUCCESS, payload: { payload, ...duplicatedStudy, panelists: migratedPanelist } });
  } catch (error) {
    yield put({ type: DUPLICATE_STUDY_ERROR, payload: error });
  }
}

function* getStudyPanelists({ payload }) {
  const study = yield select(getCurrentStudy);
  const { _id } = study.data;

  try {
    const result = yield call(services.panelist.getByStudyId, { idStudy: _id, ...(!!payload && payload) });
    yield put({ type: LIST_STUDY_PANELISTS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: LIST_STUDY_PANELISTS_ERROR, payload: error });
  }
}

function* getFilteredPanelists({ payload }) {
  const study: { data: StudyDto } = yield select(getCurrentStudy);
  const { _id } = study.data;
  const { minAge, maxAge, sex, filters, studyType } = study.data;
  const applyedFilters = {
    ...(!!filters && filters),
    ...(!!payload && payload),
    minAge,
    maxAge,
    sex,
    idStudy: _id,
    studyType,
    state: 'active',
  };

  try {
    const result = yield call(services.panelist.filteredByStudy, applyedFilters);
    yield put({ type: LIST_FILTERED_PANELISTS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: LIST_FILTERED_PANELISTS_ERROR, payload: error });
  }
}

function* addExtraFilters({ payload }) {
  const study: { data: StudyDto } = yield select(getCurrentStudy);
  const { _id } = study.data;
  const { minAge, maxAge, sex, filters, studyType } = study.data;

  const applyedFilters = {
    ...(!!filters && filters),
    ...(!!payload && payload),
    minAge,
    maxAge,
    sex,
    idStudy: _id,
    studyType,
    state: 'active',
  };

  try {
    const result = yield call(services.panelist.filteredByStudy, applyedFilters);
    yield put({ type: ADD_EXTRA_FILTERS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: ADD_EXTRA_FILTERS_ERROR, payload: error });
  }
}

function* getStatistics() {
  try {
    const result = yield call(services.study.statistics);
    yield put({ type: GET_STUDIES_STATISTICS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_STUDIES_STATISTICS_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(LIST_STUDIES, listStudies);
  yield takeLatest<any>(GET_STUDY, getStudy);
  yield takeLatest<any>(UPDATE_STUDY, updateStudy);
  yield takeLatest<any>(NEW_STUDY, newStudy);
  yield takeLatest<any>(LIST_STUDY_PANELISTS, getStudyPanelists);
  yield takeLatest<any>(LIST_FILTERED_PANELISTS, getFilteredPanelists);
  yield takeLatest<any>(GET_STUDIES_STATISTICS, getStatistics);
  yield takeLatest<any>(ADD_EXTRA_FILTERS, addExtraFilters);
  yield takeLatest<any>(DUPLICATE_STUDY, duplicateStudy);
}
