import {
  initialState,
  Actions,
  State,
  UPDATE_PANELIST,
  UPDATE_PANELIST_SUCCESS,
  UPDATE_PANELIST_ERROR,
  RESET_UPDATE_PANELIST,
  UPDATE_PANELIST_EXTRAINFORMATION,
} from '../../types/panelist/update';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_PANELIST:
      return {
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case UPDATE_PANELIST_SUCCESS:
      return {
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case UPDATE_PANELIST_ERROR:
      return {
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case UPDATE_PANELIST_EXTRAINFORMATION:
      return {
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case RESET_UPDATE_PANELIST:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
