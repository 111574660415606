import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import user from './user';
import study from './study';
import profile from './profile';
import location from './location';
import panelist from './panelist';
import statics from './statics';

const createRootReducer = (history: any) =>
  combineReducers({
    routerStore: connectRouter(history),
    userStore: user,
    profileStore: profile,
    authStore: auth,
    locationStore: location,
    panelistStore: panelist,
    staticsStore: statics,
    studyStore: study,
  });

export default createRootReducer;
