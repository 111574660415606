import {
  initialState,
  Actions,
  State,
  UPDATE_STUDY,
  UPDATE_STUDY_SUCCESS,
  UPDATE_STUDY_ERROR,
  RESET_UPDATE_STUDY,
} from '../../types/study/update';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_STUDY:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case UPDATE_STUDY_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case UPDATE_STUDY_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case RESET_UPDATE_STUDY:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
