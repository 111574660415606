import { PanelistDto } from '../../../types/panelist';

export const GET_PANELIST = 'GET_PANELIST';
export const GET_PANELIST_SUCCESS = 'GET_PANELIST_SUCCESS';
export const GET_PANELIST_ERROR = 'GET_PANELIST_ERROR';
export const UPDATE_CURRENT_PANELIST = 'UPDATE_CURRENT_PANELIST';

export interface GetPanelist {
  type: typeof GET_PANELIST;
  payload: any;
}
export interface GetPanelistSuccess {
  type: typeof GET_PANELIST_SUCCESS;
  payload: PanelistDto;
}
export interface GetPanelistError {
  type: typeof GET_PANELIST_ERROR;
  payload: boolean | string;
}
export interface UpdateCurrentPanelist {
  type: typeof UPDATE_CURRENT_PANELIST;
  payload: any;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetPanelist | GetPanelistSuccess | GetPanelistError | UpdateCurrentPanelist;
