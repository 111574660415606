export const GET_SEX_OPTIONS = 'GET_SEX_OPTIONS';
export const GET_SEX_OPTIONS_SUCCESS = 'GET_SEX_OPTIONS_SUCCESS';
export const GET_SEX_OPTIONS_ERROR = 'GET_SEX_OPTIONS_ERROR';

export interface GetSexOptions {
  type: typeof GET_SEX_OPTIONS;
  payload: any;
}
export interface GetSexOptionsSuccess {
  type: typeof GET_SEX_OPTIONS_SUCCESS;
  payload: null;
}
export interface GetSexOptionsError {
  type: typeof GET_SEX_OPTIONS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetSexOptions | GetSexOptionsSuccess | GetSexOptionsError;
