import { Fragment } from 'react';
import { useDispatch, connect } from 'react-redux';
import { User, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';
import { signOut } from '../../store/actions/auth';
import Avatar from '../Avatar';

const UserMenu = ({ userInformation }) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <Avatar name={userInformation.name} lastName={userInformation.lastName} picture={userInformation.picture} />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/user/profile`}>
              <User />
              Perfil
            </Link>
          </li>
          <li>
            <a
              onClick={() => {
                dispatch(signOut());
              }}
              href="#"
            >
              <LogOut /> Salir
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
  };
};

export default connect(state)(UserMenu);
