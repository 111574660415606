import { PanelistDto } from '../../../types/panelist';

export const GET_PANELISTS_STATISTICS = 'GET_PANELISTS_STATISTICS';
export const GET_PANELISTS_STATISTICS_SUCCESS = 'GET_PANELISTS_STATISTICS_SUCCESS';
export const GET_PANELISTS_STATISTICS_ERROR = 'GET_PANELISTS_STATISTICS_ERROR';

export interface GetPanelistsStatistics {
  type: typeof GET_PANELISTS_STATISTICS;
  payload: any;
}

export interface GetPanelistsStatisticsSuccess {
  type: typeof GET_PANELISTS_STATISTICS_SUCCESS;
  payload: PanelistDto;
}

export interface GetPanelistsStatisticsError {
  type: typeof GET_PANELISTS_STATISTICS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetPanelistsStatistics | GetPanelistsStatisticsSuccess | GetPanelistsStatisticsError;
