import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'PRODUCTOS COSMÉTICOS UTILIZADOS - CORPORAL',
  id: 'productos-cosmeticos-utilizados-corporal',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Productos cosméticos para el aseo e higiene corporal',
      name: 'productos-cosmeticos-para-el-aseo-e-higiene-corporal',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Talcos',
          value: 'talcos',
        },
        {
          label: 'Aceite, sales, perlas y geles de baño',
          value: 'aceites-sales-perlas-y-gales-de-bano',
        },
        {
          label: 'Paños y toallas húmedas',
          value: 'panos-y-toallas-humedas',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'productos-cosmeticos-para-el-aseo-e-higiene-corporal-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-cosmeticos-para-el-aseo-e-higiene-corporal',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Productos desodorantes y antitranspirantes',
      name: 'productos-desodorantes-y-antitranspirantes',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Desodorante',
          value: 'desodorante',
        },
        {
          label: 'Desodorante antitranspirante',
          value: 'desodorante-antitranspirante',
        },
        {
          label: 'Desodorante pédico',
          value: 'desodorante-pedico',
        },
        {
          label: 'Antitranspirante pédico',
          value: 'antitranspirante-pedico',
        },
        {
          label: 'Desodorantes para higiene íntima',
          value: 'desodorantes-para-higiene-intima',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'productos-desodorantes-y-antitranspirantes-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-desodorantes-y-antitranspirantes',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Desodorantes - antitranspirantes: Forma cosmética',
      name: 'desodorantes-antitranspirantes-forma-cosmetica',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Barra',
          value: 'barra',
        },
        {
          label: 'Roll-on / Bolilla',
          value: 'roll-on-bolilla',
        },
        {
          label: 'Crema',
          value: 'crema',
        },
        {
          label: 'Atomizador',
          value: 'atomizador',
        },
        {
          label: 'Aerosol',
          value: 'aerosol',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'desodorantes-antitranspirantes-forma-cosmetica-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'desodorantes-antitranspirantes-forma-cosmetica',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Usa perfumes?',
      name: 'usa-perfume',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: 'Productos cosméticos para uñas',
      name: 'productos-cosmeticos-para-unas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Esmalte',
          value: 'esmalte',
        },
        {
          label: 'Esmaltado semipermanente',
          value: 'esmaltado-semipermanente',
        },
        {
          label: 'Esmaltado permanente',
          value: 'esmaltado-permanente',
        },
        {
          label: 'Gelificado',
          value: 'gelificado',
        },
        {
          label: 'Removedor de esmalte',
          value: 'removedor-de-esmalte',
        },
        {
          label: 'Suavizante de cutícula',
          value: 'suavizante-de-cuticulas',
        },
        {
          label: 'Uñas esculpidas',
          value: 'unas-esculpidas',
        },
        {
          label: 'Uñas artificiales',
          value: 'unas-artificiales',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: 'Uñas - ¿Puede retirarse el esmalte?',
      name: 'unas-puede-retirarse-el-esmalte',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Tuvo alguna reacción con algún producto? ¿Cuál?',
      name: 'tuvo-alguna-reaccion-con-algun-producto',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
  ],
};

export default form;
