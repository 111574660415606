import action from './../helpers/rest-client';

export class ErrorPanelistTechnicalInformation extends Error {}

class PanelistTechnicalInformation {
  public async newOne(payload) {
    const { idPanelist, formId, ...rest } = payload;
    return action
      .Post({
        url: '/panelist-technical-information/',
        body: {
          idPanelist,
          data: {
            [formId]: { ...rest },
          },
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistTechnicalInformation(error.message);
      });
  }

  public async getOne({ idPanelist }) {
    return action
      .Get({
        url: `/panelist-technical-information/${idPanelist}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistTechnicalInformation(error.message);
      });
  }

  public async updateOne({ _id, ...rest }) {
    return action
      .Put({
        url: `/panelist-technical-information/${_id}`,
        body: rest,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistTechnicalInformation(error.message);
      });
  }

  public async deleteOne({ _id }) {
    return action
      .Del({
        url: `/panelist-technical-information/${_id}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistTechnicalInformation(error.message);
      });
  }
}

const panelistTechnicalInformation = new PanelistTechnicalInformation();
export default panelistTechnicalInformation;
