import action from '../helpers/rest-client';

export class ErrorPanelistStudy extends Error {}

class PanelistStudy {
  public async createPanelistStudyRelation(payload) {
    return action
      .Post({
        url: '/panelist-study',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistStudy(error.message);
      });
  }

  public async migratePanelistStudyRelations(payload) {
    return action
      .Post({
        url: '/panelist-study/migration',
        body: payload,
      })
      .then((resp) => {
        console.log('migratePanelistStudyRelations', resp);
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistStudy(error.message);
      });
  }

  public async updatePanelistStudyRelation(payload) {
    const { idPanelistStudy, ...rest } = payload;

    return action
      .Patch({
        url: `/panelist-study/${idPanelistStudy}`,
        body: { ...rest },
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistStudy(error.message);
      });
  }
}

const panelistStudy = new PanelistStudy();
export default panelistStudy;
