import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Pagination from '../../../../../../components/Pagination';
import { Row, Col } from 'reactstrap';
import slugify from 'slugify';
import { listPanelists } from '../../../../../../store/actions/panelist';
import Card from '../Card';

const Component = ({ panelistList, appliedFilters }) => {
  const dispatch = useDispatch();

  const paginate = (page) => {
    dispatch(listPanelists({ ...appliedFilters, page }));
  };

  useEffect(() => {
    dispatch(listPanelists({}));
  }, []);

  return (
    <div className="panelist-list">
      {!panelistList ? (
        <p>Aún no hay panelistas cargados.</p>
      ) : (
        panelistList.docs.map((panelist, index) => (
          <Card
            panelist={panelist}
            key={slugify(`${panelist.nombre} ${panelist.apellido} ${index}`, { lower: true })}
          />
        ))
      )}

      <Row>
        <Col>
          <Pagination {...panelistList} onClick={(page) => paginate(page)} />
        </Col>
      </Row>
    </div>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistList, filters: appliedFilters } = panelistStore.panelistList;
  return { panelistList, appliedFilters };
};

export default connect(state)(Component);
