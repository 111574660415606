import { useEffect } from 'react';
import Study from '../Card';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Pagination from '../../../../../../components/Pagination';
import { listStudies } from '../../../../../../store/actions/study';

const Component = ({ studyList, studyListStates, appliedFilters }) => {
  const { loading: loadingList } = studyListStates;
  const dispatch = useDispatch();

  const paginate = (page) => {
    console.log('paginate', appliedFilters);
    dispatch(listStudies({ ...appliedFilters, page }));
  };

  useEffect(() => {
    dispatch(listStudies({}));
  }, []);

  console.log('paginate', appliedFilters);

  /**
   * Tasks
   * Agregar skeleton al cargar el listado de estudios
   */

  return (
    <div className="studies-list">
      <Card>
        <CardBody>
          <Row>
            {loadingList ? (
              'Cargando'
            ) : !studyList ? (
              <p>Aún no hay estudios cargados.</p>
            ) : (
              studyList.docs.map((study) => (
                <Col sm={6} xl={4} key={study._id}>
                  <Study study={study} />
                </Col>
              ))
            )}
          </Row>

          <Row>
            <Col>
              <Pagination {...studyList} onClick={(page) => paginate(page)} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const states = ({ studyStore }) => {
  const { data: studyList, filters: appliedFilters, states: studyListStates } = studyStore.studyList;
  const { states: studyStates } = studyStore.study;
  return {
    studyList,
    studyListStates,
    studyStates,
    appliedFilters,
  };
};

export default connect(states)(Component);
