import { PanelistContacted, CONTACTED_PANELIST } from '../types/panelist-study/contacted-panelist';
import { PanelistAssigned, ASSIGNED_PANELIST } from '../types/panelist-study/assigned-panelist';

export const setPanelistContacted = (payload): PanelistContacted => {
  return {
    type: CONTACTED_PANELIST,
    payload,
  };
};

export const setPanelistAssigned = (payload): PanelistAssigned => {
  return {
    type: ASSIGNED_PANELIST,
    payload,
  };
};
