import moment from 'moment';
import { StudyDto } from '../types/study';
import datesHandler from './dates-hanlder';

export const getStudiesInProgress = (allStudies: StudyDto[], currentStudy: StudyDto | null) => {
  if (!allStudies) {
    return null;
  }

  const allStudiesInProgress = allStudies
    .filter((study) => {
      const sameAsCurrentStudy = !!currentStudy && study._id === currentStudy._id;
      const isActiveStudy = study.state === 'active';
      const isSelected = study.relation?.isSelected;
      const isEndend = datesHandler.isExpired(new Date(study.endDate));
      return !sameAsCurrentStudy && isActiveStudy && !isEndend && isSelected;
    })
    .sort((a, b) => {
      const date1 = new Date(moment.utc(a.endDate).format('MMM D, YYYY')).getTime();
      const date2 = new Date(moment.utc(b.endDate).format('MMM D, YYYY')).getTime();
      return date2 - date1;
    });

  return allStudiesInProgress.length > 0 ? allStudiesInProgress : null;
};
