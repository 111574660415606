import { CustomizationDto } from './../../../types/customization';

export const SAVE_USER_CUSTOMIZATION = 'SAVE_USER_CUSTOMIZATION';
export const SAVE_USER_CUSTOMIZATION_SUCCESS = 'SAVE_USER_CUSTOMIZATION_SUCCESS';
export const SAVE_USER_CUSTOMIZATION_ERROR = 'SAVE_USER_CUSTOMIZATION_ERROR';

export interface SaveUserCustomization {
  type: typeof SAVE_USER_CUSTOMIZATION;
  payload: CustomizationDto;
}

export interface SaveUserCustomizationSuccess {
  type: typeof SAVE_USER_CUSTOMIZATION_SUCCESS;
  payload: null;
}

export interface SaveUserCustomizationError {
  type: typeof SAVE_USER_CUSTOMIZATION_ERROR;
  payload: boolean | string;
}

export const GET_USER_CUSTOMIZATION = 'GET_USER_CUSTOMIZATION';
export const GET_USER_CUSTOMIZATION_SUCCESS = 'GET_USER_CUSTOMIZATION_SUCCESS';
export const GET_USER_CUSTOMIZATION_ERROR = 'GET_USER_CUSTOMIZATION_ERROR';

export interface GetUserCustomization {
  type: typeof GET_USER_CUSTOMIZATION;
  payload: null;
}

export interface GetUserCustomizationSuccess {
  type: typeof GET_USER_CUSTOMIZATION_SUCCESS;
  payload: CustomizationDto;
}

export interface GetUserCustomizationError {
  type: typeof GET_USER_CUSTOMIZATION_ERROR;
  payload: boolean | string;
}

export interface State {
  data: CustomizationDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | SaveUserCustomization
  | SaveUserCustomizationSuccess
  | SaveUserCustomizationError
  | GetUserCustomization
  | GetUserCustomizationSuccess
  | GetUserCustomizationError;
