import { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import './styles.scss';

const createPageArray = (currentPage, totalPages) => {
  const ARRAY_MAX_LENGTH = 5;
  const MIDDLE_POSITION = Math.floor(ARRAY_MAX_LENGTH / 2);

  if (!currentPage) return [];

  let pages = [currentPage];

  while (pages[0] > 1 && pages.length < ARRAY_MAX_LENGTH) {
    pages.unshift(pages[0] - 1);
  }

  let nextPagesCount = 0;

  while (
    pages[pages.length - 1] < totalPages &&
    (pages.length < ARRAY_MAX_LENGTH || nextPagesCount < MIDDLE_POSITION)
  ) {
    pages.push(pages[pages.length - 1] + 1);
    nextPagesCount += 1;

    if (pages.length > ARRAY_MAX_LENGTH) {
      pages = pages.splice(1, ARRAY_MAX_LENGTH);
    }
  }

  return pages;
};

const Component = ({ page: currentPage, totalPages, onClick }) => {
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    setPages(createPageArray(currentPage, totalPages));
  }, [currentPage, totalPages]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="pagination">
      <button className="prev-page" onClick={() => onClick(currentPage - 1)}>
        <ArrowLeft size="10px" color="#898994" />
      </button>

      {pages.length && pages[0] > 1 && (
        <>
          <div className="pagination-list">
            <button className="page-link" onClick={() => onClick(1)}>
              1
            </button>
          </div>

          <div className="etc">...</div>
        </>
      )}

      <div className="pagination-list">
        {pages.map((page) => (
          <button
            className={`page-link ${page === currentPage && 'active'}`}
            key={page}
            onClick={() => page !== currentPage && onClick(page)}
          >
            <span>{page}</span>
          </button>
        ))}
      </div>

      {pages.length && pages[pages.length - 1] < totalPages && (
        <>
          <div className="etc">...</div>

          <div className="pagination-list">
            <button className="page-link" onClick={() => onClick(totalPages)}>
              {totalPages}
            </button>
          </div>
        </>
      )}

      <button className="next-page" disabled={currentPage >= totalPages} onClick={() => onClick(currentPage + 1)}>
        <ArrowRight size="10px" color="#898994" />
      </button>
    </div>
  );
};

export default Component;
