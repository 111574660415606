import { Filter } from '../../../types/panelist-filter';

export const LIST_PANELISTS = 'LIST_PANELISTS';
export const LIST_PANELISTS_SUCCESS = 'LIST_PANELISTS_SUCCESS';
export const LIST_PANELISTS_ERROR = 'LIST_PANELISTS_ERROR';

export interface ListPanelists {
  type: typeof LIST_PANELISTS;
  payload: Filter[];
}
export interface ListPanelistsSuccess {
  type: typeof LIST_PANELISTS_SUCCESS;
  payload: null;
}
export interface ListPanelistsError {
  type: typeof LIST_PANELISTS_ERROR;
  payload: boolean | string;
}

export const LIST_PANELISTS_FILTERS = 'LIST_PANELISTS_FILTERS';
export const LIST_PANELISTS_FILTERS_SUCCESS = 'LIST_PANELISTS_FILTERS_SUCCESS';
export const LIST_PANELISTS_FILTERS_ERROR = 'LIST_PANELISTS_FILTERS_ERROR';

export interface ListPanelistsFilters {
  type: typeof LIST_PANELISTS_FILTERS;
  payload: Filter[];
}
export interface ListPanelistsFiltersSuccess {
  type: typeof LIST_PANELISTS_FILTERS_SUCCESS;
  payload: null;
}
export interface ListPanelistsFiltersError {
  type: typeof LIST_PANELISTS_FILTERS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  filters: Filter[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  filters: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | ListPanelists
  | ListPanelistsSuccess
  | ListPanelistsError
  | ListPanelistsFilters
  | ListPanelistsFiltersSuccess
  | ListPanelistsFiltersError;
