import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SweetAlert from 'sweetalert2';

import { signOut } from '../../../../../../store/actions/auth';

import { Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import NewPassword from '../NewPassword';
import OldPassword from '../OldPassword';
import Confirm from '../Confirm';
import Submit from '../../../../../../components/Form/Submit';

import { changePassword } from '../../../../../../store/actions/user';

const rules = Yup.object().shape({
  oldPassword: Yup.string().required('Este campo es requerido.'),
  newPassword: Yup.string()
    .required('Este campo es requerido')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Debe contener 8 caracateres, uno en mayuscula, uno en minuscula, un numero y un caracter especial.',
    ),
  confirm: Yup.string()
    .required('Este campo es requerido')
    .oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide'),
});

const initialValues = {};

interface ComponentProps {
  changePasswordStates;
}

const Component = ({ changePasswordStates }: ComponentProps) => {
  const {
    error: changePasswordError,
    loading: changePasswordLoading,
    success: changePasswordSuccess,
  } = changePasswordStates;
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(changePassword(values));
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      SweetAlert.fire({
        title: 'Contraseña actualizada exitosamente',
        showCancelButton: false,
        confirmButtonText: 'Confirmar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(signOut());
        },
      });
    }
  }, [changePasswordSuccess]);

  return (
    <Card>
      <CardHeader>
        <h5>Cambiar contraseña</h5>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={rules}
          enableReinitialize
        >
          {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleSubmit }) => {
            return (
              <form className="theme-form" onSubmit={handleSubmit} id="change-password">
                <Row>
                  <Col>
                    <OldPassword
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <NewPassword
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Confirm
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>

      <CardFooter>
        <Row>
          <Col>
            <div className="form-group text-right">
              <Submit form="change-password" isLoading={false}>
                Cambiar
              </Submit>
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

const states = ({ userStore }) => {
  const { states: changePasswordStates } = userStore.changePassword;
  return {
    changePasswordStates,
  };
};
export default connect(states)(Component);
