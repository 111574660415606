import { Form } from '../../../types/form-dto';
import { newPanelist } from '../../../store/actions/panelist';
import moment from 'moment';
const form: Form = {
  label: 'Nuevo Panelista',
  id: 'nuevo-panelista',
  submit: newPanelist,
  submitLabel: 'Dar de alta',
  fields: [
    {
      label: 'N° de Panelista',
      name: 'panelistNumber',
      description: 'Número de identificación del voluntario.',
      value: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },
    {
      label: 'Fecha de ficha',
      name: 'recordDate',
      description: 'Fecha de alta del voluntario.',
      type: 'date',
      value: moment().format('YYYY-MM-DD'),
      validationType: 'date',
      validations: [],
    },
    {
      label: 'A través de quien nos contacto',
      name: 'throughWhoContactedUs',
      description: 'Referente del voluntario.',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },
    {
      label: 'Nombre',
      name: 'name',
      description: 'identificacion',
      type: 'text',
      value: '',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },
    {
      label: 'Apellido',
      name: 'lastName',
      description: 'identificacion',
      type: 'text',
      value: '',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },
    {
      label: 'Sexo',
      name: 'sex',
      description: '',
      value: 'woman',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Mujer',
          value: 'woman',
        },
        {
          label: 'Hombre',
          value: 'man',
        },
      ],
      validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Dirección',
      name: 'street',
      description: '',
      value: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },

    {
      label: 'Partido',
      name: 'city',
      description: '',
      value: '',
      type: 'react-select-location',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Localidad',
      name: 'locality',
      description: '',
      value: '',
      type: 'react-select-location',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Teléfono',
      name: 'phone',
      description: '',
      value: '',
      type: 'number',
      validationType: 'number',
      //validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },

    {
      label: 'Celular',
      name: 'mobilePhone',
      description: '',
      value: '',
      type: 'number',
      validationType: 'number',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },

    {
      label: 'E-mail',
      name: 'e-mail',
      description: 'correo electrónico',
      value: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'required', params: ['Por favor, escriba su respuesta'] }],
    },

    {
      label: 'Fecha de nacimiento',
      name: 'birthdate',
      description: '',
      value: '',
      type: 'date',
      validationType: 'date',
      validations: [
        {
          type: 'max',
          params: [new Date(), 'La fecha de nacimiento no puede ser posterior al dia de la fecha'],
        },
        { type: 'required', params: ['Por favor, indique una fecha'] },
      ],
    },

    {
      label: 'Número de documento',
      name: 'identification',
      description: 'DNI',
      value: '',
      type: 'number',
      validationType: 'number',
      validations: [
        { type: 'min', params: [7, 'Mínimo 7 carácteres'] },
        { type: 'required', params: ['Por favor, escriba su respuesta'] },
      ],
    },

    {
      label: 'Origen étnico',
      name: 'etnic-origin',
      description: '',
      value: 'africano',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Africano',
          value: 'africano',
        },
        {
          label: 'Arabe',
          value: 'arabe',
        },
        {
          label: 'Hispanoamericano',
          value: 'hispanoamericano',
        },
        {
          label: 'Europeo',
          value: 'europeo',
        },
        {
          label: 'Asiático',
          value: 'asiático',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Otro origen étnico',
      name: 'other-ethnic-origin',
      description: 'Otro origen étnico',
      value: '',
      type: 'text',
      validationType: 'string',
      validations: [],
      condition: {
        enabledIf: [
          {
            field: 'origen-etnico',
            condition: 'equal',
            value: 'otro',
          },
        ],
      },
    },
    {
      label: '¿Está dispuesto a realizar los siguientes estudios?',
      name: 'studies-available',
      description: 'Estudios que el Panelista esta dispuesto a realizar.',
      value: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          value: 'ip',
          label: 'Seguridad - IP',
        },
        {
          value: 's',
          label: 'Seguridad - RIPT',
        },
        {
          value: 'sc',
          label: 'Seguridad Comedogenico',
        },
        {
          value: 'ef',
          label: 'Eficacia Facial',
        },
        {
          value: 'ec',
          label: 'Eficacia Corporal',
        },
        {
          value: 'p',
          label: 'Pediatrico Seguridad',
        },
        {
          value: 'pe',
          label: 'Pediatrico Eficacia',
        },
        {
          value: 'of',
          label: 'Oftalmologico CLAIM',
        },
        {
          value: 'ofl',
          label: 'Oftalmologico con Laboratorio',
        },
        {
          value: 'g',
          label: 'Ginecologico',
        },
        {
          value: 'o',
          label: 'Odontologico',
        },
        {
          value: 'i',
          label: 'Independientes',
        },
      ],
      validations: [{ type: 'default', params: [] }],
    },
    {
      label: 'Panelista activo',
      name: 'state',
      description: 'El voluntario se encuentra activo para realizar estudios',
      value: 'active',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Activo',
          value: 'active',
        },
        {
          label: 'Inactivo',
          value: 'inactive',
        },
      ],
      validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Panelista inactivo hasta',
      name: 'inactive-until',
      description: 'Indicar la fecha en que el voluntario se encontrara activo nuevamente',
      type: 'date',
      validationType: 'date',
      validations: [],
      condition: {
        enabledIf: [
          {
            field: 'voluntario-activo',
            condition: 'equal',
            value: 'no',
          },
        ],
      },
    },
    {
      label: 'Responsable CLAIM',
      name: 'responsable-claim',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [],
    },
    {
      label: 'Comentarios',
      name: 'comments',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [],
    },
  ],
};

export default form;
