import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'FICHA TÉCNICA - ÁREA CORPORAL',
  id: 'ficha-tecnica-area-corporal',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: 'Piernas - Diagnóstico',
      name: 'piernas-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Piernas - apto foto',
      name: 'piernas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Brazos - Diagnóstico',
      name: 'brazos-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Brazos - apto foto',
      name: 'brazos-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Codos - Diagnóstico',
      name: 'codos-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Codos - apto foto',
      name: 'codos-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Rodillas - Diagnóstico',
      name: 'rodillas-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Rodillas - apto foto',
      name: 'rodillas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },

    {
      label: 'Talones- Diagnóstico',
      name: 'talones-diagnostico',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Talones- apto foto',
      name: 'talone-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Axilas pigmentadas',
      name: 'axilas-pigmentadas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Nivel de pigmentación',
      name: 'nivel-de-pigmentacion',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Leve',
          value: 'leve',
        },
        {
          label: 'Moderado',
          value: 'moderado',
        },
        {
          label: 'Severo',
          value: 'severo',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'axilas-pigmentadas',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Axilas pigmentadas - apto foto',
      name: 'axila-pigmentadas-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'axilas-pigmentadas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, seleccione una opción'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Cicatrices',
      name: 'cicatrices',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Localización',
          value: 'localizacion',
        },
        {
          label: 'Origen',
          value: 'origen',
        },
        {
          label: 'Tiempo de evolución',
          value: 'tiempo-de-evolucion',
        },
      ],
    },
    {
      label: 'Cicatrices - Atróficas',
      name: 'cicatrices-atroficas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'cicatrices-atroficas-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-atroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Origen?',
      name: 'cicatrices-atroficas-origen',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-atroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Tiempo de evolución',
      name: 'cicatrices-atroficas-tiempo-de-evolucion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-atroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Cicatrices - Queloides',
      name: 'cicatrices-queloides',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'cicatrices-queloides-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-queloides',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Origen?',
      name: 'cicatrices-queloides-origen',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-queloides',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Tiempo de evolución',
      name: 'cicatrices-queloides-tiempo-de-evolucion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-queloides',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Cicatrices - Hipertróficas',
      name: 'cicatrices-hipertroficas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'cicatrices-hipertroficas-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-hipertroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Origen?',
      name: 'cicatrices-hipertroficas-origen',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-hipertroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Tiempo de evolución',
      name: 'cicatrices-hipertroficas-tiempo-de-evolucion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-hipertroficas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Cicatrices - Hipotróficas/deprimidas',
      name: 'cicatrices-hipertroficas-deprimidas',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Dónde?',
      name: 'cicatrices-hipertroficas-deprimidas-donde',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'cicatrices-hipertroficas-deprimidas',
            {
              is: 'si',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Cicatrices - apto foto',
      name: 'cicatrices-apto-fotos',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Telangiectasias corporal',
      name: 'telangetiasias-corporal',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Estrías - causa',
      name: 'estrias-causas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Hormonales',
          value: 'hormonales',
        },
        {
          label: 'Crecimiento',
          value: 'crecimiento',
        },
        {
          label: 'Embarazo',
          value: 'embarazo',
        },
        {
          label: 'Descenso brusco de peso',
          value: 'descenso-brusco-de-peso',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: 'Otro',
      name: 'estrias-causas-otro',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'estrias-causas',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Estrías - localización',
      name: 'estrias-localizacion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Estrías - tiempo de evolución',
      name: 'estrias-tiempo-de-evolucion',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
    {
      label: 'Color de las estrías',
      name: 'color-de-las-estrias',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Rojas',
          value: 'rojas',
        },
        {
          label: 'Violáceas',
          value: 'violaceas',
        },
        {
          label: 'Blancas/nacaradas',
          value: 'blancas-nacaradas',
        },
      ],
    },
    {
      label: 'Estrías -apto foto',
      name: 'estrias-apto-foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿Ha realizado alguna vez o realiza algún tipo de tratamiento reductor?',
      name: 'ha-realizado-alguna-vez-o-realiza-algun-tipo-de-tratamiento-reductor',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: '¿De qué tipo?',
      name: 'de-que-tipo',
      description: '',
      type: 'checklist',
      validationType: 'array',
      options: [
        {
          label: 'Aparatología',
          value: 'aparatologia',
        },
        {
          label: 'Masajes',
          value: 'masajes',
        },
        {
          label: 'Drenaje linfático',
          value: 'drenaje-linfatico',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      validations: [
        {
          type: 'when',
          params: [
            'ha-realizado-alguna-vez-o-realiza-algun-tipo-de-tratamiento-reductor',
            {
              is: 'si',
              then: { type: 'min', params: [1, 'Por favor, seleccione una opción.'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Otro',
      name: 'de-que-tipo-otro',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'de-que-tipo',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Celulitis - áreas',
      name: 'celulitis-areas',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Glúteos',
          value: 'gluteos',
        },
        {
          label: 'Muslos',
          value: 'muslos',
        },
        {
          label: 'Abdomen',
          value: 'abdomen',
        },
        {
          label: 'Mamas',
          value: 'mamas',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
    },
    {
      label: 'Otro',
      name: 'celulitis-areas-otro',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [
        {
          type: 'when',
          params: [
            'celulitis-areas',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Celulitis - grado',
      name: 'celulitis-grado',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Grado I',
          value: 'Grado-i',
        },
        {
          label: 'Grado II',
          value: 'Grado-ii',
        },
        {
          label: 'Grado III',
          value: 'Grado-iii',
        },
        {
          label: 'Grado IV',
          value: 'Grado-iv',
        },
      ],
    },
    {
      label: 'Celulitis - apto foto',
      name: 'Celulitis - apto foto',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Uñas de las manos',
      name: 'unas-de-mano',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Manchadas',
          value: 'manchadas',
        },
        {
          label: 'Débiles',
          value: 'debiles',
        },
        {
          label: 'Rugosas y ásperas',
          value: 'rugosas-y-asperas',
        },
        {
          label: 'Estriadas',
          value: 'estriadas',
        },
        {
          label: 'Mordidas',
          value: 'mordidas',
        },
        {
          label: 'Micosis',
          value: 'micosis',
        },
      ],
    },
    {
      label: 'Uñas de los pies',
      name: 'unas-de-los-pies',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Manchadas',
          value: 'manchadas',
        },
        {
          label: 'Débiles',
          value: 'debiles',
        },
        {
          label: 'Rugosas y ásperas',
          value: 'rugosas-y-asperas',
        },
        {
          label: 'Estriadas',
          value: 'estriadas',
        },
        {
          label: 'Mordidas',
          value: 'mordidas',
        },
        {
          label: 'Micosis',
          value: 'micosis',
        },
      ],
    },
    {
      label: 'Comentarios',
      name: 'ficha-tecnica-area-corporal-comentarios',
      description: '',
      type: 'text',
      validationType: 'string',
      validations: [{ type: 'max', params: [200, 'Maximo 200 carácteres'] }],
    },
  ],
};

export default form;
