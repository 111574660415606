import List from './components/List';
import SearchBar from './components/SearchBar';

interface ComponentProps {
  active: boolean;
}

const Component = ({ active }: ComponentProps) => {
  return (
    <div id="study-filtered-panelist-list" className={`study study-step ${active ? 'step-active' : ''}`}>
      <SearchBar />
      <List />
    </div>
  );
};

export default Component;
