import {
  initialState,
  Actions,
  State,
  GET_PROVINCIAS,
  GET_PROVINCIAS_SUCCESS,
  GET_PROVINCIAS_ERROR,
  GET_PARTIDOS,
  GET_PARTIDOS_SUCCESS,
  GET_PARTIDOS_ERROR,
  GET_LOCALIDADES,
  GET_LOCALIDADES_SUCCESS,
  GET_LOCALIDADES_ERROR,
} from '../../types/statics/locations';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_PROVINCIAS:
      return {
        ...state,
        data: {
          ...state.data,
          provincias: null,
        },
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_PROVINCIAS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          provincias: action.payload,
        },
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_PROVINCIAS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          provincias: null,
        },
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case GET_PARTIDOS:
      return {
        ...state,
        data: {
          ...state.data,
          partidos: null,
        },
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_PARTIDOS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          partidos: action.payload,
        },
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_PARTIDOS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          partidos: null,
        },
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case GET_LOCALIDADES:
      return {
        ...state,
        data: {
          ...state.data,
          localidades: null,
        },
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_LOCALIDADES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          localidades: action.payload,
        },
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_LOCALIDADES_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          localidades: null,
        },
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
