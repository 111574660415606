import action from '../helpers/rest-client';

export class ErrorPanelistCommonInformation extends Error {}

class PanelistCommonInformation {
  public async newOne(payload) {
    const { idPanelist, formId, ...rest } = payload;
    return action
      .Post({
        url: '/panelist-common-information',
        body: {
          idPanelist,
          data: {
            [formId]: { ...rest },
          },
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistCommonInformation(error.message);
      });
  }

  public async getOne({ idPanelist }) {
    return action
      .Get({
        url: `/panelist-common-information/${idPanelist}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistCommonInformation(error.message);
      });
  }

  public async updateOne({ _id, ...rest }) {
    return action
      .Put({
        url: `/panelist-common-information/${_id}`,
        body: rest,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistCommonInformation(error.message);
      });
  }

  public async deleteOne({ _id }) {
    return action
      .Del({
        url: `/panelist-common-information/${_id}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelistCommonInformation(error.message);
      });
  }
}

const panelistCommonInformation = new PanelistCommonInformation();
export default panelistCommonInformation;
