import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { User, MapPin, Calendar, Smartphone, Phone } from 'react-feather';
import moment from 'moment';
import Checkbox from '../../../../../../components/Form/Checkbox';
import Avatar from '../../../../../../components/Avatar';
import externalLink from '../../../../../../assets/images/icons/external-link.svg';
import './styles.scss';
import { getNiceDate } from '../../../../../../helpers/dates-hanlder';
import { useDispatch } from 'react-redux';
import slugify from 'slugify';
import { useEffect, useState } from 'react';
import { PanelistStudyDto } from '../../../../../../types/panelist-study';
import { setPanelistContacted, setPanelistAssigned } from '../../../../../../store/actions/panelist-study';
import { getStudiesInProgress } from '../../../../../../helpers/getStudiesInProgress';
import { PanelistDto } from '../../../../../../types/panelist';
import { StudyDto } from '../../../../../../types/study';
import { panelistState } from '../../../../../../helpers/resolve-panelist-state';
import { getPanelistAge } from '../../../../../../helpers/get-panelist-age';

const statesClasses = {
  active: { label: 'Activo', class: 'badge-success' },
  inactive: { label: 'Pausado', class: 'badge-danger' },
  'study-in-progress': { label: 'Finalizado', class: 'badge-warning' },
};

interface ComponentProps {
  panelist: PanelistDto;
  study: StudyDto;
  panelistStates: any;
}

const Component = ({ panelist, study, panelistStates }: ComponentProps) => {
  const [panelistCurrentState, setPanelistCurrentState] = useState<{ value: string; label: string }>();
  const [studyInProgress, setStudyInProgress] = useState<StudyDto[] | null>(null);
  const [panelistStudy, setPanelistStudy] = useState<PanelistStudyDto | undefined | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isContacted, setIsContacted] = useState<boolean>(false);
  const dispatch = useDispatch();

  const setContactedPanelist = (payload) => {
    dispatch(setPanelistContacted(payload));
  };

  const setSelectedPanelist = (payload) => {
    dispatch(setPanelistAssigned(payload));
  };

  useEffect(() => {
    if (!!panelist && !!panelist.studies && !!study) {
      const panelistStudy = panelist.studies.filter((panelistStudy) => panelistStudy._id === study._id);
      setPanelistStudy(panelistStudy.length > 0 ? panelistStudy[0].relation : null);
      setStudyInProgress(getStudiesInProgress(panelist.studies, panelistStudy[0]));
    }
  }, [panelist, study]);

  useEffect(() => {
    if (!!panelistStudy) {
      setIsContacted(panelistStudy.isContacted);
      setIsSelected(panelistStudy?.isSelected);
    }
  }, [panelistStudy]);

  useEffect(() => {
    if (!!panelist && !!panelistStates) {
      setPanelistCurrentState(panelistState(panelist, panelistStates));
    }
  }, [panelist, panelistStates]);

  return (
    <div className={`panelist-card ${studyInProgress ? 'study-in-progress' : ''}`}>
      <div className="picture">
        <span>
          <Avatar name={panelist.name} lastName={panelist.lastName} picture={panelist.picture} />
        </span>
        <div className={`status-${panelist.state} ${statesClasses[panelist.state].class}`}></div>
      </div>

      <div className="information">
        <div className="name">
          <div className="icon">
            <User size={14} color="#757575" />
          </div>
          <Link to={`/dashboard/panelist/${panelist._id}`}>{`${panelist.lastName}, ${panelist.name}`}</Link>
        </div>

        <div className="location">
          <div className="icon">
            <MapPin size={13} color="#757575" />
          </div>
          {`${panelist.street} ${panelist.number}, ${panelist.location.partido.descripcion}, ${panelist.location.localidad.descripcion}`}
        </div>

        <div className="age">
          <div className="icon">
            <Calendar size={13} color="#757575" />
          </div>
          {getNiceDate(panelist.birthdate)}{' '}
          {getPanelistAge(panelist) && `(${getPanelistAge(panelist)?.number} ${getPanelistAge(panelist)?.unit})`}
        </div>

        {!!panelist.mobilePhone && (
          <div className="mobile-phone">
            <div className="icon">
              <Smartphone size={13} color="#757575" />
            </div>
            {panelist.mobilePhone}
          </div>
        )}

        {!panelist.mobilePhone && !!panelist.mobilePhone && (
          <div className="mobile-phone">
            <div className="icon">
              <Phone size={13} color="#757575" />
            </div>
            {panelist.mobilePhone}
          </div>
        )}

        {studyInProgress && (
          <div className="study-in-progress">
            {`Estudio en curso ${studyInProgress[0].costEstimationNumber} finaliza el 
            ${getNiceDate(studyInProgress[0].endDate)}`}
          </div>
        )}
      </div>

      <div className="actions">
        <Checkbox
          name="contacted"
          options={{}}
          items={[
            {
              label: 'Contactado',
              value: 'contacted',
            },
          ]}
          key={slugify('contacted', { lower: true })}
          onChange={(event) => {
            setIsContacted(event.target.checked);
            setContactedPanelist({
              idPanelist: panelist._id,
              idStudy: study._id,
              ...(panelistStudy && { idPanelistStudy: panelistStudy?._id }),
              isContacted: event.target.checked,
            });
          }}
          value={isContacted ? ['contacted'] : []}
        />

        <Checkbox
          name="selected"
          options={{}}
          items={[
            {
              label: 'Seleccionado',
              value: 'selected',
            },
          ]}
          key={slugify('selected', { lower: true })}
          onChange={(event) => {
            setIsSelected(event.target.checked);
            setSelectedPanelist({
              idPanelist: panelist._id,
              idStudy: study._id,
              ...(panelistStudy && { idPanelistStudy: panelistStudy?._id }),
              isSelected: event.target.checked,
            });
          }}
          value={isSelected ? ['selected'] : []}
        />
      </div>

      <Link className="go-to-panelist" to={`/dashboard/panelist/${panelist._id}`}>
        <img src={externalLink} alt="" />
      </Link>
    </div>
  );
};

const state = ({ staticsStore }) => {
  const { data: panelistStates } = staticsStore.panelistStates;
  return { panelistStates };
};
export default connect(state)(Component);
