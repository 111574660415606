import {
  initialState,
  Actions,
  State,
  UPDATE_USER_MAIN_INFORMATION,
  UPDATE_USER_MAIN_INFORMATION_SUCCESS,
  UPDATE_USER_MAIN_INFORMATION_ERROR,
} from '../../types/user/update-information';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_USER_MAIN_INFORMATION:
      return {
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case UPDATE_USER_MAIN_INFORMATION_SUCCESS:
      return {
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case UPDATE_USER_MAIN_INFORMATION_ERROR:
      return {
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
