import { updateStudyFilters } from '../../../store/actions/study';
import { historiaClinicaFilters } from './historia-clinica';
import { fichaTecnicaGeneralFilters } from './ficha-tecnica-general';
import { fichaTecnicaFacialFilters } from './ficha-tecnica-facial';
import { fichaTecnicaAreaCorporalFilters } from './ficha-tecnica-area-corporal';
import { StudyFilters } from '../../../types/study-filters';

const form: StudyFilters = {
  label: 'Filtros',
  id: 'filters',
  submit: updateStudyFilters,
  submitLabel: 'Actualizar Filtros',
  fields: [
    ...historiaClinicaFilters,
    ...fichaTecnicaGeneralFilters,
    ...fichaTecnicaFacialFilters,
    ...fichaTecnicaAreaCorporalFilters,
  ],
};

export default form;
