import { connect, useDispatch } from 'react-redux';
import Pagination from '../../../../../../../../components/Pagination';
import Card from '../../../Card';
import { Row, Col } from 'reactstrap';
import { useEffect } from 'react';
import Submit from '../../../../../../../../components/Form/Submit';
import { listStudyPanelists } from '../../../../../../../../store/actions/study';

const Component = ({ panelistList, studyInformation, updateStudyStates  }) => {
  const { error: updateStudyError, loading: updateStudyLoading, success: updateStudySuccess } = updateStudyStates;
  const dispatch = useDispatch();

  const paginate = (page) => {
    dispatch(listStudyPanelists({ page }));
  };

  useEffect(() => {
    if (!!studyInformation) {
      dispatch(listStudyPanelists(null));
    }
  }, [studyInformation]);

  return (
    <>
      <Row>
        <Col>
          <div className="panelist-list">
            {panelistList.assigned.docs?.length <= 0 ? (
              <p>No se asignaron panelistas al estudio.</p>
            ) : (
              panelistList?.assigned.docs?.map((panelist, index) => (
                <Card study={studyInformation} panelist={{ ...panelist }} />
              ))
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination {...panelistList.assigned} onClick={(page) => paginate(page)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group text-right">
            <Submit form="update-study-form" isLoading={updateStudyLoading}>
              Actualizar
            </Submit>
          </div>
        </Col>
      </Row>
    </>
  );
};

const state = ({ studyStore }) => {
  const { states: updateStudyStates } = studyStore.updateStudy;
  const { data: studyInformation } = studyStore.study;
  const { data: panelistList } = studyStore.panelistList;
  return { panelistList, studyInformation, updateStudyStates };
};

export default connect(state)(Component);
