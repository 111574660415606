import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col, Progress } from 'reactstrap';
import './styles.scss';
import { StudyDto } from '../../../../../../types/study';
import { studyState } from '../../../../../../helpers/resolve-study-state';
import { useEffect, useState } from 'react';

const statesClasses = {
  active: { label: 'Activo', class: 'badge-success' },
  paused: { label: 'Pausado', class: 'badge-warning' },
  ended: { label: 'Finalizado', class: 'badge-danger' },
  canceled: { label: 'Cancelado', class: 'badge-dark' },
  deleted: { label: 'Eliminado', class: 'badge-dark' },
};

const stripStates = {
  active: 'success',
  paused: 'warning',
  ended: 'danger',
  canceled: 'dark',
  deleted: 'dark',
};

interface ComponentProps {
  study: StudyDto;
  studyZones;
  studyStates;
  studyTypes;
  sexOptions;
}

const getStudyZones = (selectedZones, studyZones) => {
  const zones = selectedZones?.map((zone) => {
    return studyZones?.filter((studyZone) => studyZone.value === zone)[0].label;
  });

  return zones.join(', ');
};

const getStudyState = (state, studyStates) => {
  return studyStates?.filter((studyState) => studyState.value === state)[0]?.label;
};

const getStudyType = (type, studyTypes) => {
  return studyTypes?.filter((studyType) => studyType.value === type)[0]?.label;
};

const getStudySex = (sex, sexOptions) => {
  return sexOptions?.filter((sexOption) => sexOption.value === sex)[0]?.label;
};

const Component = ({ study, studyZones, studyStates, studyTypes, sexOptions }: ComponentProps) => {
  const [studyCurrentState, setStudyCurrentState] = useState<{ value: string; label: string }>();
  const history = useHistory();

  /**
   * Tasks
   * Agregar un loading en la card presionada
   */

  useEffect(() => {
    if (study && studyStates) {
      setStudyCurrentState(studyState(study, studyStates));
    }
  }, [study, studyStates]);

  return (
    <button className="go-to-study simplified-study-card" onClick={() => history.push(`/dashboard/study/${study._id}`)}>
      <div className="project-box">
        {!!studyCurrentState && (
          <span className={`badge ${statesClasses[studyCurrentState.value].class} capitalize`}>
            {statesClasses[studyCurrentState.value].label}
          </span>
        )}

        <h6 className="f-w-600">{study.costEstimationNumber}</h6>

        <div className="media">
          <div className="media-body">
            <p className="m-0"># {study.studyNumber}</p>
            <p className="capitalize">{getStudyType(study.studyType, studyTypes)}</p>
          </div>
        </div>

        <div className="project-status mt-4">
          <div className="media mb-0">
            <p>{Math.round((100 / study.amountParticipants) * (study.panelists?.length || 0))}%</p>
            <div className="media-body text-right">
              <span>
                {study.panelists?.length || 0} de {study.amountParticipants} Participantes
              </span>
            </div>
          </div>

          <Progress
            className="sm-progress-bar"
            striped
            {...(studyCurrentState && { color: stripStates[studyCurrentState?.value] })}
            value={Math.round((100 / study.amountParticipants) * (study.panelists?.length || 0))}
            style={{ height: '5px' }}
          />
        </div>
      </div>
    </button>
  );
};

const states = ({ staticsStore }) => {
  const { data: studyZones } = staticsStore.studyZones;
  const { data: studyStates } = staticsStore.studyStates;
  const { data: studyTypes } = staticsStore.studyTypes;
  const { data: sexOptions } = staticsStore.sexOptions;
  return {
    studyZones,
    studyStates,
    studyTypes,
    sexOptions,
  };
};
export default connect(states)(Component);
