import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import services from '../../services';

import { NEW_PANELIST, NEW_PANELIST_SUCCESS, NEW_PANELIST_ERROR } from '../types/panelist/new';
import {
  UPDATE_PANELIST_EXTRAINFORMATION,
  UPDATE_PANELIST,
  UPDATE_PANELIST_SUCCESS,
  UPDATE_PANELIST_ERROR,
} from '../types/panelist/update';
import { GET_PANELIST, GET_PANELIST_SUCCESS, GET_PANELIST_ERROR, UPDATE_CURRENT_PANELIST } from '../types/panelist/get';
import {
  LIST_PANELISTS,
  LIST_PANELISTS_SUCCESS,
  LIST_PANELISTS_ERROR,
  LIST_PANELISTS_FILTERS_SUCCESS,
} from '../types/panelist/list';
import {
  GET_PANELISTS_STATISTICS,
  GET_PANELISTS_STATISTICS_ERROR,
  GET_PANELISTS_STATISTICS_SUCCESS,
} from '../types/panelist/statistics';

const getCurrentPanelist = ({ panelistStore }) => panelistStore.panelist.data;

function* listPanelists({ payload }) {
  try {
    const result = yield call(services.panelist.list, payload);
    yield all([
      put({ type: LIST_PANELISTS_FILTERS_SUCCESS, payload }),
      put({ type: LIST_PANELISTS_SUCCESS, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: LIST_PANELISTS_ERROR, payload: error });
  }
}

function* getPanelist({ payload }) {
  try {
    const result = yield call(services.panelist.getOne, payload);
    yield put({ type: GET_PANELIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_PANELIST_ERROR, payload: error });
  }
}

function* updatePanelist({ payload }) {
  const panelist = yield select(getCurrentPanelist);

  try {
    const result = yield call(services.panelist.updateOne, {
      ...panelist,
      ...payload,
      ...((panelist.extraInformation || payload?.extraInformation) && {
        extraInformation: {
          ...panelist.extraInformation,
          ...payload?.extraInformation,
        },
      }),
    });

    yield all([
      put({ type: UPDATE_PANELIST_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_PANELIST, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: UPDATE_PANELIST_ERROR, payload: error });
  }
}

function* newPanelist({ payload }) {
  try {
    const result = yield call(services.panelist.newOne, payload);
    yield put({ type: NEW_PANELIST_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: NEW_PANELIST_ERROR, payload: error });
  }
}

function* getStatistics() {
  try {
    const result = yield call(services.panelist.statistics);
    yield put({ type: GET_PANELISTS_STATISTICS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_PANELISTS_STATISTICS_ERROR, payload: error });
  }
}
/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(LIST_PANELISTS, listPanelists);
  yield takeLatest<any>(GET_PANELIST, getPanelist);
  yield takeLatest<any>(UPDATE_PANELIST, updatePanelist);
  yield takeLatest<any>(NEW_PANELIST, newPanelist);
  yield takeLatest<any>(UPDATE_PANELIST_EXTRAINFORMATION, updatePanelist);
  yield takeLatest<any>(GET_PANELISTS_STATISTICS, getStatistics);
}
