import check from '../../assets/images/icons/check.svg';
import './styles.scss';

const Component = ({ disabled, step, state, label, onClick }) => {
  return (
    <button onClick={onClick} className={`step ${state === 'CURRENT' ? 'current' : ''} ${disabled && 'disabled'}`}>
      <div className={`disc ${state === 'CURRENT' ? 'current' : ''}`}>
        {state === 'PREV' ? <img alt="check" width="13px" height="13px" src={check} /> : step}
      </div>{' '}
      {label}
    </button>
  );
};

export default Component;
