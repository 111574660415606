import slugify from 'slugify';
import { Field } from 'formik';
import './styles.scss';
interface ComponentProps {
  name: string;
  value?: (string | number)[];
  disabled?: boolean;
  error?: {};
  touched?: {};
  items: {
    label: string;
    value: string | number;
  }[];

  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  options: {
    label?: string;
  };
  checked?: boolean;
}

const Component = ({ options, error, touched, items, name, value, onFocus, onBlur, onChange }: ComponentProps) => {
  const { label } = options;

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <div className="form-group">
      {!!label && <label className="col-form-label pt-0">{label}</label>}

      {items.map((item, index) => {
        const { label: itemLabel, value: itemValue } = item;
        const id = `${slugify(itemLabel || '', { lower: true })}-${index}`;

        return (
          <div className="checkbox-option" key={id}>
            <input
              type="checkbox"
              name={name}
              id={id}
              value={itemValue}
              checked={value && value.includes(itemValue)}
              className={`checkbox_animated ${!!error && !!touched ? 'error-field' : ''}`}
              onChange={(event) => handleOnChange(event, onChange)}
              onBlur={(event) => handleOnBlur(event, onBlur)}
              onFocus={(event) => handleOnFocus(event, onFocus)}
            />
            <label htmlFor={id}>{itemLabel}</label>
          </div>
        );
      })}

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
