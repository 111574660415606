import ReactSelect from '../../../../../components/Form/ReactSelect';
import { ErrorMessage } from 'formik';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { CSSProperties } from 'react';

interface ComponentProps {
  style?: CSSProperties;
  studyZones;
  values?: any;
  type?: string;
  disabled?: boolean;
  errors?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
}

const Filter = ({ studyZones, errors, touched, values, ...rest }: ComponentProps) => {
  const hasError = !!errors && !!errors['studyZones'] && !!touched;

  return (
    <div className="form-group">
      <Row>
        <Col>
          <label className="col-form-label pt-0">Zonas de uso</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactSelect
            name="studyZones"
            items={studyZones}
            error={!!errors && errors['studyZones']}
            touched={touched}
            isMulti
            {...(!!values && { defaultVaue: values['studyZones'] })}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrorMessage name="studyZones" />
        </Col>
      </Row>
    </div>
  );
};

const states = ({ staticsStore }) => {
  const { data: studyZones } = staticsStore.studyZones;
  return {
    studyZones,
  };
};

export default connect(states)(Filter);
