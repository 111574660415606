import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { getSexOptions, getStudyStates, getStudyTypes, getStudyZones } from '../../../store/actions/statics';

import New from './New';
import List from './List';
import Edit from './Edit';

const Component = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(getStudyTypes());
    dispatch(getStudyZones());
    dispatch(getSexOptions());
    dispatch(getStudyStates());
  }, []);

  return (
    <Switch>
      <Route path={`${path}/new`} component={New} />
      <Route path={`${path}/list`} component={List} />
      <Route exact path={`${path}/:id`} component={(props) => <Edit {...props} />} />
      <Route path={`*`} render={() => <Redirect to={`${path}/list`} />} />
    </Switch>
  );
};

export default Component;
