import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import { listPanelists } from '../../../../store/actions/panelist';
import Filters from './components/Filters';
import List from './components/List';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const Panelist = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPanelists({}));
  }, []);

  return (
    <Container fluid={true}>
      <Row>
        <Col md={12} className="project-list">
          <Card>
            <Row>
              <Col>
                <div className="text-right">
                  <Link className="btn btn-primary" to={'/dashboard/panelist/new'}>
                    {' '}
                    <PlusCircle />
                    Nuevo Panelista
                  </Link>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={3}>
          <Filters />
        </Col>
        <Col>
          <List />
        </Col>
      </Row>
    </Container>
  );
};

export default Panelist;
