import action from '../helpers/rest-client';

export class ErrorPanelist extends Error {}

class Panelist {
  public async newOne(payload) {
    return action
      .Post({
        url: '/panelist',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error);
      });
  }

  public async getOne(idPanelis) {
    return action
      .Get({
        url: `/panelist/${idPanelis}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async updateOne({ idPanelist, ...rest }) {
    return action
      .Patch({
        url: `/panelist/${idPanelist}`,
        body: rest,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async deleteOne({ idPanelist }) {
    return action
      .Del({
        url: `/panelist/${idPanelist}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async list(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = 'sort=lastName&order=1';
    return action
      .Get({
        url: `/panelist?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async filteredByStudy(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = 'sort=lastName&order=1';
    return action
      .Get({
        url: `/panelist/filtered-by-study?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async getByStudyId(filters) {
    const queryString = new URLSearchParams(filters).toString();
    const sort = 'sort=lastName&order=1';
    return action
      .Get({
        url: `/panelist/by-study?${!!queryString ? `${queryString}&${sort}` : sort}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }

  public async statistics() {
    return action
      .Get({
        url: `/panelist/statistics`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorPanelist(error.message);
      });
  }
}

const panelist = new Panelist();
export default panelist;
