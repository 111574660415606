import datosPersonales from './datos-personales';
import historiaClinica from './historia-clinica';
import productosCosmeticosUtilizadosFacial from './productos-cosmeticos-utilizados-facial';
import productosCosmeticosUtilizadosCorporal from './productos-cosmeticos-utilizados-corporal';
import productosCosmeticosUtilizadosCapilar from './productos-cosmeticos-utilizados-capilar';
import productosDepilatoriosAfeitado from './productos-depilatorios-afeitado';
import productosCosmeticosCuidadosPersonal from './productos-cosmeticos-cuidados-personal';
import fichaTecnicaGeneral from './ficha-tecnica-general';
import fichaTecnicaFacial from './ficha-tecnica-facial';
import fichaTecnicaAreaCorporal from './ficha-tecnica-area-corporal';

export const forms = [
  datosPersonales,
  historiaClinica,
  productosCosmeticosUtilizadosFacial,
  productosCosmeticosUtilizadosCorporal,
  productosCosmeticosUtilizadosCapilar,
  productosDepilatoriosAfeitado,
  productosCosmeticosCuidadosPersonal,
  fichaTecnicaGeneral,
  fichaTecnicaFacial,
  fichaTecnicaAreaCorporal,
];
