export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR';

export interface GetUserRoles {
  type: typeof GET_USER_ROLES;
  payload: any;
}
export interface GetUserRolesSuccess {
  type: typeof GET_USER_ROLES_SUCCESS;
  payload: null;
}
export interface GetUserRolesError {
  type: typeof GET_USER_ROLES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetUserRoles | GetUserRolesSuccess | GetUserRolesError;
