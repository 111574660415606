export const UPDATE_PANELIST = 'UPDATE_PANELIST';
export const UPDATE_PANELIST_SUCCESS = 'UPDATE_PANELIST_SUCCESS';
export const UPDATE_PANELIST_ERROR = 'UPDATE_PANELIST_ERROR';
export const UPDATE_PANELIST_EXTRAINFORMATION = 'UPDATE_PANELIST_EXTRAINFORMATION';
export const RESET_UPDATE_PANELIST = 'RESET_UPDATE_PANELIST';

export interface UpdatePanelist {
  type: typeof UPDATE_PANELIST;
  payload: any;
}
export interface UpdatePanelistSuccess {
  type: typeof UPDATE_PANELIST_SUCCESS;
  payload: null;
}
export interface UpdatePanelistError {
  type: typeof UPDATE_PANELIST_ERROR;
  payload: boolean | string;
}
export interface ResetUpdatePanelist {
  type: typeof RESET_UPDATE_PANELIST;
  payload: null;
}

export interface UpdatePanelistExtraInformation {
  type: typeof UPDATE_PANELIST_EXTRAINFORMATION;
  payload: any;
}

export interface State {
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | UpdatePanelist
  | UpdatePanelistSuccess
  | UpdatePanelistError
  | ResetUpdatePanelist
  | UpdatePanelistExtraInformation;
