import { connect } from 'react-redux';
import EmptyState from './components/EmptyState';
import List from './components/List';
import './styles.scss';

const Component = ({ panelistStatistics }) => {
  return panelistStatistics?.lastPanelist.length <= 0 ? <EmptyState /> : <List />;
};

const state = ({ panelistStore }) => {
  const { data: panelistStatistics } = panelistStore.statistics;
  return { panelistStatistics };
};

export default connect(state)(Component);
