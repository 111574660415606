export const GET_PANELIST_STATES = 'GET_PANELIST_STATES';
export const GET_PANELIST_STATES_SUCCESS = 'GET_PANELIST_STATES_SUCCESS';
export const GET_PANELIST_STATES_ERROR = 'GET_PANELIST_STATES_ERROR';

export interface GetPanelistStates {
  type: typeof GET_PANELIST_STATES;
  payload: any;
}
export interface GetPanelistStatesSuccess {
  type: typeof GET_PANELIST_STATES_SUCCESS;
  payload: null;
}
export interface GetPanelistStatesError {
  type: typeof GET_PANELIST_STATES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = GetPanelistStates | GetPanelistStatesSuccess | GetPanelistStatesError;
