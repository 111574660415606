import { Link } from 'react-router-dom';

const Component = () => {
  return (
    <div id="studies-list-empty-state">
      <h2>No se encontraron nuevos panelistas</h2>

      <Link to="/dashboard/panelist/new">Crear nuevo panelista</Link>
    </div>
  );
};

export default Component;
