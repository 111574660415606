import { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { connect, useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import * as Yup from 'yup';

import Form from '../../components/Form';
import Input from '../../components/Form/Input';
import Submit from '../../components/Form/Submit';
import ErrorMessage from '../../components/Form/ErrorMessage';

import { recoverPassword, resetPassword, signIn } from '../../store/actions/auth';
import './styles.scss';

interface FormLoginValues {
  email: string;
  password: string;
}

interface FormRecoveryCodeValues {
  email: string;
}

interface FormPasswordRecoveryValues {
  id: string;
  password: string;
  'confirm-password': string;
}

const formLoginValues: FormLoginValues = {
  email: '',
  password: '',
};

const formRecoveryCodeValues: FormRecoveryCodeValues = {
  email: '',
};

const formPasswordRecoveryValues: FormPasswordRecoveryValues = {
  id: '',
  password: '',
  'confirm-password': '',
};

const formLoginRules = Yup.object().shape({
  email: Yup.string().email('El email es invalido.').required('Este campo es requerido.'),
  password: Yup.string().required('Este campo es requerido.'),
});

const formRecoveryCodeRules = Yup.object().shape({
  email: Yup.string().email('El email es invalido.').required('Este campo es requerido.'),
});

const formPasswordRecoveryRules = Yup.object().shape({
  id: Yup.string().required('Este campo es requerido.'),
  password: Yup.string()
    .required('Este campo es requerido')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Debe contener 8 caracateres, uno en mayuscula, uno en minuscula, un numero y un caracter especial.',
    ),
  'confirm-password': Yup.string()
    .required('Este campo es requerido')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
});

const Page = ({ userStates, userInformation, authStates, recoverPasswordStates, resetPasswordStates }) => {
  const dispatch = useDispatch();

  const { loading: userLoading } = userStates;
  const { error: authError, loading: authLoading } = authStates;

  const [showLogin, setShowLogin] = useState(true);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [accountToRecover, setAccountToRecover] = useState('');

  const appSignIn = ({ values, actions }) => {
    dispatch(signIn(values));
  };

  const sendRecoveryCode = ({ values, actions }) => {
    dispatch(recoverPassword(values));
    setAccountToRecover(values.email);
  };

  const changePassword = ({ values, actions }) => {
    const { id, password } = values;
    dispatch(resetPassword({ id, password }));
  };

  useEffect(() => {
    if (recoverPasswordStates.success) {
      setShowLogin(false);
      setShowPasswordRecovery(false);
      setShowPasswordChange(true);
    }
  }, [recoverPasswordStates]);

  useEffect(() => {
    if (resetPasswordStates.success) {
      setShowLogin(true);
      setShowPasswordRecovery(false);
      setShowPasswordChange(false);
    }
  }, [resetPasswordStates]);

  if (!!userInformation) {
    return <Redirect to="/dashboard/home" />;
  }

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="Logo" width=" 80px;" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        {showLogin && (
                          <>
                            <div className="text-center m-b-30">
                              <h4>Bienvenido</h4>
                              <p>Ingresá tu usuario y contraseña</p>
                            </div>

                            <Form id="login-form" rules={formLoginRules} values={formLoginValues} onSubmit={appSignIn}>
                              <Input
                                type="email"
                                name="email"
                                placeholder="Ingrese su e-mail"
                                options={{ label: 'E-mail' }}
                              />

                              <Input
                                type="password"
                                name="password"
                                placeholder="Ingrese su contraseña"
                                options={{ label: 'Contraseña' }}
                              />

                              <ErrorMessage visible={!!authError}>Usuario o Contraseña incorrecta</ErrorMessage>

                              <div className="form-group text-center m-t-20">
                                <Submit form="login-form" className="btn-block" isLoading={authLoading || userLoading}>
                                  Iniciar sesión
                                </Submit>
                                <button
                                  className="btn btn-link recover-password-btn"
                                  type="button"
                                  onClick={() => {
                                    setShowLogin(false);
                                    setShowPasswordRecovery(true);
                                    setShowPasswordChange(false);
                                  }}
                                >
                                  Olvide mi contraseña
                                </button>
                              </div>
                            </Form>
                          </>
                        )}

                        {showPasswordRecovery && (
                          <>
                            <div className="text-center m-b-30">
                              <h4>Recupera tu contraseña</h4>
                              <p>Ingresá tu e-mail </p>
                            </div>

                            <Form
                              id="recover-code-form"
                              rules={formRecoveryCodeRules}
                              values={formRecoveryCodeValues}
                              onSubmit={sendRecoveryCode}
                            >
                              <Input
                                type="email"
                                name="email"
                                placeholder="Ingrese su e-mail"
                                options={{ label: 'E-mail' }}
                              />

                              <div className="form-group text-center m-t-20">
                                <Submit
                                  form="recover-code-form"
                                  className="btn-block"
                                  isLoading={recoverPasswordStates.loading}
                                >
                                  Recuperar contraseña
                                </Submit>
                                <button
                                  className="btn btn-link recover-code-btn"
                                  type="button"
                                  onClick={() => {
                                    setShowLogin(true);
                                    setShowPasswordRecovery(false);
                                    setShowPasswordChange(false);
                                  }}
                                >
                                  cancelar
                                </button>
                              </div>
                            </Form>
                          </>
                        )}

                        {showPasswordChange && (
                          <>
                            <div className="text-center m-b-30">
                              <h4>Recupera tu contraseña</h4>
                              <p>Ingresá el código de recuperacion que enviamos a tu corre electrónico.</p>
                            </div>

                            <div className="fake-form-group">
                              <span className="label">Email</span>
                              <span className="input">{accountToRecover}</span>
                            </div>

                            <Form
                              id="recover-password-form"
                              rules={formPasswordRecoveryRules}
                              values={formPasswordRecoveryValues}
                              onSubmit={changePassword}
                            >
                              <Input
                                type="text"
                                name="id"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                options={{ label: 'Código de recuperación' }}
                                autocomplete="off"
                              />

                              <Input
                                type="password"
                                name="password"
                                placeholder="Ingrese su contraseña"
                                options={{ label: 'Nueva contraseña' }}
                                autocomplete="new-password"
                              />

                              <Input
                                type="password"
                                name="confirm-password"
                                placeholder="Ingrese su contraseña"
                                options={{ label: 'Confirmar contraseña' }}
                                autocomplete="new-password"
                              />

                              <div className="form-group text-center m-t-20">
                                <Submit
                                  form="recover-password-form"
                                  className="btn-block"
                                  isLoading={resetPasswordStates.loading}
                                >
                                  Cambiar contraseña
                                </Submit>
                                <button
                                  className="btn btn-link recover-password-btn"
                                  type="button"
                                  onClick={() => {
                                    setShowLogin(true);
                                    setShowPasswordRecovery(false);
                                    setShowPasswordChange(false);
                                  }}
                                >
                                  cancelar
                                </button>
                              </div>
                            </Form>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const state = ({ authStore, userStore }) => {
  const { signin, recoverPassword, resetPassword } = authStore;
  const { states: authStates } = signin;
  const { states: recoverPasswordStates } = recoverPassword;
  const { states: resetPasswordStates } = resetPassword;
  const { data: userInformation, states: userStates } = userStore.information;
  return {
    authStates,
    recoverPasswordStates,
    resetPasswordStates,
    userStates,
    userInformation,
  };
};

export default connect(state)(Page);
