import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';
import { PlusCircle } from 'react-feather';
import Filters from './components/Filters';
import List from './components/List';
import './styles.scss';

const Studies = () => {
  return (
    <Container fluid={true}>
      <Row>
        <Col md={12} className="project-list">
          <Card>
            <Row>
              <Col>
                <div className="text-right">
                  <Link className="btn btn-primary" to={'/dashboard/study/new'}>
                    {' '}
                    <PlusCircle />
                    Nuevo Estudio
                  </Link>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={5} xl={3}>
          <Filters />
        </Col>
        <Col sm={7} xl={9}>
          <List />
        </Col>
      </Row>
    </Container>
  );
};

export default Studies;
