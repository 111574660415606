import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import Submit from '../../../../../../components/Form/Submit';

import FieldChangeStudyState from '../../../components/FieldChangeStudyState';
import FieldComments from '../../../components/FieldComments';
import FieldAge from '../../../components/FieldAge';
import FieldAmountParticipants from '../../../components/FieldAmountParticipants';
import FieldAmountProducts from '../../../components/FieldAmountProducts';
import FieldControls from '../../../components/FieldControls';
import FieldCostStimationNumber from '../../../components/FieldCostStimationNumber';
import FieldEndDate from '../../../components/FieldEndDate';
import FieldLaboratoryCode from '../../../components/FieldLaboratoryCode';
import FieldPayment from '../../../components/FieldPayment';
import FieldRevision from '../../../components/FieldRevision';
import FieldSex from '../../../components/FieldSex';
import FieldStartDate from '../../../components/FieldStartDate';
import FieldStudyNumber from '../../../components/FieldStudyNumber';
import FieldType from '../../../components/FieldType';
import FieldZones from '../../../components/FieldZones';

import { updateStudy } from '../../../../../../store/actions/study';
import { initialValues, rules } from '../../../../../../constants/study/new-study';
import { dateSpliter } from '../../../../../../helpers/dates-hanlder';

interface ComponentProps {
  updateStudyStates;
  study;
  active: boolean;
}

const Component = ({ active, study, updateStudyStates }: ComponentProps) => {
  const { error: updateStudyError, loading: updateStudyLoading, success: updateStudySuccess } = updateStudyStates;
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateStudy({ ...values, idStudy: study._id }));
  };

  if (!study) {
    return null;
  }

  return (
    <div id="study-common-information" className={`study study-step ${active ? 'step-active' : ''}`}>
      <Formik
        initialValues={{
          ...initialValues,
          ...study,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validationSchema={rules}
        enableReinitialize
      >
        {({ touched, errors, values, handleChange, isSubmitting, handleBlur, handleReset, handleSubmit }) => {
          return (
            <form className="theme-form" onSubmit={handleSubmit} id="update-study-form">
              <Row>
                <Col md={3}>
                  <FieldChangeStudyState />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldStudyNumber
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <FieldCostStimationNumber
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldRevision
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldLaboratoryCode
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={3}>
                  <FieldPayment
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldType errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
                <Col md={6}>
                  <FieldAmountProducts
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldStartDate
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldEndDate
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldAmountParticipants
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldSex errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FieldAge
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FieldControls
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldZones errors={errors} touched={touched} onBlur={handleBlur} values={values} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldComments
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="form-group text-right">
                    <Submit form="update-study-form" isLoading={updateStudyLoading}>
                      Actualizar
                    </Submit>
                  </div>
                </Col>
              </Row>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const states = ({ studyStore }) => {
  const { states: updateStudyStates } = studyStore.updateStudy;
  const { data: study } = studyStore.study;
  return {
    updateStudyStates,
    study,
  };
};
export default connect(states)(Component);
