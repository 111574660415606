export const NEW_STUDY = 'NEW_STUDY';
export const NEW_STUDY_SUCCESS = 'NEW_STUDY_SUCCESS';
export const NEW_STUDY_ERROR = 'NEW_STUDY_ERROR';
export const RESET_NEW_STUDY = 'RESET_NEW_STUDY';

export interface NewStudy {
  type: typeof NEW_STUDY;
  payload: any;
}
export interface NewStudySuccess {
  type: typeof NEW_STUDY_SUCCESS;
  payload: null;
}
export interface NewStudyError {
  type: typeof NEW_STUDY_ERROR;
  payload: boolean | string;
}
export interface ResetNewStudy {
  type: typeof RESET_NEW_STUDY;
  payload: null;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = NewStudy | NewStudySuccess | NewStudyError | ResetNewStudy;
