import Card from '../../../../../Panelist/List/components/Card';
import { connect } from 'react-redux';
import './styles.scss';
import slugify from 'slugify';

const Component = ({ panelistStatistics }) => {
  return (
    <div id="panelist-list">
      <h2>Ultimos Panelistas</h2>

      <div>
        <ul>
          {panelistStatistics?.lastPanelist.map((panelist, index) => (
            <li key={slugify(`${panelist.name}-${panelist.lastName}-${index}`)}>
              <Card panelist={panelist} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const state = ({ panelistStore }) => {
  const { data: panelistStatistics } = panelistStore.statistics;
  return { panelistStatistics };
};

export default connect(state)(Component);
