import { useEffect } from 'react';
import SweetAlert from 'sweetalert2';
import { useHistory } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { forms } from '../../../../constants/panelist/contact-information';
import Submit from '../../../../components/Form/Submit';
import FormFactory from '../../../../helpers/FormFactory';
import { resetNewPanelist } from '../../../../store/actions/panelist';
import { Container, Row, Col } from 'reactstrap';
import './styles.scss';

interface ComponentProps {
  newPanelistData;
  newPanelistStates;
}

const Component = ({ newPanelistData, newPanelistStates }: ComponentProps) => {
  const dispatch = useDispatch();
  const { loading, error, success } = newPanelistStates;
  const history = useHistory();

  useEffect(() => {
    if (success && !!newPanelistData) {
      SweetAlert.fire({
        title: 'Panelista creado satisfactoriamente',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetNewPanelist());
          history.push(`/dashboard/panelist/${newPanelistData._id}`);
        },
      });
    }
  }, [success, newPanelistData]);

  useEffect(() => {
    if (error) {
      SweetAlert.fire({
        title: 'Error al crear panelista',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        preConfirm: () => {
          dispatch(resetNewPanelist());
          //window.location.reload();
        },
      });
    }
  }, [error]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <div id="panelist-contact-information">
            {forms.map((form) => {
              console.log('form', form);
              return (
                <div className="card panelist-information" key={form.id}>
                  <div className="card-body">
                    <FormFactory form={form} />
                  </div>
                  <div className="card-footer text-right">
                    <Submit form={form.id} isLoading={loading}>
                      {form.submitLabel}
                    </Submit>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const states = ({ panelistStore }) => {
  const { data: newPanelistData, states: newPanelistStates } = panelistStore.newPanelist;
  return {
    newPanelistData,
    newPanelistStates,
  };
};
export default connect(states)(Component);
