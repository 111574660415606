import {
  initialState,
  Actions,
  State,
  NEW_STUDY,
  NEW_STUDY_SUCCESS,
  NEW_STUDY_ERROR,
  RESET_NEW_STUDY,
} from '../../types/study/new';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_STUDY:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case NEW_STUDY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case NEW_STUDY_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case RESET_NEW_STUDY:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
