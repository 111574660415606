import Spinner from '../../Spinner';

interface ComponentProps {
  isLoading: boolean;
  form: string;
  children: any;
  className?: string;
}

const Component = ({ isLoading, children, className, ...rest }: ComponentProps) => {
  console.log('button', isLoading);
  return (
    <button disabled={isLoading} className={`btn btn-primary ${className || ''}`} type="submit" {...rest}>
      {isLoading ? <Spinner size="small" /> : children}
    </button>
  );
};

export default Component;
