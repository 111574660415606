import { Activity, User, Home, UserPlus } from 'react-feather';

interface Path {
  path?: string;
  title: string;
  type: string;
  active?: boolean;
}

export interface SubPath extends Path {
  children?: Path[];
  active?: boolean;
}

export interface MenuItem {
  onlyAdmins?: boolean;
  title: string;
  sidebartitle?: string;
  icon: any;
  type: string;
  badgeType?: string;
  bookmark?: boolean;
  active?: boolean;
  path?: string;
  children?: SubPath[];
}

export const MENUITEMS: MenuItem[] = [
  {
    title: 'Inicio',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: '/dashboard/home',
  },
  {
    title: 'Estudios',
    icon: Activity,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: '/dashboard/study',
  },
  {
    title: 'Panelistas',
    icon: User,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: '/dashboard/panelist',
  },
  {
    onlyAdmins: true,
    title: 'Agregar usuario',
    icon: UserPlus,
    type: 'link',
    badgeType: 'primary',
    active: false,
    path: '/dashboard/admin/new-user',
  },
  // {
  //   title: 'Herramientas',
  //   icon: Tool,
  //   type: 'sub',
  //   badgeType: 'primary',
  //   active: false,
  //   children: [
  //     {
  //       path: '/dashboard/admin/new-user',
  //       title: 'Agregar usuario',
  //       type: 'link',
  //       active: false,
  //     },
  //   ],
  // },
];
