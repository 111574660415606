import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'PRODUCTOS COSMÉTICOS CUIDADO PERSONAL',
  id: 'productos-cosmeticos-cuidado-personal',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: '¿Usa Gel íntimo?',
      name: 'usa-gel-intimo',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },
    {
      label: '¿Método Anticoncepción utilizado?',
      name: 'metodo-anticoncepcion-utilizado',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Anticonceptivo oral',
          value: 'anticonceptivo-oral',
        },
        {
          label: 'DIU',
          value: 'diu',
        },
        {
          label: 'Preservativo',
          value: 'preservativo',
        },
        {
          label: 'Parche',
          value: 'parche',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'metodo-anticoncepcion-utilizado-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'metodo-anticoncepcion-utilizado',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Usa preservativo?',
      name: 'usa-preservativo',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Productos para el cuidado e higiene intima?',
      name: 'productos-para-el-cuidado-e-higiene-intima',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Jabón líquido íntimo',
          value: 'jabon-liquido-intimo',
        },
        {
          label: 'Paños y Toallas húmedas intimas',
          value: 'panos-y-toallas-humedas-intimas',
        },
        {
          label: 'Protectores diarios descartables',
          value: 'protectores-diarios-descartables',
        },
        {
          label: 'Protectores diarios ecológicas reutilizables',
          value: 'protectores-diarios.ecologicas-reutilizables',
        },
        {
          label: 'Toallitas descartables',
          value: 'toallitas-descartables',
        },
        {
          label: 'Toallitas ecológicas reutilizables',
          value: 'toallitas-ecologicas-reutilizables',
        },
        {
          label: 'Copa menstrual',
          value: 'copa-menstrual',
        },
        {
          label: 'Tampón',
          value: 'tampon',
        },
        {
          label: 'Otros',
          value: 'otros',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'productos-para-el-cuidado-e-higiene-intima-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-para-el-cuidado-e-higiene-intima',
            {
              is: 'otros',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
  ],
};

export default form;
