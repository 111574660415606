import { Form } from '../../../types/form-dto';
import { updatePanelistExtraInformation } from '../../../store/actions/panelist';

const form: Form = {
  label: 'PRODUCTOS COSMÉTICOS UTILIZADOS - FACIAL',
  id: 'productos-cosmeticos-utilizados-facial',
  submit: updatePanelistExtraInformation,
  submitLabel: 'Guardar',
  fields: [
    {
      label: '¿Mantiene alguna rutina de higiene facial?',
      name: 'mantiene-alguna-rutina-de-higiene-facial',
      description: '',
      type: 'radio',
      validationType: 'string',
      options: [
        {
          label: 'Si',
          value: 'si',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione una opción'] }],
    },

    {
      label: 'Rutina de higiene facial - Especifique',
      name: 'rutina-de-higiene-facial',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'A la mañana',
          value: 'a-la-manana',
        },
        {
          label: 'A la noche',
          value: 'a-la-noche',
        },
        {
          label: 'Nunca',
          value: 'nunca',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione solo una de las opciones'] }],
    },
    {
      label: 'Comentario',
      name: 'comentario',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
    },
    {
      label: 'Productos para el blanqueado de la piel del rostro',
      name: 'productos-para-el-blanqueado-de-la-piel-del-rostro',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Cremas blanqueadoras',
          value: 'cremas-blanqueadoras',
        },
        {
          label: 'Tratamiento médico blanqueador',
          value: 'tratamiento-medico-blanqueador',
        },
        {
          label: 'Lociones blanqueadoras',
          value: 'lociones-blanqueadoras',
        },
        {
          label: 'Máscaras blanqueadoras',
          value: 'mascaras-blanqueadoras',
        },
        {
          label: 'Ninguno',
          value: 'ninguno',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione solo una de las opciones'] }],
    },
    {
      label: 'Productos cosméticos para el área de ojos',
      name: 'productos-cosmeticos-para-el-area-de-ojos',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Lápiz de cejas',
          value: 'lapiz-de-cejas',
        },
        {
          label: 'Delineador de ojos',
          value: 'delineador-de-ojos',
        },
        {
          label: 'Sombras',
          value: 'sombras',
        },
        {
          label: 'Corrector de ojeras',
          value: 'corrector-de-ojeras',
        },
        {
          label: 'Desmaquillante para ojos',
          value: 'demaquillante-para-ojos',
        },
        {
          label: 'Máscaras para pestañas',
          value: 'mascara-para-pestanas',
        },
        {
          label: 'Crema de contorno de ojos',
          value: 'crema-de-contorno-de ojos',
        },
        {
          label: 'Gel de contorno de ojos',
          value: 'gel-de-contorno-de-ojos',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuál?',
      name: 'cual',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-cosmeticos-para-el-area-de-ojos',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Productos cosméticos para los labios',
      name: 'productos-cosmeticos-para-los-labios',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Lápices labiales',
          value: 'lapices-labiales',
        },
        {
          label: 'Brillo labial',
          value: 'brillo-labial',
        },
        {
          label: 'Protectores labiales',
          value: 'protectores-labiales',
        },
        {
          label: 'Delineadores labiales',
          value: 'delineadores-labiales',
        },
        {
          label: 'Protector solar',
          value: 'protector-solar',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'productos-cosmeticos-para-los-labios-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-cosmeticos-para-los-labios',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: 'Productos para la higiene bucal y dental',
      name: 'productos-para-la-higiene-bucal-y-dental',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Dentífricos (todo tipo)',
          value: 'dentrificos',
        },
        {
          label: 'Enjuagues bucales (no medicados)',
          value: 'enjuagues-bucales',
        },
        {
          label: 'Antiplaca',
          value: 'antiplaca',
        },
        {
          label: 'Blanqueadores dentales',
          value: 'blanqueadores-dentales',
        },
        {
          label: 'Hilo dental',
          value: 'hilo-dental',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'productos-para-la-higiene-bucal-y-dental-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'productos-para-la-higiene-bucal-y-dental',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Cuántas veces al día se lava los dientes?',
      name: 'cuantas-veces-al-dia-se-lava-los-dientes',
      description: '',
      type: 'checklist',
      validationType: 'object',
      options: [
        {
          label: 'Al levantarse',
          value: 'al-levantarse',
        },
        {
          label: 'Al acostarse',
          value: 'al-acostarse',
        },
        {
          label: 'Después de cada comida',
          value: 'despues-de-cada-comida',
        },
        {
          label: 'Otro',
          value: 'otro',
        },
      ],
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
    {
      label: '¿Cuáles?',
      name: 'cuantas-veces-al-dia-se-lava-los-dientes-cuales',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, escriba su respuesta aquí'] }],
      validations: [
        {
          type: 'when',
          params: [
            'cuantas-veces-al-dia-se-lava-los-dientes',
            {
              is: 'otro',
              then: { type: 'required', params: ['Por favor, escriba su respuesta aquí'] },
            },
          ],
        },
      ],
    },
    {
      label: '¿Tuvo alguna reacción con algún producto? ¿Cuál?',
      name: 'tuvo-alguna-reaccion-con-algun-producto',
      description: '',
      type: 'text',
      validationType: 'string',
      // validations: [{ type: 'required', params: ['Por favor, seleccione sólo una de las siguientes opciones'] }],
    },
  ],
};

export default form;
