import {
  initialState,
  Actions,
  State,
  LIST_PANELISTS,
  LIST_PANELISTS_SUCCESS,
  LIST_PANELISTS_ERROR,
  LIST_PANELISTS_FILTERS,
  LIST_PANELISTS_FILTERS_SUCCESS,
  LIST_PANELISTS_FILTERS_ERROR,
} from '../../types/panelist/list';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LIST_PANELISTS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case LIST_PANELISTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case LIST_PANELISTS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case LIST_PANELISTS_FILTERS:
      return {
        ...state,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case LIST_PANELISTS_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case LIST_PANELISTS_FILTERS_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
