import { Filter } from '../../../types/panelist-filter';

export const LIST_STUDIES = 'LIST_STUDIES';
export const LIST_STUDIES_SUCCESS = 'LIST_STUDIES_SUCCESS';
export const LIST_STUDIES_ERROR = 'LIST_STUDIES_ERROR';

export interface ListStudies {
  type: typeof LIST_STUDIES;
  payload: Filter[] | null;
}
export interface ListStudiesSuccess {
  type: typeof LIST_STUDIES_SUCCESS;
  payload: null;
}
export interface ListStudiesError {
  type: typeof LIST_STUDIES_ERROR;
  payload: boolean | string;
}

export const LIST_STUDIES_FILTERS = 'LIST_STUDIES_FILTERS';
export const LIST_STUDIES_FILTERS_SUCCESS = 'LIST_STUDIES_FILTERS_SUCCESS';
export const LIST_STUDIES_FILTERS_ERROR = 'LIST_STUDIES_FILTERS_ERROR';

export interface ListStudiesFilters {
  type: typeof LIST_STUDIES_FILTERS;
  payload: Filter[];
}
export interface ListStudiesFiltersSuccess {
  type: typeof LIST_STUDIES_FILTERS_SUCCESS;
  payload: null;
}
export interface ListStudiesFiltersError {
  type: typeof LIST_STUDIES_FILTERS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  filters: Filter[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  filters: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | ListStudies
  | ListStudiesSuccess
  | ListStudiesError
  | ListStudiesFilters
  | ListStudiesFiltersSuccess
  | ListStudiesFiltersError;
