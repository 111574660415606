import { inputDate } from '../helpers/dates-hanlder';
import action from './../helpers/rest-client';

export class ErrorStudy extends Error {}

class Study {
  public async newOne(payload) {
    return action
      .Post({
        url: '/study',
        body: payload,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }

  public async getOne(idStudy) {
    return action
      .Get({
        url: `/study/${idStudy}`,
      })
      .then((resp) => {
        resp.startDate = inputDate(resp.startDate);
        resp.endDate = inputDate(resp.endDate);
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }

  public async updateOne({ idStudy, ...rest }) {
    return action
      .Patch({
        url: `/study/${idStudy}`,
        body: rest,
      })
      .then((resp) => {
        resp.startDate = inputDate(resp.startDate);
        resp.endDate = inputDate(resp.endDate);
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }

  public async deleteOne({ idStudy }) {
    return action
      .Del({
        url: `/study/${idStudy}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }

  public async list(filters) {
    const queryString = new URLSearchParams(filters).toString();
    return action
      .Get({
        url: `/study${!!queryString ? `?${queryString}` : ''}`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }

  public async statistics() {
    return action
      .Get({
        url: `/study/statistics`,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new ErrorStudy(error.message);
      });
  }
}

const study = new Study();
export default study;
