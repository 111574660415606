import { CSSProperties, FunctionComponent, useRef, useState } from 'react';
import Select, { ClearIndicatorProps, ActionMeta } from 'react-select';
import { CSSObject } from '@emotion/serialize';
import { Field } from 'formik';

interface Option {
  label: string;
  value: string | number;
}

interface ComponentProps {
  name: string;
  items: Option | Option[];
  options?: {
    label?: string;
  };
  style?: any;
  hasError?: boolean;
  error?: {};
  touched?: {};
  defaultValue?: Option | Option[];
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  components?: any;
  onOptionSelected?: (newValue: Option | Option[], actionMeta: any) => void;
  isOptionDisabled?: (option: any, optionSelected: any) => boolean;
}

const CustomClearText: FunctionComponent = () => <></>;
const ClearIndicator = (props: ClearIndicatorProps<Option, true>) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const Component = ({
  name,
  items,
  error,
  touched,
  defaultValue,
  isMulti = false,
  onOptionSelected,
  isOptionDisabled,
  style,
  options,
  ...rest
}: ComponentProps) => {
  const [forcedValue, setForcedValue] = useState<Option | Option[] | null>(null);
  const hasError = !!error && !!touched;

  return (
    <div className="form-group" style={style}>
      {!!options?.label && <label className="col-form-label pt-0">{options.label}</label>}

      <Field
        name={name}
        options={items}
        component={({ options: fieldOptions, field, form }) => {
          const handleChange = (newValue: Option | Option[], actionMeta) => {
            form.setFieldValue(
              field.name,
              isMulti ? (newValue as Option[]).map((item: Option) => item.value) : (newValue as Option).value,
            );

            if (!!onOptionSelected) {
              onOptionSelected(newValue, { ...actionMeta, setForcedValue });
            }
          };

          const getValue = () => {
            if (fieldOptions) {
              return isMulti
                ? fieldOptions.filter((option) => field.value?.indexOf(option.value) >= 0)
                : fieldOptions.find((option) => option.value === field.value);
            } else {
              return isMulti ? [] : ('' as any);
            }
          };

          return (
            <Select
              {...rest}
              name={field.name}
              className={`${hasError ? 'error-field' : ''}`}
              placeholder="Selecciona..."
              styles={{
                container: (base: CSSObject) => ({
                  ...base,
                  flex: 1,
                }),
                menuList: (base: CSSObject) => ({
                  ...base,
                  textTransform: 'capitalize',
                }),
                multiValueLabel: (base: CSSObject) => ({
                  ...base,
                  textTransform: 'capitalize',
                }),
                valueContainer: (base: CSSObject) => ({
                  ...base,
                  textTransform: 'capitalize',
                }),
                control: (base: CSSObject) => ({
                  ...base,
                  ...(hasError && { borderColor: 'red' }),
                }),
              }}
              options={fieldOptions}
              menuPlacement="auto"
              isOptionDisabled={(option) => (isOptionDisabled ? isOptionDisabled(option, getValue()) : false)}
              value={forcedValue || getValue()}
              {...(!!defaultValue && { defaultValue })}
              onChange={handleChange}
              onBlur={field.onBlur}
              isMulti={isMulti}
              {...(isMulti && {
                components: { ClearIndicator },
                closeMenuOnSelect: false,
              })}
            />
          );
        }}
      />

      {!!error && !!touched && <div className="input-feedback">{error}</div>}
    </div>
  );
};

export default Component;
