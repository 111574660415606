import { PanelistDto } from '../../../types/panelist';
export const NEW_PANELIST = 'NEW_PANELIST';
export const NEW_PANELIST_SUCCESS = 'NEW_PANELIST_SUCCESS';
export const NEW_PANELIST_ERROR = 'NEW_PANELIST_ERROR';
export const RESET_NEW_PANELIST = 'RESET_NEW_PANELIST';

export interface NewPanelist {
  type: typeof NEW_PANELIST;
  payload;
}
export interface NewPanelistSuccess {
  type: typeof NEW_PANELIST_SUCCESS;
  payload: null;
}
export interface NewPanelistError {
  type: typeof NEW_PANELIST_ERROR;
  payload: boolean | string;
}
export interface ResetNewPanelist {
  type: typeof RESET_NEW_PANELIST;
  payload: null;
}

export interface State {
  data: PanelistDto | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions = NewPanelist | NewPanelistSuccess | NewPanelistError | ResetNewPanelist;
