import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import Avatar from '../../../../../../components/Avatar';
import Input from '../../../../../../components/Form/Input';
import Radio from '../../../../../../components/Form/Radio/index';
import Checkbox from '../../../../../../components/Form/Checkbox';
import Submit from '../../../../../../components/Form/Submit';
import Textarea from '../../../../../../components/Form/Textarea';
import Form from '../../../../../../components/Form';
import ReactSelect from '../../../../../../components/Form/ReactSelect';
import FieldChangePanelistState from '../../../components/FieldChangePanelistState';
import { getNiceDate, inputDate } from '../../../../../../helpers/dates-hanlder';
import { PanelistDto } from '../../../../../../types/panelist';
import { updatePanelist } from '../../../../../../store/actions/panelist';
import { StudyDto } from '../../../../../../types/study';
import { getStudiesInProgress } from '../../../../../../helpers/getStudiesInProgress';
import { panelistState } from '../../../../../../helpers/resolve-panelist-state';
import { getLocalidades, getPartidos } from '../../../../../../store/actions/statics';
import { createYupSchema } from '../../../../../../helpers/yup-schema-validation';
import './styles.scss';
import { getPanelistAge } from '../../../../../../helpers/get-panelist-age';

interface ComponentProps {
  panelistInformation: PanelistDto & { recordDate: string };
  panelistStates;
  updatePanelistStates;
  studyTypes;
  locations;
  form;
}

const origenesEtnicos = [
  {
    label: 'Africano',
    value: 'africano',
  },
  {
    label: 'Arabe',
    value: 'arabe',
  },
  {
    label: 'Hispanoamericano',
    value: 'hispanoamericano',
  },
  {
    label: 'Europeo',
    value: 'europeo',
  },
  {
    label: 'Asiático',
    value: 'asiático',
  },
  {
    label: 'Otro',
    value: 'otro',
  },
];

const statesClasses = {
  active: { label: 'Activo', class: 'badge-success' },
  inactive: { label: 'Pausado', class: 'badge-danger' },
  'study-in-progress': { label: 'Finalizado', class: 'badge-warning' },
};

const Component = ({
  updatePanelistStates,
  panelistInformation,
  panelistStates,
  studyTypes,
  locations,
  form,
}: ComponentProps) => {
  const [studyInProgress, setStudyInProgress] = useState<StudyDto[] | null>(null);
  const [panelistCurrentState, setPanelistCurrentState] = useState<{ value: string; label: string }>();
  const [selectedPartido, setSelectedPartido] = useState<{ label: string; value: string | number }>();
  const [selectedLocalidad, setSelectedLocalidad] = useState<{ label: string; value: string | number }>();
  const birthdateValidation = [form.fields[12]];

  const dispatch = useDispatch();

  const getLocalidadesByPartido = (partido) => {
    dispatch(getLocalidades(partido.value));
  };

  const onSubmit = (data) => {
    dispatch(updatePanelist({ ...panelistInformation, ...data.values, idPanelist: panelistInformation._id }));
  };

  useEffect(() => {
    if (!!panelistInformation?.studies) {
      setStudyInProgress(getStudiesInProgress(panelistInformation.studies, null));
    }

    if (!!panelistInformation?.city) {
      dispatch(getLocalidades(panelistInformation.city));
    }
  }, [panelistInformation]);

  useEffect(() => {
    if (!!panelistInformation && !!panelistStates) {
      setPanelistCurrentState(panelistState(panelistInformation, panelistStates));
    }
  }, [panelistInformation, panelistStates]);

  // useEffect(() => {
  //   dispatch(getPartidos(1));
  // }, []);

  useEffect(() => {
    if (
      !!locations?.partidos &&
      !!locations?.localidades &&
      !!panelistInformation?.city &&
      !!panelistInformation?.locality
    ) {
      const partido = locations.partidos.filter((partido) => partido.id === Number(panelistInformation?.city))[0];
      const localidad = locations.localidades.filter(
        (localidad) => localidad.id === Number(panelistInformation?.locality),
      )[0];

      setSelectedPartido({
        label: partido?.descripcion,
        value: partido?.id,
      });

      setSelectedLocalidad({
        label: localidad?.descripcion,
        value: localidad?.id,
      });
    }
  }, [locations, panelistInformation]);

  if (!panelistInformation) {
    return null;
  }

  const yupSchema = birthdateValidation.reduce(createYupSchema, {});
  const validateSchema = Yup.object().shape(yupSchema);

  return (
    <div className="card" id="contact-data">
      <div className="card-header">
        <h5 className="card-title mb-0">
          <div className="picture">
            <span>
              <Avatar
                name={panelistInformation?.name}
                lastName={panelistInformation?.lastName}
                picture={panelistInformation?.picture}
              />
            </span>
            <div
              className={`status-${panelistInformation.state} ${statesClasses[panelistInformation.state].class}`}
            ></div>
          </div>
          <div>
            <div className="panelist-name">
              {panelistInformation?.name} {panelistInformation?.lastName}
            </div>
            <div className="panelist-age">
              {getNiceDate(panelistInformation?.birthdate)}{' '}
              {getPanelistAge(panelistInformation) &&
                `(${getPanelistAge(panelistInformation)?.number} ${getPanelistAge(panelistInformation)?.unit})`}
            </div>
            {studyInProgress && (
              <div className="study-in-progress">
                {`Estudio en curso ${studyInProgress[0].costEstimationNumber} finaliza el
            ${getNiceDate(studyInProgress[0].endDate)}`}
              </div>
            )}
          </div>
        </h5>
        <div className="card-options">
          <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
            <i className="fe fe-chevron-up"></i>
          </a>
          <a className="card-options-remove" href="javascript" data-toggle="card-remove">
            <i className="fe fe-x"></i>
          </a>
        </div>
      </div>
      <div className="card-body">
        <FieldChangePanelistState />

        <Form
          id="panelista-datos-contacto"
          onSubmit={onSubmit}
          values={{
            ...panelistInformation,
            birthdate: inputDate(panelistInformation?.birthdate),
            recordDate: inputDate(panelistInformation?.recordDate),
          }}
          rules={validateSchema}
        >
          <Input name={'panelistNumber'} placeholder="" options={{ label: 'N° de Panelista' }} type="text" />
          <Input name={'recordDate'} placeholder="" options={{ label: 'Fecha de ficha' }} type="date" />
          <Input
            name={'throughWhoContactedUs'}
            placeholder=""
            options={{ label: 'A través de quien nos contacto' }}
            type="text"
          />
          <Input name={'name'} placeholder="" options={{ label: 'Nombre' }} type="text" />
          <Input name={'lastName'} placeholder="" options={{ label: 'Apellido' }} type="text" />
          <Radio
            name={'sex'}
            options={{ label: 'Sexo' }}
            items={[
              {
                label: 'Mujer',
                value: 'woman',
              },
              {
                label: 'Hombre',
                value: 'man',
              },
            ]}
          />

          <Input name={'street'} placeholder="" options={{ label: 'Dirección' }} type="text" />

          <ReactSelect
            name="city"
            options={{ label: 'Partido' }}
            items={
              locations.partidos &&
              locations.partidos.map((partido) => {
                return {
                  label: partido.descripcion,
                  value: partido.id,
                };
              })
            }
            onOptionSelected={(partido) => {
              getLocalidadesByPartido(partido);
            }}
            defaultValue={selectedPartido}
          />
          <ReactSelect
            name="locality"
            options={{ label: 'Localidad' }}
            items={
              locations.localidades &&
              locations.localidades.map((localidad) => {
                return {
                  label: localidad.descripcion,
                  value: localidad.id,
                };
              })
            }
            defaultValue={selectedLocalidad}
          />
          <Input name={'phone'} placeholder="(xxx) xxxx xxxx" options={{ label: 'Teléfono Fijo' }} type="number" />
          <Input name={'mobilePhone'} placeholder="(xxx) xxxx xxxx" options={{ label: 'Celular' }} type="number" />
          <Input name={'e-mail'} placeholder="your-email@domain.com" options={{ label: 'E-mail' }} type="email" />
          <Input name={'birthdate'} placeholder="" options={{ label: 'Fecha de Nacimiento' }} type="date" />
          <Input name={'identification'} placeholder="" options={{ label: 'Numero de documento' }} type="number" />
          <Radio name={'etnic-origin'} options={{ label: 'Origen Etnico' }} items={origenesEtnicos} />
          <Input name={'other-ethnic-origin'} placeholder="" options={{ label: 'Otro origen' }} type="text" />
          <Checkbox name={'studies-available'} options={{ label: 'Estudios que puede realizar' }} items={studyTypes} />

          <Input name={'responsable-claim'} placeholder="" options={{ label: 'Responsable CLAIM' }} type="texta" />
          <Textarea name={'comments'} placeholder="" options={{ label: 'Comentarios' }} type="text" />

          <div className="form-group text-right">
            <Submit form="panelista-datos-contacto" isLoading={updatePanelistStates.loading}>
              Actualizar
            </Submit>
          </div>
        </Form>
      </div>
    </div>
  );
};

const state = ({ panelistStore, staticsStore }) => {
  const { data: panelistStates } = staticsStore.panelistStates;
  const { states: updatePanelistStates } = panelistStore.updatePanelist;
  const { data: panelistInformation } = panelistStore.panelist;
  const { data: locations } = staticsStore.locations;
  const { data: studyTypes } = staticsStore.studyTypes;
  return { panelistInformation, updatePanelistStates, panelistStates, studyTypes, locations };
};
export default connect(state)(Component);
