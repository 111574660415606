import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const Component = ({ component: RouteComponent, user, userInformation, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (!!userInformation ? <RouteComponent {...routeProps} /> : <Redirect to={'/signin'} />)}
    />
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
